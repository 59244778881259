import React, {useContext, useState} from 'react';
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {PAContext} from "../../../Services/PAContext";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";



function MessageCard(props){

    const { dataArray } = useContext(PAContext);
    const { Attendees } = dataArray;

    const [ message, setMessage] = useState(props.message);


    return (

        <div
            key={message.id}
            style={{
                display: 'flex',
                justifyContent: props.align === 'left' ? 'flex-start' : 'flex-end',
            }}
        >
            <div style={{
                position: 'relative',
                maxWidth: '70%',
                backgroundColor: props.align === 'left' ? '#e0f7fa' : '#b3e0f7',
                padding: '8px',
                borderRadius: '12px',
                marginBottom: '8px',
                border: message.priority ? "2px solid red" : "none"
            }}>
                <Typography
                    sx={{ fontSize: '13px' }}
                    variant="body2"
                    component="div"
                >
                    {message.message}
                </Typography>
                {
                    props.align === 'left' ? (
                        <Typography
                            sx={{ fontSize: '11px' }}
                            variant="body2"
                            component="div"
                        >
                            {capitalizeFirstLetter(Attendees.find((_att) => _att.id === message.attendee_id)?.nickname)}
                        </Typography>
                    ) : (
                        <></>
                    )
                }
                <Typography
                    component="div"
                    sx={{
                        fontSize: '10px',
                        textAlign: props.align === 'assistant' ? 'left' : 'right',
                        paddingRight: props.align === 'left' ? '0' : '15px'
                    }}
                    variant="caption"
                >
                    {dayjs(message.created).format('ddd, MMM D, YYYY h:mm A')}
                </Typography>

                {/* Indicator for messages that aren't from 'assistant' */}
                {
                    props.align === 'right' ? (
                        <></>
                    ) : (
                        <></>
                    )
                }

            </div>
        </div>
    )
}

export default MessageCard;