import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    Slider
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {cancelChipStyle} from "../../ButtonStyle/Cancel";

import './lodging.css';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import StarIcon from '@mui/icons-material/Star';
import {addChipStyle} from "../../ButtonStyle/Add";
import {useNavigate} from "react-router-dom";
import Location from "../../../MasterEventObjects/Location";
import Lodging from "../../../MasterEventObjects/Lodging";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {createTimeTableForLodging} from "../../Helpers/EventTimeTableConversion";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import _ from "lodash";


function LodgingSuggestions(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { SuggestionsLodging } = dataArray;

    const gridContainerRef = useRef(null);
    const gridItemRefs = {};

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    const [priceRangeLow, setPriceRangeLow] = useState(0);
    const [priceRangeHigh, setPriceRangeHigh] = useState(1000000);
    const [pricePoint, setPricePoint] = useState([0, 1000000]);
    const [lodgingFilterCount, setLodgingFilterCount] = useState(SuggestionsLodging.length);

    useEffect(()=> {

        //find the min and max pricing

        let highestPrice = -1000000; // Initialize as negative infinity
        let lowestPrice = 1000000;   // Initialize as positive infinity

        // Iterate through the array of hotels
        for (const lodging of SuggestionsLodging) {

            console.log("lodging : ", lodging);

            const _item_check_in_date = dayjs(lodging.check_in_date);
            const _item_check_out_date = dayjs(lodging.check_out_date);
            const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');
            const _total_price = parseFloat(lodging.cheapest_rate_total_amount);
            const _item_per_night_rate = _total_price / _item_num_nights;

            console.log("_total_price : "+_total_price);

            if (!isNaN(_total_price)) {
                // Check if the price is a valid number
                if (_item_per_night_rate > highestPrice) {
                    highestPrice = _item_per_night_rate; // Update the highest price
                }
                if (_item_per_night_rate < lowestPrice) {
                    lowestPrice = _item_per_night_rate;  // Update the lowest price
                }
            }
        }

        setPriceRangeLow(lowestPrice);
        setPriceRangeHigh(highestPrice);
        setPricePoint([lowestPrice, highestPrice]);
        props.setViewSuggestionLodgingMarkersFilter([0,1000000]);

    }, []);

    useEffect(() => {
        console.log(" ----------------------- SuggestionsLodging changed");

        //find the min and max pricing

        let highestPrice = -1000000; // Initialize as negative infinity
        let lowestPrice = 1000000;   // Initialize as positive infinity

        // Iterate through the array of hotels
        for (const lodging of SuggestionsLodging) {

            console.log("lodging : ", lodging);

            const _item_check_in_date = dayjs(lodging.check_in_date);
            const _item_check_out_date = dayjs(lodging.check_out_date);
            const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');
            const _total_price = parseFloat(lodging.cheapest_rate_total_amount);
            const _item_per_night_rate = _total_price / _item_num_nights;

            console.log("_total_price : "+_total_price);

            if (!isNaN(_total_price)) {
                // Check if the price is a valid number
                if (_item_per_night_rate > highestPrice) {
                    highestPrice = _item_per_night_rate; // Update the highest price
                }
                if (_item_per_night_rate < lowestPrice) {
                    lowestPrice = _item_per_night_rate;  // Update the lowest price
                }
            }
        }

        setPriceRangeLow(lowestPrice);
        setPriceRangeHigh(highestPrice);
        setPricePoint([lowestPrice, highestPrice]);
        props.setViewSuggestionLodgingMarkersFilter([0,1000000]);

    }, [SuggestionsLodging]);

    const handleSliderChange = (event, newValue) => {

        setPricePoint(newValue);
        props.setViewSuggestionLodgingMarkersFilter(newValue);

        const _filtered_count = SuggestionsLodging
            .filter((_item)=>{

                const _item_check_in_date = dayjs(_item.check_in_date);
                const _item_check_out_date = dayjs(_item.check_out_date);
                const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');

                const _item_price = _item['accommodation'].cheapest_rate_total_amount;
                const _item_per_night_rate = _item_price / _item_num_nights;

                return (_item_per_night_rate >= newValue[0] && _item_per_night_rate <= newValue[1]);

            });
        setLodgingFilterCount(_filtered_count.length);

    };
//    const valueLabelFormat = (pricePoint) => `$${pricePoint.toFixed(2)}`;
    const valueLabelFormat = (pricePoint) => pricePoint.toFixed(2);


    const shadesOfGreen = [
        '#00522a', // Darkest Green for one star
        '#008c3a', // Dark Green for two stars
        '#00bf49', // Moderate Green for three stars
        '#4edb73', // Light Green for four stars
        '#a1f7a4', // Lightest Green for five stars
    ];

    function CustomRating({ value }) {
        return (
            <div style={{width: '100%', textAlign: 'right'}}>
                {Array.from({ length: 5 }).map((_, index) => (
                    <StarIcon
                        key={index}
                        sx={{
                            color: index < value ? shadesOfGreen[index] : '#aaa',
                            fontSize: '16px',
                        }}
                    />
                ))}
            </div>
        );
    }

    function addLodgingFromDuffel(_lodging_id){
        console.log("addLodgingFromDuffel : "+_lodging_id);

        props.setCurrentFeature("Lodging");
        const lodgingDetails = SuggestionsLodging.find(_lodging => _lodging.id === _lodging_id);

        if (lodgingDetails){

            console.log("lodgingDetails : ", lodgingDetails);

            const request = {
                query: lodgingDetails['accommodation'].name,
                location: new window.google.maps.LatLng(
                    lodgingDetails['accommodation']['location']['geographic_coordinates'].latitude,
                    lodgingDetails['accommodation']['location']['geographic_coordinates'].longitude
                ),
                radius: 5,
            };

            console.log("search request : ", request);


            const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
            _global_map_service.textSearch(request, (results, status) => {
                console.log("^^^^^^^^^^^^^^^^^^^^^ results : ", results);
                console.log("textSearch status : " + status);
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    // Handle the search results (e.g. display them on a map or list)

                    if (results && results.length > 0){

                        const lodgingLocation = new Location();

                        //the coordinates
                        const _geometry = results[0].geometry;
                        lodgingLocation.google_place_id = results[0].place_id;
                        lodgingLocation.latitude = _geometry.location.lat();
                        lodgingLocation.longitude = _geometry.location.lng();

                        lodgingLocation.formatted_address = results[0].formatted_address;

                        const parsedAddress = parseGoogleAddress(results[0].formatted_address);

                        lodgingLocation.name = results[0].name;
                        lodgingLocation.address = parsedAddress.address;
                        lodgingLocation.city = parsedAddress.city;
                        lodgingLocation.state = parsedAddress.state;
                        lodgingLocation.zip = parsedAddress.zip;
                        lodgingLocation.country = parsedAddress.country;


                        const _new_lodging = new Lodging();

                        _new_lodging.name = lodgingDetails['accommodation'].name;
                        _new_lodging.phone = lodgingDetails['accommodation'].phone_number;


                        const eventTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table;
                        if (eventTimeTable?.dates?.length > 0) {
                            _new_lodging.time_table = createTimeTableForLodging(
                                eventTimeTable.dates[0].date,
                                eventTimeTable.dates[eventTimeTable.dates.length - 1].date,
                                eventTimeTable);
                        } else {
                            _new_lodging.time_table = new TimeTable();
                        }


                        //set the Place object from Google
                        _new_lodging.Place = results;
                        _new_lodging.location = lodgingLocation;

                        //get the per night rate and then multiply

                        const _item_check_in_date = dayjs(lodgingDetails.check_in_date, 'YYYY-MM-DD');
                        const _item_check_out_date = dayjs(lodgingDetails.check_out_date, 'YYYY-MM-DD');
                        const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');

                        console.log("_item_num_nights : "+_item_num_nights);

                        const _item_price = lodgingDetails.cheapest_rate_total_amount;
                        const _item_per_night_rate = _item_price / _item_num_nights;

                        console.log("_item_price : "+_item_price);

                        console.log("amount_total : "+_item_per_night_rate * _item_num_nights);


                        //add any of the Event attendees to the lodging
                        const attendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees?.filter(
                            (attendee) => attendee.status !== 4 && attendee.planner !== true
                        ) || [];

                        if (attendees.length > 0) {
                            _new_lodging.attendees = attendees
                                .map((att) => ({
                                    id: att.id,
                                    cost: ((_item_per_night_rate * _item_num_nights) / attendees.length).toFixed(2),
                                    is_business_expense: false,
                                    business_expense_amount_authorized: 0,
                                    business_expense_amount_authorized_by: "",
                                    business_expense_amount_authorized_timestamp: 0,
                                    business_expense_amount: 0,
                                    business_expense_paid: 0,
                                    business_expense_reference_id: "",
                                }));
                        }


                        _new_lodging.amount_total = _item_per_night_rate * _item_num_nights;
                        _new_lodging.currency = lodgingDetails.cheapest_rate_currency;

                        _new_lodging.Duffel = _.cloneDeep(lodgingDetails);

                        _new_lodging.notes= "";

                        set_PA_GlobalObject((prev) => ({
                            Planning: {
                                ...prev.Planning, // Retain all existing fields in Planning
                                lodging: [...prev.Planning.lodging, _new_lodging], // Append new lodging
                            },
                        }));


//                        props.setLodgingFromSuggestions(lodgingDetails);
                        props.setShowEventItemConfig(true);

                        navigate('/Planner/Lodging');

                    }

                } else {
                    console.log("no results for this Lodging");
                }

            });

        }

    }

    useEffect(()=> {
        console.log("something selected from map : "+props.lodgingSuggestionNameFromMap);
        //bring it into the viewable area

        console.log("gridItemRefs : "+gridItemRefs[props.lodgingSuggestionNameFromMap]);
        console.log("gridContainerRef.current : "+gridContainerRef.current);

        if (gridItemRefs[props.lodgingSuggestionNameFromMap] && gridContainerRef.current) {
            try {
                console.log("gridItemRefs[props.lodgingSuggestionNameFromMap] : " + gridItemRefs[props.lodgingSuggestionNameFromMap]);

                gridItemRefs[props.lodgingSuggestionNameFromMap].scrollIntoView({
                    behavior: 'smooth', // You can use 'auto' for instant scrolling
                    block: 'start',
                });

            } catch (e){
                console.log(e);
            }
        }

    }, [props.lodgingSuggestionNameFromMap]);

    function parseGoogleAddress(formattedAddress) {
        const addressParts = formattedAddress.split(",").map(part => part.trim());

        let address = "";
        let city = "";
        let state = "";
        let zip = "";
        let country = "";

        if (addressParts.length >= 3) {
            address = addressParts[0]; // Street address
            country = addressParts[addressParts.length - 1]; // Last part is the country

            // Extract state and zip using regex
            const stateZipMatch = addressParts[addressParts.length - 2].match(/([A-Z]{2})\s(\d{5})/);

            if (stateZipMatch) {
                state = stateZipMatch[1]; // Two-letter state code
                zip = stateZipMatch[2]; // Zip code
            }

            // City is usually the second-to-last part (before state/zip)
            city = addressParts.length > 3 ? addressParts[addressParts.length - 3] : "";
        }

        return { address, city, state, zip, country };
    }

    return (

        <div className="lodging-suggestions-list-container" >
            {/* show the header */}
            <div className="lodging-suggestions-list-header">
                <div className="lodging-suggestions-list-header-text">
                    <h5>Lodging Suggestions {lodgingFilterCount===0?'':lodgingFilterCount}</h5>
                </div>

                <div className="lodging-suggestions-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptChangeClose}
                    />

                </div>
            </div>

            {/*
            <div className="lodging-suggestions-list-pricing-disclaimer">
                <Typography variant="caption" display="block" gutterBottom>
                    * Any pricing is based on a 5 night stay for 2 weeks from today.
                    Once you have chosen Event dates then exact pricing will be shown.
                </Typography>
            </div>
            */}

            {
                SuggestionsLodging.length > 0 ? (
                    <>
                        <div className="lodging-suggestions-price-range-display-container">
                            <span className="lodging-suggestions-price-range-display-low">{pricePoint[0].toFixed(0)}</span>
                            <span className="lodging-suggestions-price-range-display-high"> - {pricePoint[1].toFixed(0)}</span>
                        </div>


                        <div className="lodging-suggestions-price-range-container">
                            <Slider
                                sx={{display:'flex',width:'80%',marginLeft:'auto', marginRight:'auto'}}
                                size="small"
                                value={pricePoint}
                                onChange={handleSliderChange}
                                min={priceRangeLow} // Set the minimum price
                                max={priceRangeHigh} // Set the maximum price
                                valueLabelDisplay="on" // Display labels for the selected values
                                valueLabelFormat={valueLabelFormat}
                                aria-labelledby="range-slider"
                            />
                        </div>
                    </>

                ) : (
                    <></>
                )
            }

            {/* show the list of lodging suggestions */}
            <div ref={gridContainerRef} className="lodging-suggestions-list-items-container">
                <Grid container spacing={2} direction="column">
                    {
                        SuggestionsLodging.length > 0 ? (


                            SuggestionsLodging
                                .filter((_item)=>{

                                    const _item_check_in_date = dayjs(_item.check_in_date);
                                    const _item_check_out_date = dayjs(_item.check_out_date);
                                    const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');

                                    const _item_price = _item.cheapest_rate_total_amount;
                                    const _item_per_night_rate = _item_price / _item_num_nights;

                                    return (_item_per_night_rate >= pricePoint[0] && _item_per_night_rate <= pricePoint[1]);

                                })
                                .sort((_item1, _item2) => {

                                    // Sort in ascending order based on per_night_rate
                                    const _item1_check_in_date = dayjs(_item1.check_in_date);
                                    const _item1_check_out_date = dayjs(_item1.check_out_date);
                                    const _item1_num_nights = _item1_check_out_date.diff(_item1_check_in_date, 'days');
                                    const _item1_price = _item1.cheapest_rate_total_amount;
                                    const _item1_per_night_rate = _item1_price / _item1_num_nights;

                                    const _item2_check_in_date = dayjs(_item2.check_in_date);
                                    const _item2_check_out_date = dayjs(_item2.check_out_date);
                                    const _item2_num_nights = _item2_check_out_date.diff(_item2_check_in_date, 'days');
                                    const _item2_price = _item2.cheapest_rate_total_amount;
                                    const _item2_per_night_rate = _item2_price / _item2_num_nights;

                                    return _item1_per_night_rate - _item2_per_night_rate;
                                })
                                .map((_item, index) => {

//                                    console.log("the lodging : "+JSON.stringify(_item, null, '\t'));

                                    const _item_check_in_date = dayjs(_item.check_in_date);
                                    const _item_check_out_date = dayjs(_item.check_out_date);
                                    const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');

                                    const _item_price = _item.cheapest_rate_total_amount;
                                    const _item_currency = _item.cheapest_rate_currency;
                                    const _item_per_night_rate = _item_price / _item_num_nights;

                                    return (
                                        <Grid
                                            item
                                            key={_item['id']}
                                            ref={(ref) => (gridItemRefs[_item['id']] = ref)}
                                            sx={{display:'flex'}}>

                                            <Card
                                                className="lodging-suggestions-list-item"
                                            >
                                                <CardContent sx={{position: 'relative'}}>
                                                    <div className="lodging-suggestions-list-item-header-chip-holder">
                                                        <Chip
                                                            sx={addChipStyle}
                                                            label="Add"
                                                            size="small"
                                                            onClick={()=>addLodgingFromDuffel(_item['id'])}
                                                        />
                                                    </div>

                                                </CardContent>
                                                {
                                                    _item['accommodation']['photos'][0] === undefined ? (
                                                        <></>
                                                    ) : (
                                                        <CardMedia
                                                            sx={{ height: 200 }}
                                                            image={_item['accommodation']['photos'][0].url}
                                                            title="green iguana"
                                                        />
                                                    )
                                                }
                                                <CardContent>
                                                    <Typography variant="body2">
                                                        {_item['accommodation'].name}
                                                    </Typography>
                                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                        {_item['accommodation']['location']['address'].line_one}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Chip
                                                        label={`${_item_per_night_rate.toFixed(2)} per night ${_item_currency}`}
                                                        size="small"
                                                        variant={"outlined"}
                                                        color="success"
                                                        sx={{
                                                            boxShadow: 2,
                                                            borderRadius: 2,
                                                            textAlign: 'center',
                                                            fontWeight: '700',
                                                        }}
                                                    />
                                                    {
                                                        _item['accommodation']['ratings'] === null || _item['accommodation']['ratings'].length === 0 ? (
                                                            <div style={{flex:'1',textAlign:'right', fontSize:'12px'}}>No Ratings</div>
                                                        ) : (
                                                            <CustomRating value={_item['accommodation']['ratings'][0].value} />
                                                        )
                                                    }
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                })
                        ):(
                            <div style={{padding: '20px', textAlign:'center'}} >
                                {/* place is required and preferably some real dates */}
                                <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                                <Typography variant="subtitle2">No Lodging suggestions yet.</Typography>
                                <Typography variant="caption">Once an area has been selected then the Concierge will look for Lodging suggestions.</Typography>
                            </div>
                        )

                    }
                </Grid>

            </div>

        </div>
    )

}

export default LodgingSuggestions;

