import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Chip,
    Menu,
    MenuItem,
    MenuList,
    Paper
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import './lodging.css';

import lodgingHeaderImage from '../../../graphics/th-513411954.jpg';
import LodgingCard from "./LodgingCard";
import dayjs from "dayjs";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Box from "@mui/material/Box";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import InfoActivities from "../../Dialogs/InfoActivities";
import InfoLodging from "../../Dialogs/InfoLodging";


function LodgingList(props){

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventLodging, setEventLodging] = useState([]);
    const [filteredLodging, setFilteredLodging] = useState([]);

    const [selectedLodgingUnit , setSelectedLodgingUnit ] = useState("dates");
    const [viewType, setViewType] = useState(
        usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0 ?
            'dates'
            : usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0 ?
                'days' : 'dates'); // Either 'dates' or 'days'
    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);
    const [selectedDate, setSelectedDate] = useState("all"); // For selected date
    const [selectedDay, setSelectedDay] = useState("all"); // For selected day

    const [selectedLodgingForDates , setSelectedLodgingForDates ] = useState("");
    const [selectedLodgingForDays , setSelectedLodgingForDays ] = useState("");

    const handleToggleViewType = () => {
        console.log("handleToggleViewType : " + viewType);

        const planning = usePA_GlobalStore.getState().PA_GlobalObject.Planning;
        const eventDays = planning.time_table?.days || [];
        const eventDates = planning.time_table?.dates || [];

        if (viewType === 'dates') {
            console.log("Changing Chips to days...");

            if (selectedDate === 'all') {
                setSelectedDay('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding day for the selected date
                const correspondingDay = eventDates.find(dateEntry => dateEntry.date === selectedDate);
                if (correspondingDay) {
                    const dayIndex = eventDates.indexOf(correspondingDay);
                    const correspondingDayKey = Object.keys(eventDays[dayIndex] || {})[0] || 'all';
                    setSelectedDay(correspondingDayKey);
                } else {
                    setSelectedDay('all'); // Fallback
                }
            }

            setViewType('days');

        } else if (viewType === 'days') {
            console.log("Changing Chips to dates...");

            if (selectedDay === 'all') {
                setSelectedDate('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding date for the selected day
                const correspondingDayEntry = eventDays.find(dayEntry => Object.keys(dayEntry)[0] === selectedDay);
                if (correspondingDayEntry) {
                    const dayIndex = eventDays.indexOf(correspondingDayEntry);
                    const correspondingDate = eventDates[dayIndex]?.date || 'all';
                    setSelectedDate(correspondingDate);
                } else {
                    setSelectedDate('all'); // Fallback
                }
            }

            setViewType('dates');
        }
    };

    useEffect(() => {

        console.log("usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging : ", usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging);
        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging !== undefined) {
            const currentLodging = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging;

            // Clone the existing eventLodging to avoid mutating state directly
            const updatedEventLodging = [...eventLodging];
            let hasUpdates = false; // Flag to track if updates occur

            currentLodging.forEach((lodging) => {

                console.log("seeing if lodging needs to be added or updated :", lodging);

                const existingLodgingIndex = updatedEventLodging.findIndex(
                    (eventLodgingItem) => eventLodgingItem.id === lodging.id
                );

                console.log("existingLodgingIndex : ", updatedEventLodging[existingLodgingIndex]);

                if (existingLodgingIndex !== -1) {
                    // Compare and update only if there are changes
                    const existingLodging = updatedEventLodging[existingLodgingIndex];
                    if (JSON.stringify(existingLodging) !== JSON.stringify(lodging)) {
                        console.log("Updating lodging:", lodging.id);
                        updatedEventLodging[existingLodgingIndex] = { ...lodging };
                        hasUpdates = true; // Mark as updated
                    }
                } else {
                    // Add new lodging if not found in eventLodging
                    console.log("Adding new lodging:", lodging.id);
                    updatedEventLodging.push({ ...lodging });
                    hasUpdates = true; // Mark as updated
                }
            });

            // If there are updates, set the new eventLodging state
            if (hasUpdates) {
                setEventLodging(updatedEventLodging);
            }
        }
    }, [PA_GlobalObject.Planning.lodging]);

    useEffect(() => {
        console.log(" ------ eventLodging updated!!!! : ", eventLodging);

        //this updates the lodging array at the Global level
//        set_PA_GlobalObject((prev) => ({
//            Planning: {
//                ...prev.Planning, // Retain all existing fields in Planning
//                lodging: eventLodging, // Update only the title
//            },
//        }));

        }, [eventLodging]);

    useEffect(() => {

    }, [selectedLodgingUnit]);

    const updateLodgingDetails = (updatedLodging) => {
        console.log("updateLodgingDetails from LodgingCard : ", updatedLodging);

        // Find the index of the updated lodging in eventLodging
        const lodgingIndex = eventLodging.findIndex(
            (lodging) => lodging.id === updatedLodging.id || lodging.temp_id === updatedLodging.temp_id
        );

        console.log("lodgingIndex : "+lodgingIndex);

        if (lodgingIndex !== -1) {
            // If the lodging is found, update it in the eventLodging array
            const updatedEventLodgingArray = [...eventLodging];
            updatedEventLodgingArray[lodgingIndex] = updatedLodging;
            setEventLodging(updatedEventLodgingArray);
        }

    };

    /*
        this is for the Menu list in the header
     */
    const [anchorLodgingListMenu, setAnchorLodgingListMenu] = useState(null);
    const handleLodgingListMenuOpenMenu = (event) => {
        setAnchorLodgingListMenu(event.currentTarget);
    };
    const handleLodgingListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorLodgingListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Lodging/AddNew');
        } else if (dataId === "from-pdf"){
            navigate('/Planner/Lodging/AddFromPDF');
        }
    };
    const handleLodgingListMenuClose = () => {
        setAnchorLodgingListMenu(null);
    };

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    function handleLodgingChipClick(_lodging_id){

    }

    const [isLodgingInfoDialogOpen, setLodgingInfoDialogOpen] = useState(false);


    return (
        <div className="lodging-list-container">

            {/* show the header */}
            <div
                className="lodging-list-header"
            >
                <div className="lodging-list-header-text">
                    <h5>Lodging</h5>
                </div>

                <div className="lodging-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setLodgingInfoDialogOpen(!isLodgingInfoDialogOpen)}
                    />
                </div>

                <div className="lodging-list-header-controls">
                    {/* Toggle for Dates/Days */}
                    <Box sx={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                        {/* Icon Button for Dates */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'dates' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to dates"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Dates</Typography>
                        </IconButton>

                        {/* Divider between buttons */}
                        <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>

                        {/* Icon Button for Days */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'days' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to days"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Days</Typography>
                        </IconButton>
                    </Box>

                    {/* Menu Chip */}
                    <Chip
                        sx={menuChipStyle}
                        label={<i className="icon-menu2" style={{fontSize: '16px'}}/>}
                        size="small"
                        onClick={handleLodgingListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorLodgingListMenu}
                        id="library-menu"
                        open={Boolean(anchorLodgingListMenu)}
                        onClose={handleLodgingListMenuClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                            <MenuItem data-id="new-entry" onClick={handleLodgingListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handlePromptChangeClose}>
                                <ListItemText>Close</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of lodging dates/days */}
            <div className="lodging-list-timetable-list-container">
                {eventLodging && eventLodging.length > 0 ? (
                    (() => {
                        const allChips = [];

                        // Step 1: Add lodging Chips to the allChips array
                        eventLodging.forEach((lodging, index) => {

                            const timeTable = lodging.time_table;

                            if (selectedLodgingUnit === 'dates' && timeTable?.dates?.length > 0) {
                                const firstDate = dayjs(timeTable.dates[0].date);
                                const lastDate = dayjs(timeTable.dates[timeTable.dates.length - 1].date);

                                const formattedFirstDay = firstDate.format('ddd'); // e.g., "Wed"
                                const formattedFirstDate = firstDate.format('M/D'); // e.g., "8/19"
                                const formattedLastDay = lastDate.format('ddd'); // e.g., "Fri"
                                const formattedLastDate = lastDate.format('M/D'); // e.g., "8/21"

                                allChips.push({
                                    date: firstDate, // Use the first lodging date for sorting
                                    component: (
                                        <Box key={index}
                                             sx={{
                                                 position: 'relative',
                                                 display: 'inline-block',
                                                 marginTop: '5px',
                                                 marginRight: '10px'
                                             }}
                                        >
                                            {/* Chip Component */}
                                            <Chip
                                                label={
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <span style={{textAlign: 'center', marginRight: '5px'}}>
                                                            <Typography variant="body2">{formattedFirstDay}</Typography>
                                                            <Typography
                                                                variant="caption">{formattedFirstDate}</Typography>
                                                        </span>
                                                        <span style={{margin: '0 5px'}}>
                                                            <Typography variant="body2">-</Typography>
                                                        </span>
                                                        <span style={{textAlign: 'center', marginLeft: '5px'}}>
                                                            <Typography variant="body2">{formattedLastDay}</Typography>
                                                            <Typography
                                                                variant="caption">{formattedLastDate}</Typography>
                                                        </span>
                                                    </div>
                                                }
                                                clickable
                                                sx={
                                                    selectedLodgingForDates === lodging.id ? selectedChipStyle : unselectedChipStyle
                                                }
                                                onClick={() => handleLodgingChipClick(lodging.id)}
                                                style={{margin: '0.2rem', height: '40px'}}
                                            />

                                            {/* Icon Component Positioned Outside the Chip */}
                                            <i
                                                className="icon-check"
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px', // Position the icon above the Chip
                                                    right: '-5px',
                                                    fontSize: '12px',
                                                    color: 'green',
                                                    zIndex: 1,
                                                    borderColor: 'green',
                                                    backgroundColor: 'white',  // White background for the circle
                                                    borderRadius: '35%',       // Make it a circle
                                                    padding: '2px',            // Space between the icon and the border
                                                    border: '1px solid',
                                                    fontWeight: '800',
                                                    textShadow: '0 0 0 green, 0 0 0 green',
                                                }}
                                            />
                                        </Box>
                                    )
                                });
                                console.log('added a Chip : ', allChips);
                            }
                        });

                        // Step 2: Add placeholder Chips for missing event dates
                        let allLodgingDates = [];

                        eventLodging.forEach(lodging => {
                            if (lodging.time_table?.dates?.length > 0) {
                                lodging.time_table.dates.forEach(dateObj => allLodgingDates.push(dateObj.date));
                            }
                        });

                        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.dates?.length > 0) {

                            usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.dates?.forEach(eventDateObj => {
                                const eventDate = dayjs(eventDateObj.date, "YYYY-MM-DD");

                                const lodgingDateExists = allLodgingDates.some(lodgingDate =>
                                    dayjs(lodgingDate).isSame(eventDate, 'day')
                                );

                                if (!lodgingDateExists) {
                                    const formattedDay = eventDate.format('ddd');
                                    const formattedDate = eventDate.format('M/D');

                                    allChips.push({
                                        date: eventDate, // Use the event date for sorting
                                        component: (
                                            <Box key={`placeholder-${eventDateObj.date}`}
                                                 sx={{
                                                     position: 'relative',
                                                     display: 'inline-block',
                                                     marginTop: '5px',
                                                     marginRight: '10px'
                                                 }}
                                            >
                                                {/* Placeholder Chip Component */}
                                                <Chip
                                                    label={
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Typography variant="body2">{formattedDay}</Typography>
                                                            <Typography variant="caption">{formattedDate}</Typography>
                                                        </div>
                                                    }
                                                    sx={unselectedChipStyle} // Placeholder style for Chips
                                                    style={{ margin: '0.2rem', height: '40px' }}
                                                />

                                                {/* Icon Component Positioned Outside the Chip */}
                                                <i
                                                    className="icon-warning"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-2px', // Position the icon above the Chip
                                                        right: '-7px',
                                                        fontSize: '10px',
                                                        color: '#10318f',
                                                        zIndex: 1,
                                                        backgroundColor: 'white',  // Green background for the circle
                                                        borderRadius: '50%',       // Make it a circle
                                                        padding: '3px',            // Space between the icon and the border
                                                        border: '1px solid #F00'
                                                    }}
                                                />
                                            </Box>
                                        )
                                    });

                                }
                            });
                        }

                        // Step 3: Sort all Chips by date
                        allChips.sort((a, b) => a.date.isBefore(b.date) ? -1 : 1);

                        // Step 4: Render the Chips in chronological order
                        return allChips.map((chipObj) => chipObj.component);
                    })()
                ) : (
                    <div style={{padding: '20px'}}>
                        <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                        <Typography variant="caption">Need to add some Lodging information first</Typography>
                    </div>
                )}
            </div>


            {/* show the list of lodgings */}
            <div className="lodging-list-items-container">

                {
                    eventLodging && eventLodging.length > 0 ? (
                        eventLodging
                            .sort((a, b) => dayjs(a.begin_date).diff(dayjs(b.begin_date)))  // Sort flights by time
                            .map((lodgingDetails, index) => (

                                <Grid item key={index}
                                      sx={{
                                          display: 'block',
                                          marginBottom: '10px'
                                      }}
                                >

                                    <LodgingCard
                                        lodging={lodgingDetails}
                                        _global_map_ref={props._global_map_ref}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item>
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 140}}
                                        image={lodgingHeaderImage}
                                        title="Add Lodging"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Lodging
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Lodgings:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels,
                                            Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal
                                            Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and
                                            let you see what we've
                                            found. You can edit the results before we actually add the details to the
                                            Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click
                                            on the
                                            <i className="icon-menu2"/> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact
                                            Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>

            <InfoLodging
                isOpen={isLodgingInfoDialogOpen}
                onClose={() => setLodgingInfoDialogOpen(false)}
            />
        </div>
    )

}

export default LodgingList;

