import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {Chip, Menu, MenuItem, MenuList} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import './supplies.css';
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import _ from 'lodash';

import { allSuggestedItems } from '../../../sampleData/SupplyItemSuggestions';
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Box from "@mui/material/Box";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";



function SuppliesList(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();
    
    const { dataArray } = useContext(PAContext);
    const { Account, Attendees, SampleAttendees } = dataArray;

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    const [ selectedAttendeesArray, setSelectedAttendeesArray ] = useState( []);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);


    const [selectedAttendee, setSelectedAttendee] = useState(() => {
        return (
            attendeesReferenceArray.find((_att) => _att.id === Account.id && !_att.planner) ||
            attendeesReferenceArray.find((_att) => !_att.planner) ||
            null
        );
    });

    const [anchorSuppliesMenu, setAnchorSuppliesMenu] = useState(null);
    const handleSuppliesMenuOpenMenu = (event) => {
        setAnchorSuppliesMenu(event.currentTarget);
    };
    const handleSuppliesMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorSuppliesMenu(event.currentTarget);

        props.setSelectedAttendeeForSupplies(selectedAttendee);

        if (dataId === "new-entry"){
            navigate('/Planner/Supplies/AddNew');
        } else if (dataId === "from-essentials"){
            navigate('/Planner/Supplies/AddEssentials');
        } else if (dataId === "from-suggestion-list"){
            props.setShowEventItemConfig(true);
            props.setShowEventItemExtendedConfig(true);
            setAnchorSuppliesMenu(null);
            navigate('/Planner/Supplies/AddSuggestions');
        }
    };

    const handleSuppliesMenuClose = () => {
        setAnchorSuppliesMenu(null);
    };

    const [sortedGroupedItems, setSortedGroupedItems] = useState([]);




    const handleChipClick = (_attendee_id) => {
        console.log("Show supplies for : "+_attendee_id);

        setSelectedAttendee(_attendee_id);
        props.setSelectedAttendeeForSupplies(_attendee_id);

        if (PA_GlobalObject.Planning.supplies !== undefined) {
            const filteredItems = PA_GlobalObject.Planning.supplies.filter(item => item.assigned_to === _attendee_id);

            console.log("filteredItems : "+JSON.stringify(filteredItems, null, '\t'));

            // Group the filtered items by category and subcategory
            const groupedItems = _.groupBy(filteredItems, item => `${item.category}_${item.sub_category}`);

            console.log("groupedItems : "+JSON.stringify(groupedItems, null, '\t'));

            setSortedGroupedItems(groupedItems);

            setGroupedItems(groupedItems);
            // Sort the grouped items alphabetically by category and subcategory
//            setSortedGroupedItems(_.sortBy(Object.entries(groupedItems), ([key]) => key));
        }
    }

    useEffect(() => {
        // Sort the attendees by lname whenever the Attendees array changes
        console.log("PA_GlobalObject.Planning.supplies : "+JSON.stringify(PA_GlobalObject.Planning.supplies, null, '\t'));

        if (PA_GlobalObject.Planning.supplies !== undefined) {
            const filteredItems = PA_GlobalObject.Planning.supplies.filter(item => item.assigned_to === selectedAttendee);

            // Group the filtered items by category and subcategory
            const groupedItems = _.groupBy(filteredItems, item => `${item.category}_${item.sub_category}`);

            setSortedGroupedItems(groupedItems);

            setGroupedItems(groupedItems);
            // Sort the grouped items alphabetically by category and subcategory
//            setSortedGroupedItems(_.sortBy(Object.entries(groupedItems), ([key]) => key));

        }

    }, [props.setSelectedAttendeeForSupplies]);


    const [groupedItems, setGroupedItems] = useState(sortedGroupedItems); // Store items in state

    const toggleItemStatus = (itemId) => {
        setGroupedItems((prevItems) => {
            const updatedItems = { ...prevItems };

            Object.keys(updatedItems).forEach((categoryKey) => {
                updatedItems[categoryKey] = updatedItems[categoryKey].map((item) =>
                    item.id === itemId ? { ...item, status: item.status === 0 ? 1 : 0 } : item
                );
            });

            return updatedItems;
        });
    };

    const [isLodgingInfoDialogOpen, setLodgingInfoDialogOpen] = useState(false);


    return (

        <div className="supplies-list-container" >
            {/* show the header */}
            <div className="supplies-list-header">
                <div className="supplies-list-header-text">
                    <h5>Supplies</h5>
                </div>

                <div className="supplies-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setLodgingInfoDialogOpen(!isLodgingInfoDialogOpen)}
                    />
                </div>

                <div className="supplies-list-header-chip-holder">

                    {
                        PA_GlobalObject.Planning.attendees && PA_GlobalObject.Planning.attendees.length > 0 ? (
                            <>

                                <Chip
                                     sx={menuChipStyle}
                                    label={
                                        <i className="icon-menu2" style={{fontSize: '16px'}}/>
                                    }
                                    size="small"
                                    onClick={handleSuppliesMenuOpenMenu}
                                />
                                <Menu
                                    sx={{width: 320, maxWidth: '100%'}}
                                    anchorEl={anchorSuppliesMenu}
                                    id="library-menu"
                                    open={Boolean(anchorSuppliesMenu)}
                                    onClose={handleSuppliesMenuClose}

                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                >
                                    <MenuList
                                        dense
                                        sx={{width: 320, maxWidth: '100%'}}
                                    >
                                        <MenuItem
                                            data-id="from-essentials"
                                            onClick={handleSuppliesMenuClick}
                                            //                disabled={dataArray.Account.essentials === undefined}
                                        >
                                            <ListItemText>From My Essentials</ListItemText>
                                            <Typography variant="body2" color="text.secondary">
                                                {
                                                    dataArray.Account.essentials === undefined ? 0 : dataArray.Account.essentials.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem data-id="from-suggestion-list" onClick={handleSuppliesMenuClick}>
                                            <ListItemText>From Suggestions</ListItemText>
                                            <Typography variant="body2" color="text.secondary">
                                                {
                                                    allSuggestedItems.length
                                                }
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem data-id="new-entry" onClick={handleSuppliesMenuClick}>
                                            <ListItemText>New Entry</ListItemText>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </>
                        ) : (
                            <></>
                        )
                    }

                </div>
            </div>

            {/* show the list of attendees */}
            <div className="supplies-list-attendee-list-container">
                {
                    PA_GlobalObject.Planning.attendees && PA_GlobalObject.Planning.attendees.length > 0 ? (
                        PA_GlobalObject.Planning.attendees
                            .filter(_att => !_att.planner)
                            .sort((a, b) => {
                                // Check if the current attendee is the user (you)
                                if (a.id === Account.id) return -1; // You should come first
                                if (b.id === Account.id) return 1;  // If not you, the other attendee comes after

                                // Otherwise, sort alphabetically by nickname
                                const nameA = capitalizeFirstLetter(a.nickname).toLowerCase();
                                const nameB = capitalizeFirstLetter(b.nickname).toLowerCase();
                                return nameA.localeCompare(nameB);
                            })
                            .map((_att, index) => (
                            <Badge
                                key={index}
                                badgeContent={
                                    PA_GlobalObject.Planning.supplies.reduce((count, item) => count + (item.assigned_to.includes(_att.id) ? 1 : 0), 0)
                                }
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'white', // Customize the dot color here
                                        border: '1px solid #ddd', // Make it a circle
                                        width: '16px', // Adjust the dot size as needed
                                        height: '16px',
                                        borderRadius: '50%', // Make it a circle
                                        top: '10px', // Adjust top and right values to move the dot
                                        right: '10px', // Adjust top and right values to move the dot
                                    },
                                }}
                            >
                                <Chip
                                    sx={selectedAttendee === _att.id ? selectedChipStyle : unselectedChipStyle}
                                    label={capitalizeFirstLetter(_att.nickname || _att.fname)}
                                    clickable
                                    onClick={() => handleChipClick(_att.id)}
                                    style={{ margin: '0.5rem' }}
                                />
                            </Badge>
                        ))

                    ) : (

                        <div style={{padding: '20px'}} >
                            {/* need to add some attendees first */}
                            <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                            <Typography variant="caption">Need to add some Attendees first</Typography>
                        </div>
                    )

                }
            </div>

            {/* show the list of supplies for the selected attendee */}
            <div className="supplies-list-items-container"
                 style={{
                     display: PA_GlobalObject.Planning.attendees.length === 0 ? "none":"block"
                }}
            >
                {
                    Object.keys(groupedItems)
                        .sort((a, b) =>
                            a.replace(/^food_/, '').replace(/^supplies_/, '').replace(/_/g, ' ')
                                .localeCompare(
                                    b.replace(/^food_/, '').replace(/^supplies_/, '').replace(/_/g, ' ')
                                )
                        ) // Sort categories properly
                        .map((categoryKey) => (
                            <Box key={categoryKey}>
                                {/* Category Header */}
                                <Typography variant="h6" gutterBottom>
                                    {categoryKey.replace(/^food_/, '').replace(/^supplies_/, '').replace(/_/g, ' ')}
                                </Typography>

                                {groupedItems[categoryKey]
                                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort items alphabetically
                                    .map((item) => {
                                        const assignedByAttendee = attendeesReferenceArray.find((_att) => _att.id === item.assigned_by);

                                        return (
                                            <Box
                                                key={item.id}
                                                sx={{ cursor: 'pointer', paddingBottom: '10px' }}
                                                p={1}
                                                onClick={() => toggleItemStatus(item.id)}
                                            >
                                                {/* Item Name with Strikethrough if Status = 0 */}
                                                <Typography
                                                    sx={{
                                                        textDecoration: item.status === 0 ? 'line-through' : 'none',
                                                        fontWeight: 'bold'
                                                    }}
                                                    variant="body1"
                                                >
                                                    {capitalizeFirstLetter(item.name)}
                                                </Typography>

                                                {/* Assigned By (if applicable) */}
                                                {item.assigned_by !== item.assigned_to && assignedByAttendee && (
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'gray', fontStyle: 'italic' }}
                                                    >
                                                        Assigned By: {assignedByAttendee.nickname || assignedByAttendee.fname}
                                                    </Typography>
                                                )}

                                                {/* Icons (Private & Essential) */}
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: '3px' }}>
                                                    {item.private && (
                                                        <i className="icon-eye-cross event-supplies-attendee-list-item-category-line-private-icon" />
                                                    )}
                                                    {item.essential && item.assigned_to === Account.id && (
                                                        <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon" />
                                                    )}
                                                </Box>

                                                {/* Notes (if applicable) */}
                                                {item.notes && (
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'gray', marginTop: '5px' }}
                                                    >
                                                        {item.notes}
                                                    </Typography>
                                                )}
                                            </Box>
                                        );
                                    })}
                            </Box>
                        ))
                }

            </div>

        </div>
    )
}

export default SuppliesList;
