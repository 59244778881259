import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Button, Menu, MenuItem, FormControlLabel, Checkbox, Tab,
} from "@mui/material";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../sampleData/currencyList";
import usePA_GlobalStore from "../../Services/ZustandGlobalStore";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {capitalizeFirstLetter} from "../Helpers/TextStyling";

function BusinessExpenseAuthorizationDialog({ isOpen, onClose }) {

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventHasDates, setEventHasDates] = useState(PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(PA_GlobalObject.Planning.time_table?.days?.length > 0);

    useEffect(() => {
        if (isOpen) {
            const businessAuth = usePA_GlobalStore.getState().PA_GlobalObject.Planning?.business_authorizations || {};

            setEventTotalLimit(businessAuth.eventTotalLimit || 0);
            setDailyLimit(businessAuth.dailyLimit || 0);
            setExpenseCurrency(businessAuth.currency || "USD");

            setCategoryMode(businessAuth.categoryMode || "same");

            // Load Category Limits
            setUseLodgingLimit(businessAuth.categoryLimits?.lodging !== null);
            setLodgingLimit(businessAuth.categoryLimits?.lodging || "");

            setUseFlightLimit(businessAuth.categoryLimits?.flights !== null);
            setFlightLimit(businessAuth.categoryLimits?.flights || "");

            setUseActivityLimit(businessAuth.categoryLimits?.activities !== null);
            setActivityLimit(businessAuth.categoryLimits?.activities || "");

            setUseMealLimit(businessAuth.categoryLimits?.meals !== null);
            setMealLimit(businessAuth.categoryLimits?.meals || "");

            setUseIncidentalsLimit(businessAuth.categoryLimits?.incidentals !== null);
            setIncidentalsLimit(businessAuth.categoryLimits?.incidentals || "");

            // Load Individual Attendee Limits
            if (businessAuth.individualLimits) {
                setIndividualLimits(businessAuth.individualLimits);
            } else {
                // Default Individual Limits (if missing)
                const defaultLimits = PA_GlobalObject.Planning.attendees.reduce((acc, attendee) => {
                    acc[attendee.id] = {
                        lodging: "",
                        flights: "",
                        activities: "",
                        meals: "",
                        incidentals: "",
                    };
                    return acc;
                }, {});
                setIndividualLimits(defaultLimits);
            }
        }
    }, [isOpen]);

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const [eventTotalLimit, setEventTotalLimit] = useState(0);
    const [dailyLimit, setDailyLimit] = useState(0);
    const [expenseCurrency, setExpenseCurrency] = useState('USD');

    // **Category-Based Limits**
    const [categoryMode, setCategoryMode] = useState("same"); // "same" or "custom"

    const [useLodgingLimit, setUseLodgingLimit] = useState(false);
    const [lodgingLimit, setLodgingLimit] = useState("");

    const [useFlightLimit, setUseFlightLimit] = useState(false);
    const [flightLimit, setFlightLimit] = useState("");

    const [useActivityLimit, setUseActivityLimit] = useState(false);
    const [activityLimit, setActivityLimit] = useState("");

    const [useMealLimit, setUseMealLimit] = useState(false);
    const [mealLimit, setMealLimit] = useState("");

    const [useIncidentalsLimit, setUseIncidentalsLimit] = useState(false);
    const [incidentalsLimit, setIncidentalsLimit] = useState("");

    // Individual Attendee Limits
    const [individualLimits, setIndividualLimits] = useState(
        PA_GlobalObject.Planning.attendees.reduce((acc, attendee) => {
            acc[attendee.id] = {
                lodging: "",
                flights: "",
                activities: "",
                meals: "",
                incidentals: ""
            };
            return acc;
        }, {})
    );

    // Handle Tab Change (Same vs Custom)
    const handleTabChange = (event, newValue) => {
        setCategoryMode(newValue);
    };

    // Handle Individual Attendee Limit Change
    const handleIndividualLimitChange = (attendeeId, category, value) => {
        setIndividualLimits((prev) => ({
            ...prev,
            [attendeeId]: { ...prev[attendeeId], [category]: value }
        }));
    };

    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        setExpenseCurrency(code);
        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    const saveBusinessAuthorizations = () => {
        
        set_PA_GlobalObject((prev) => {
            return {
                Planning: {
                    ...prev.Planning,
                    business_authorizations: {
                        // **Global Limits**
                        eventTotalLimit: Number(eventTotalLimit) || 0,
                        dailyLimit: Number(dailyLimit) || 0,
                        currency: expenseCurrency,

                        // **Category Mode (Same or Custom)**
                        categoryMode,

                        // **Category-Based Limits**
                        categoryLimits: {
                            lodging: useLodgingLimit ? Number(lodgingLimit) || 0 : null,
                            flights: useFlightLimit ? Number(flightLimit) || 0 : null,
                            activities: useActivityLimit ? Number(activityLimit) || 0 : null,
                            meals: useMealLimit ? Number(mealLimit) || 0 : null,
                            incidentals: useIncidentalsLimit ? Number(incidentalsLimit) || 0 : null,
                        },

                        // **Individual Attendee Limits**
                        individualLimits: Object.keys(individualLimits).reduce((acc, attendeeId) => {
                            acc[attendeeId] = {
                                lodging: individualLimits[attendeeId].lodging ? Number(individualLimits[attendeeId].lodging) : null,
                                flights: individualLimits[attendeeId].flights ? Number(individualLimits[attendeeId].flights) : null,
                                activities: individualLimits[attendeeId].activities ? Number(individualLimits[attendeeId].activities) : null,
                                meals: individualLimits[attendeeId].meals ? Number(individualLimits[attendeeId].meals) : null,
                                incidentals: individualLimits[attendeeId].incidentals ? Number(individualLimits[attendeeId].incidentals) : null,
                            };
                            return acc;
                        }, {}),
                    },
                },
            };
        });

        //close the Dialog
        onClose();
    };



    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>Business Expense Authorization</DialogTitle>
            <DialogContent>
                {/* set the master currency to apply to all amounts */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>Set the Currency</Typography>
                    <Box display="flex" alignItems="center">
                        <IconButton
                            sx={{fontSize: '12px', color:'initial'}}
                            aria-label="more"
                            id="long-button"
                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleOpenChangeCurrency}
                        >
                            {expenseCurrency}
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorChangeCurrencyEl}
                            open={openChangeCurrency}
                            onClose={handleChangeCurrencyClose}
                        >
                            {currencyList.map((option) => (
                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === PA_GlobalObject.Planning['budget_currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                    {option.code} : {option.name}
                                </MenuItem>
                            ))}
                        </Menu>

                    </Box>
                </Box>

                {/* Event Limit Row */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>Event</Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size="small"
                            variant="outlined"
                            value={eventTotalLimit}
                            onChange={(e) => setEventTotalLimit(e.target.value)}
                            sx={{ width: "120px", mr: 1 }}
                        />
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>{expenseCurrency}</Typography>
                    </Box>
                </Box>

                {/* Event Limit Description */}
                <Typography variant="body2" color="textSecondary" sx={{mb: 2}}>
                    This is the total authorized amount per attendee for all items marked as business expenses for the entire event.
                    So each Attendee will have an Event expenditure authorization for&nbsp;
                    <strong>
                        {eventTotalLimit ? Number(eventTotalLimit).toLocaleString() : "N/A"} {expenseCurrency || "USD"}
                    </strong>
                    .
                </Typography>

                {/* Daily Limit Row */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>Daily Limit</Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size="small"
                            variant="outlined"
                            value={dailyLimit}
                            onChange={(e) => setDailyLimit(e.target.value)}
                            sx={{ width: "120px", mr: 1 }}
                        />
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>{expenseCurrency}</Typography>
                    </Box>
                </Box>

                {/* Daily Limit Description */}
                <Typography variant="body2" color="textSecondary">
                    Each Attendee will have a daily expenditure authorization for&nbsp;
                    <strong>
                        {dailyLimit ? Number(dailyLimit).toLocaleString() : "N/A"} {expenseCurrency || "USD"}
                    </strong>.
                    This daily limit will be applied to Meals, Activities, Incidentals.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Each attendee's daily expenditure authorization applies to meals, activities, and
                    incidentals. If an event limit is set, the combined daily limits will be compared to
                    the total event authorization amount to determine whether the limits have been exceeded.
                </Typography>

                {/*
                    Itemized Description
                    this is where we can fine-tune the authorization levels
                */}
                <Box>
                    {/* Category Limits Title + Tabs (Same/Custom) */}
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>Category Limits</Typography>
                        <Typography variant="body2" color="textSecondary">
                            On the SAME tab, you can set a single value per category that applies to all attendees.
                            On the CUSTOM tab, you can personalize category limits for each attendee.
                        </Typography>
                        <TabContext value={categoryMode}>
                            <TabList
                                onChange={handleTabChange}
                                aria-label="Category Limits Mode"
                                sx={{ minHeight: "30px" }}
                            >
                                <Tab label="Same" value="same" sx={{ fontSize: "12px", minHeight: "30px", padding: "5px" }} />
                                <Tab label="Custom" value="custom" sx={{ fontSize: "12px", minHeight: "30px", padding: "5px" }} />
                            </TabList>
                        </TabContext>
                    </Box>

                    {/* SAME LIMITS FOR ALL ATTENDEES */}
                    {categoryMode === "same" && (
                        [
                            { label: "Lodging (per night)", state: useLodgingLimit, setState: setUseLodgingLimit, value: lodgingLimit, setValue: setLodgingLimit },
                            { label: "Flights", state: useFlightLimit, setState: setUseFlightLimit, value: flightLimit, setValue: setFlightLimit },
                            { label: "Activities (per activity)", state: useActivityLimit, setState: setUseActivityLimit, value: activityLimit, setValue: setActivityLimit },
                            { label: "Meals (per day)", state: useMealLimit, setState: setUseMealLimit, value: mealLimit, setValue: setMealLimit },
                            { label: "Incidentals", state: useIncidentalsLimit, setState: setUseIncidentalsLimit, value: incidentalsLimit, setValue: setIncidentalsLimit },
                        ].map((item, index) => (
                            <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body1">{item.label}</Typography>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        disabled={!item.state}
                                        value={item.value}
                                        onChange={(e) => item.setValue(e.target.value)}
                                        sx={{ width: "120px", mr: 1 }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={item.state} onChange={(e) => item.setState(e.target.checked)} />}
                                        label=""
                                    />
                                </Box>
                            </Box>
                        ))
                    )}

                    {/* CUSTOM LIMITS FOR EACH ATTENDEE */}
                    {categoryMode === "custom" && (
                        <>
                            {PA_GlobalObject.Planning.attendees
                                    .filter((attendee) => !attendee.planner && attendee.status !== 4) // Exclude planners and status 4
                                    .map((attendee) => (
                                        <Box key={attendee.id} mb={2}>
                                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                {capitalizeFirstLetter(attendee.fname)} {capitalizeFirstLetter(attendee.lname)}
                                            </Typography>
                                            {["lodging", "flights", "activities", "meals", "incidentals"].map((category) => (
                                                <Box key={category} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                    <Typography variant="body2" color="textSecondary" sx={{ pl: 2 }}>
                                                        {category.charAt(0).toUpperCase() + category.slice(1)}
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        value={individualLimits[attendee.id]?.[category] || ""}
                                                        onChange={(e) => handleIndividualLimitChange(attendee.id, category, e.target.value)}
                                                        sx={{ width: "120px", mr: 1 }}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    ))
                            }
                        </>
                    )}
                </Box>
            </DialogContent>

            {/* Dialog Actions */}
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={saveBusinessAuthorizations} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
}


export default BusinessExpenseAuthorizationDialog;
