import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ListItem, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {capitalizeFirstLetter} from "../Helpers/TextStyling";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import React, {useEffect, useState} from "react";
import usePA_GlobalStore from "../../Services/ZustandGlobalStore";
import dayjs from "dayjs";


function BusinessExpenseReport({ isOpen, onClose }) {

    const { PA_GlobalObject } = usePA_GlobalStore();


    const attendeesWithExpenses = PA_GlobalObject.Planning.attendees.filter((attendee) =>
        [PA_GlobalObject.Planning.lodging, PA_GlobalObject.Planning.meals, PA_GlobalObject.Planning.activities, PA_GlobalObject.Planning.flights].some((category) =>
            category.some((item) =>
                item.attendees?.some((att) => att.id === attendee.id && att.is_business_expense)
            )
        )
    );



    const [eventTotalLimit, setEventTotalLimit] = useState(0);
    const [eventDailyLimit, setEventDailyLimit] = useState(0);
    const [expenseCurrency, setExpenseCurrency] = useState('USD');

    // **Category-Based Limits**
    const [categoryMode, setCategoryMode] = useState("same"); // "same" or "custom"

    const [useLodgingLimit, setUseLodgingLimit] = useState(false);
    const [lodgingLimit, setLodgingLimit] = useState("");

    const [useFlightLimit, setUseFlightLimit] = useState(false);
    const [flightLimit, setFlightLimit] = useState("");

    const [useActivityLimit, setUseActivityLimit] = useState(false);
    const [activityLimit, setActivityLimit] = useState("");

    const [useMealLimit, setUseMealLimit] = useState(false);
    const [mealLimit, setMealLimit] = useState("");

    const [useIncidentalsLimit, setUseIncidentalsLimit] = useState(false);
    const [incidentalsLimit, setIncidentalsLimit] = useState("");

    // Individual Attendee Limits
    const [individualLimits, setIndividualLimits] = useState(
        PA_GlobalObject.Planning.attendees.reduce((acc, attendee) => {
            acc[attendee.id] = {
                lodging: "",
                flights: "",
                activities: "",
                meals: "",
                incidentals: ""
            };
            return acc;
        }, {})
    );


    useEffect(() => {
        const businessAuth = usePA_GlobalStore.getState().PA_GlobalObject.Planning.business_authorizations || {};

        //load all of the limits
        setExpenseCurrency(businessAuth.currency || "USD");
        setEventTotalLimit(businessAuth.eventTotalLimit || 0);
        setEventDailyLimit(businessAuth.dailyLimit || 0);

        // 🌟 Load Category-Based Limits
        setUseLodgingLimit(!!businessAuth['categoryLimits']?.lodging || false);
        setLodgingLimit(businessAuth['categoryLimits']?.lodging || "");

        setUseFlightLimit(!!businessAuth['categoryLimits']?.flights || false);
        setFlightLimit(businessAuth['categoryLimits']?.flights || "");

        setUseActivityLimit(!!businessAuth['categoryLimits']?.activities || false);
        setActivityLimit(businessAuth['categoryLimits']?.activities || "");

        setUseMealLimit(!!businessAuth['categoryLimits']?.meals || false);
        setMealLimit(businessAuth['categoryLimits']?.meals || "");

        setUseIncidentalsLimit(!!businessAuth['categoryLimits']?.incidentals || false);
        setIncidentalsLimit(businessAuth['categoryLimits']?.incidentals || "");

        // 🌟 Load Individual Attendee Limits
        if (businessAuth.individualLimits) {
            setIndividualLimits(businessAuth.individualLimits);
        }

    }, [PA_GlobalObject.Planning.business_authorizations]);

    const remainingLimits = {
        eventTotal: eventTotalLimit, // Overall event-wide limit
        dailyTotal: eventDailyLimit,
        categories: {
            lodging: useLodgingLimit ? lodgingLimit : null,
            flights: useFlightLimit ? flightLimit : null,
            activities: useActivityLimit ? activityLimit : null,
            meals: useMealLimit ? mealLimit : null,
            incidentals: useIncidentalsLimit ? incidentalsLimit : null
        },
        individual: { ...individualLimits } // Copy of per-attendee limits
    };

    console.log("remainingLimits : ", remainingLimits);

    const [expandedItems, setExpandedItems] = useState({});

    // Function to toggle expansion
    const toggleExpand = (attendeeId, itemId) => {
        const key = `${attendeeId}-${itemId}`; // Create a unique key
        setExpandedItems((prev) => ({
            ...prev,
            [key]: !prev[key], // Toggle only for this attendee's specific item
        }));
    };

    const formatDate = (timestamp) => (timestamp ? dayjs(timestamp).format("MMM D, YYYY") : "N/A");


    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Business Expense Report</DialogTitle>
            <DialogContent>
                {attendeesWithExpenses.length === 0 ? (
                    <Typography>No business expenses found.</Typography>
                ) : (
                    attendeesWithExpenses.map((attendee) => {
                        const attendeeExpenses = {
                            lodging: PA_GlobalObject.Planning.lodging.filter((lodgingItem) =>
                                lodgingItem.attendees?.some(
                                    (att) => att.id === attendee.id && att.is_business_expense
                                )
                            ),
                            meals: PA_GlobalObject.Planning.meals.filter((mealItem) =>
                                mealItem.attendees?.some(
                                    (att) => att.id === attendee.id && att.is_business_expense
                                )
                            ),
                            activities: PA_GlobalObject.Planning.activities.filter((activityItem) =>
                                activityItem.attendees?.some(
                                    (att) => att.id === attendee.id && att.is_business_expense
                                )
                            ),
                            flights: PA_GlobalObject.Planning.flights.filter((flightItem) =>
                                flightItem.attendees?.some(
                                    (att) => att.id === attendee.id && att.is_business_expense
                                )
                            ),
                        };

                        // Calculate the total for the attendee grouped by currency
                        const totalExpensesByCurrency = {};
                        Object.values(attendeeExpenses).forEach((category) => {
                            category.forEach((item) => {
                                const expense = item.attendees.find((att) => att.id === attendee.id);
                                const currency = item.currency || "USD"; // Default to USD if no currency
                                const expenseAmount = parseFloat(expense.business_expense_amount) || 0; // Ensure numeric conversion

                                if (!totalExpensesByCurrency[currency]) {
                                    totalExpensesByCurrency[currency] = 0;
                                }

                                totalExpensesByCurrency[currency] += expenseAmount; // Correct numeric summation
                            });
                        });

                        const eventLimit = remainingLimits.eventTotal;
                        const dailyLimit = remainingLimits.dailyTotal || null;
                        const categoryLimits = remainingLimits.categories;
                        const individualLimits = remainingLimits.individual[attendee.id] || {};

                        let eventRemaining = eventLimit;
                        let dailyRemaining = dailyLimit;
                        let categoryRemaining = { ...categoryLimits };
                        let individualRemaining = { ...individualLimits };

                        let dailyExpenseTracker = {};

                        return (
                            <div key={attendee.id} style={{ marginBottom: "20px" }}>
                                {/* Attendee Name & Total grouped by currency */}
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h6">
                                        {capitalizeFirstLetter(attendee.fname)} {capitalizeFirstLetter(attendee.lname)}
                                    </Typography>
                                    <Box textAlign="right">
                                        {Object.entries(totalExpensesByCurrency).map(([currency, total]) => (
                                            <Typography key={currency} variant="h6" color="primary">
                                                {Number(total).toFixed(2).toLocaleString()} {currency}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                                <Divider style={{ marginBottom: "10px" }} />

                                {Object.entries(attendeeExpenses).map(([category, items]) => {
                                    if (items.length === 0) return null;

                                    // Calculate totals per currency for this category
                                    const categoryTotalsByCurrency = {};
                                    items.forEach((item) => {
                                        const expense = item.attendees.find((att) => att.id === attendee.id);
                                        const currency = item.currency || "USD";
                                        const expenseAmount = parseFloat(expense.business_expense_amount) || 0; // Ensure numeric conversion

                                        if (!categoryTotalsByCurrency[currency]) {
                                            categoryTotalsByCurrency[currency] = 0;
                                        }
                                        categoryTotalsByCurrency[currency] += expenseAmount;
                                    });


                                    return (
                                        <div key={category} style={{ marginBottom: "15px" }}>
                                            {/* Category Name & Total grouped by currency */}
                                            {Object.entries(categoryTotalsByCurrency).map(([currency, total]) => (
                                                <Box key={currency} display="flex" justifyContent="space-between" fontWeight="bold">
                                                    <Typography variant="subtitle1" color="primary">
                                                        {category.charAt(0).toUpperCase() + category.slice(1)}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="primary">
                                                        {Number(total || 0).toFixed(2).toLocaleString()} {currency}
                                                    </Typography>
                                                </Box>
                                            ))}

                                            {/* List of Expenses (Light Gray) */}
                                            <List dense style={{ backgroundColor: "#f8f8f8", padding: "5px 0 5px 10px", borderRadius: "5px" }}>
                                                {items.map((item) => {
                                                    const expense = item.attendees.find((att) => att.id === attendee.id);
                                                    if (!expense || !expense.is_business_expense) return null; // Skip items without business expenses

                                                    const uniqueKey = `${attendee.id}-${item.id}`; // Unique key per attendee-item pair
                                                    const isExpanded = expandedItems[uniqueKey] || false;

                                                    let expenseAmount = parseFloat(expense.business_expense_amount) || 0;
                                                    let authorizedFromIndividual = 0,
                                                        authorizedFromDaily = 0,
                                                        authorizedFromCategory = 0,
                                                        authorizedFromEvent = 0;

                                                    // **Determine number of days/nights**
                                                    let numDays = item.time_table?.dates?.length || item.time_table?.days?.length || 1;
                                                    let perDayCost = expenseAmount / numDays;

                                                    console.log(`Expense: ${category} - ${expenseAmount}, Nights/Days: ${numDays}, Per Day Cost: ${perDayCost}`);

                                                    console.log("eventRemaining : ",eventRemaining);
                                                    console.log("dailyRemaining : ",dailyRemaining);

                                                    // ✅ Ensure dailyRemaining is an object before assigning properties
                                                    if (typeof dailyRemaining !== "object" || dailyRemaining === null) {
                                                        dailyRemaining = {}; // Reinitialize as an empty object if it's not already
                                                    }
                                                    // Ensure categoryRemaining is an object per day
                                                    if (typeof categoryRemaining[category] !== "object" || categoryRemaining[category] === null) {
                                                        categoryRemaining[category] = {};
                                                    }

                                                    // ✅ Track expenses per day using dates (preferred) or days fallback
                                                    let dateKeys = [];

                                                    if (item.time_table?.dates?.length > 0) {
                                                        dateKeys = item.time_table.dates.map(dateObj => dateObj.date);
                                                    } else if (item.time_table?.days?.length > 0) {
                                                        dateKeys = item.time_table.days.map(dayObj => Object.keys(dayObj)[0]); // e.g., "day 1"
                                                    } else {
                                                        dateKeys = ["day 1"]; // Default fallback if no dates or days exist
                                                    }

                                                    dateKeys.forEach((dateKey) => {
                                                        if (!dailyRemaining[dateKey]) {
                                                            dailyRemaining[dateKey] = remainingLimits.dailyTotal; // ✅ Assign daily limit per day
                                                        }
                                                        if (!categoryRemaining[category][dateKey]) {
                                                            categoryRemaining[category][dateKey] = remainingLimits['categories'][category] || 0;
                                                        }
                                                    });


                                                    console.log("dateKeys : "+dateKeys);

                                                    console.log("calculating authorizations .....");

                                                    // ✅ **Step 1: Deduct Lodging Cost from Daily Limit**
                                                    if (category === "lodging" && (dailyLimit !== null || remainingLimits['categories'].lodging)) {
                                                        console.log("it is a lodging");

                                                        dateKeys.forEach((dateKey) => {
                                                            console.log("dailyRemaining : ",dailyRemaining);

                                                            let availableDailyOrCategoryLimit = dailyRemaining[dateKey] || remainingLimits['categories'].lodging || 0;

                                                            if (availableDailyOrCategoryLimit > 0) {
                                                                let maxAllowable = Math.min(perDayCost, availableDailyOrCategoryLimit);

                                                                if (remainingLimits['categories'].lodging){
                                                                    authorizedFromCategory += maxAllowable;
                                                                } else if (dailyLimit !== null){
                                                                    authorizedFromDaily += maxAllowable;
                                                                    dailyRemaining[dateKey] -= maxAllowable; // ✅ **Reduce the daily limit properly**
                                                                }

                                                            }
                                                        });
                                                        if (remainingLimits['categories'].lodging) {
                                                            expenseAmount -= authorizedFromCategory; // ✅ **Reduce the expenseAmount**
                                                        }  else if (dailyLimit !== null) {
                                                            expenseAmount -= authorizedFromDaily; // ✅ **Reduce the expenseAmount**
                                                        }

                                                    }

                                                   // ✅ **Step 2: Deduct from Daily Limit for Non-Lodging Expenses**
                                                    if (expenseAmount > 0 && dailyLimit !== null) {
                                                        let totalAuthorizedFromDaily = 0;
                                                        dateKeys.forEach((dateKey) => {
                                                            let availableDailyLimit = dailyRemaining[dateKey] || 0;

                                                            if (availableDailyLimit > 0) {
                                                                let maxAllowable = Math.min(expenseAmount, availableDailyLimit);
                                                                totalAuthorizedFromDaily += maxAllowable;
                                                                dailyRemaining[dateKey] -= maxAllowable; // ✅ **Update dailyRemaining**
                                                            }
                                                        });
                                                        authorizedFromDaily += totalAuthorizedFromDaily;
                                                        expenseAmount -= totalAuthorizedFromDaily;
                                                    }

                                                   // ✅ **Step 3: Deduct from Individual Limit**
                                                    if (expenseAmount > 0 && individualRemaining[category]) {
                                                        const maxAllowable = Math.min(expenseAmount, individualRemaining[category]);
                                                        authorizedFromIndividual = maxAllowable;
                                                        individualRemaining[category] -= maxAllowable;
                                                        expenseAmount -= maxAllowable;
                                                    }

                                                    // ✅ **Step 4: Deduct from Category Limit**
                                                    if (expenseAmount > 0 && categoryRemaining[category]) {
                                                        let totalAuthorizedFromCategory = 0;

                                                        dateKeys.forEach((dateKey) => {
                                                            let availableCategoryLimit = categoryRemaining[category][dateKey] || 0; // Get per-day category limit

                                                            if (availableCategoryLimit > 0) {
                                                                let maxAllowable = Math.min(expenseAmount, availableCategoryLimit);
                                                                totalAuthorizedFromCategory += maxAllowable;
                                                                categoryRemaining[category][dateKey] -= maxAllowable; // ✅ Deduct per day
                                                                expenseAmount -= maxAllowable;
                                                            }
                                                        });

                                                        authorizedFromCategory += totalAuthorizedFromCategory;
                                                    }
                                                    // ✅ **Step 5: Deduct from Event-Wide Limit**
                                                    if (expenseAmount > 0 && eventRemaining > 0) {
                                                        const maxAllowable = Math.min(expenseAmount, eventRemaining);
                                                        authorizedFromEvent = maxAllowable;
                                                        eventRemaining -= maxAllowable;
                                                        expenseAmount -= maxAllowable;
                                                    }

                                                    const totalAuthorized = authorizedFromIndividual + authorizedFromDaily + authorizedFromCategory + authorizedFromEvent;
                                                    const remainingAmount = Math.max((expense.business_expense_amount || 0) - totalAuthorized, 0);

                                                    console.log("AFTER calculating authorizations .....");
                                                    console.log("authorizedFromIndividual : "+authorizedFromIndividual);
                                                    console.log("authorizedFromDaily : "+authorizedFromDaily);
                                                    console.log("authorizedFromCategory : "+authorizedFromCategory);
                                                    console.log("authorizedFromEvent : "+authorizedFromEvent);
                                                    console.log("individualRemaining[category] : "+individualRemaining[category]);
                                                    console.log("categoryRemaining[category] : "+categoryRemaining[category]);
                                                    console.log("eventRemaining : "+eventRemaining);
                                                    console.log("dailyRemaining : ",dailyRemaining);
                                                    console.log("expenseAmount : "+expenseAmount);
                                                    console.log("remainingLimits : ", remainingLimits);
                                                    console.log("remainingAmount : ", remainingAmount);
                                                    console.log("-----------------------------------");

                                                    return (
                                                        <ListItem key={uniqueKey} alignItems="flex-start" sx={{paddingRight:0}}>
                                                            {/* Primary Section with Toggle Button */}
                                                            <ListItemText
                                                                primary={
                                                                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                                                        <Typography variant="body2">{item.name || item.title}</Typography>
                                                                        <Box display="flex" alignItems="center">
                                                                            {
                                                                                remainingAmount > 0 ?
                                                                                    remainingAmount === parseFloat(expense.business_expense_amount) || 0 ?
                                                                                        <i className="icon-warning"
                                                                                           style={{
                                                                                               fontSize: '11px',
                                                                                               color: 'red',
                                                                                               border: '1px solid #428ce2',
                                                                                               borderRadius: '20px',
                                                                                               padding: '5px',
                                                                                           }}
                                                                                        />
                                                                                        :
                                                                                        <i className="icon-warning"
                                                                                           style={{
                                                                                               fontSize: '11px',
                                                                                               color: 'orange',
                                                                                               border: '1px solid #428ce2',
                                                                                               borderRadius: '20px',
                                                                                               padding: '5px',
                                                                                           }}
                                                                                        />
                                                                                    :
                                                                                    <i className="icon-check"
                                                                                       style={{
                                                                                           fontSize: '11px',
                                                                                           color: 'green',
                                                                                           border: '1px solid #428ce2',
                                                                                           borderRadius: '20px',
                                                                                           padding: '5px',
                                                                                       }}/>
                                                                            }
                                                                            <Typography variant="body2" sx={{marginLeft:'10px'}}>
                                                                                <strong>
                                                                                    {Number(expense?.business_expense_amount || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency || "USD"}
                                                                                </strong>
                                                                            </Typography>
                                                                            <IconButton size="small" onClick={() => toggleExpand(attendee.id, item.id)} sx={{ marginLeft: "8px" }}>
                                                                                {isExpanded ?
                                                                                    <i className="icon-chevron-up" style={{fontSize:'14px'}}/> :
                                                                                    <i className="icon-chevron-down" style={{fontSize:'14px'}}/>}
                                                                            </IconButton>

                                                                        </Box>
                                                                    </Box>
                                                                }
                                                                secondary={isExpanded && (
                                                                    <Box textAlign="right" sx={{ marginTop: "8px", width: "100%" }}>
                                                                        <Typography variant="body2" color="textPrimary" fontWeight="bold">
                                                                            Auth & Payment
                                                                        </Typography>
                                                                        <Box display="flex" flexDirection="column" alignItems="flex-end" gap={0.5}>

                                                                            {authorizedFromIndividual > 0 && (
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Individual Authorization:&nbsp;&nbsp;
                                                                                    <strong>
                                                                                        {Number(authorizedFromIndividual || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency || "USD"}
                                                                                    </strong>
                                                                                </Typography>
                                                                            )}
                                                                            {authorizedFromDaily > 0 && (
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Daily Authorization:&nbsp;&nbsp;
                                                                                    <strong>
                                                                                        {Number(authorizedFromDaily || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency || "USD"}
                                                                                    </strong>
                                                                                </Typography>
                                                                            )}
                                                                            {authorizedFromCategory > 0 && (
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Category Authorization:&nbsp;&nbsp;
                                                                                    <strong>
                                                                                        {Number(authorizedFromCategory || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency || "USD"}
                                                                                    </strong>
                                                                                </Typography>
                                                                            )}
                                                                            {authorizedFromEvent > 0 && (
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    Event Authorization:&nbsp;&nbsp;
                                                                                    <strong>
                                                                                        {Number(authorizedFromEvent || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency || "USD"}
                                                                                    </strong>
                                                                                </Typography>
                                                                            )}

                                                                            {/* Total Covered */}
                                                                            <Typography variant="body2" color="textSecondary" sx={{ fontWeight: "bold" }}>
                                                                                Total Covered:&nbsp;&nbsp;
                                                                                <strong>
                                                                                    {Number(totalAuthorized || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency || "USD"}
                                                                                </strong>
                                                                            </Typography>

                                                                            {/* Remaining Out-of-Pocket Amount */}
                                                                            {expense.business_expense_amount > 0 && (
                                                                                <Typography variant="body2" color={remainingAmount > 0 ? "error" : "success"} sx={{ fontWeight: "bold" }}>
                                                                                    {remainingAmount > 0
                                                                                        ? `Not Covered: ${Number(remainingAmount || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${item.currency || "USD"}`
                                                                                        : "Fully Covered"
                                                                                    }
                                                                                </Typography>
                                                                                )
                                                                            }

                                                                            {/* Reimbursed Amount */}
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                Reimbursed:&nbsp;&nbsp;
                                                                                {Number(expense.paid || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency || "USD"}
                                                                            </Typography>

                                                                            <Typography variant="body2" color="textSecondary">
                                                                                Authorized By: {expense.business_expense_amount_authorized_by || "N/A"}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                Reference ID: {expense.business_expense_reference_id || "N/A"}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                Authorization Date: {formatDate(expense.business_expense_amount_authorized_timestamp)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                )}
                                                            />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </div>
                                    );
                                })}

                                {/* Email & Submit Section */}
                                <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} gap={1}>
                                    <TextField
                                        label="Recipient Email"
                                        variant="outlined"
                                        type="email"
                                        size="small"
                                        sx={{ minWidth: "250px" }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => alert(`Expense report sent for ${attendee.fname} ${attendee.lname}`)}
                                    >
                                        Submit
                                    </Button>
                                </Box>

                                <Divider />
                            </div>
                        );
                    })
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BusinessExpenseReport