import TimeTable from "./TimeTable";
import PAAttendee from "./Attendee";


class PAEvent {
    constructor(eventData = {}) {
        // Default values for all fields
        const _temp = PAAttendee.generateRandomString(10);

        const defaults = {
            id: _temp,
            temp_id: _temp,
            title: "",
            description: "",
            eventIntro: "",

            coverImage: {},
            image: "",
            cover_image_thumbnail: "",
            cover_image_full_data: "",
            host: [],
            hostNotes: "",

            time_table: new TimeTable(),

            locations: [],
            attendees: [],
            activities: [],
            lodging: [],
            meals: [],
            ideas: [],
            flights: [],
            car_rentals: [],
            cruises: [],
            tours: [],
            supplies: [],
            to_do: [],
            documents: [],
            receipts: [],
            messages: [],
            latest_message_timestamp: 0,
            photos: [],
            menu_items: [],
            weather: [],

            suggestions: {
                outdoor: [],
                indoor: [],
                walking: [],
                unique: [],
                hidden: []
            },

            budget_total: 0,
            budget_currency: "USD",
            budget_actual: 0,

            business_info_and_data: {},
            business_authorizations: {},

            version: 2.0,
            need_to_save: false,

            is_template: false,
            template_id: null,
            template_timestamp: null,
            template_to_event: [],

            last_mod: 0,
            status: 0
        };

        // Merge the provided eventData, keeping default values for any missing fields
        Object.assign(this, defaults, eventData);
    }

}


export default PAEvent;
