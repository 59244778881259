import React, {useContext, useEffect, useState} from 'react';
import {Chip} from "@mui/material";
import {PAContext} from "../../Services/PAContext";
import './planningPageStyle.css';
import Badge from "@mui/material/Badge";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import TourRadarTour from "../../MasterEventObjects/TourRadarTour";
import {selectedSuggestionStyle, unselectedSuggestionStyle} from "../../components/ButtonStyle/Suggestions";
import usePA_GlobalStore from "../../Services/ZustandGlobalStore";


const chipLabels = [
    'Edventure Travel',
    'Slow Travel',
    'Tours',
    'Lodging',
    'Meals',
    'Walking',
    'Unique',
    'Hidden'
];

const EARTH_RADIUS_MILES = 3958.8; // Earth's radius in miles
const MIN_MOVE_DISTANCE_MILES = 20; // 20-mile threshold
// Function to calculate Haversine distance
function haversineDistance(lat1, lon1, lat2, lon2) {
    const toRadians = (angle) => (Math.PI / 180) * angle;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const rLat1 = toRadians(lat1);
    const rLat2 = toRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rLat1) * Math.cos(rLat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return EARTH_RADIUS_MILES * c;
}


function Suggestions(props) {

    const navigate = useNavigate();

    const { dataArray, addSuggestionsLodging, clearSuggestionsLodging,
        addSuggestionsTours, clearSuggestionsTours,
        addSuggestionsUnique, clearSuggestionsUnique} = useContext(PAContext);
    const { SuggestionsPipeline } = dataArray;

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [selectedChip, setSelectedChip] = useState(null);

    const [lastMapLocation, setLastMapLocation] = useState(null);
    const [fetchedSuggestions, setFetchedSuggestions] = useState({});
    const [edventureTravelItems, setEdventureTravelItems] = useState([]);
    const [slowTravelItems, setSlowTravelItems] = useState([]);
    const [tourItems, setTourItems] = useState([]);
    const [lodgingItems, setLodgingItems] = useState([]);
    const [mealItems, setMealItems] = useState([]);
    const [walkingItems, setWalkingItems] = useState([]);
    const [uniqueItems, setUniqueItems] = useState([]);
    const [hiddenItems, setHiddenItems] = useState([]);
    const [sustainableItems, setSustainableItems] = useState([]);
    const [insuranceItems, setInsuranceItems] = useState([]);


    const addItemToLodging = (item) => {
        setLodgingItems((prevItems) => [...prevItems, item]);
    };
    const addItemToTours = (_item) => {

        const _tour = new TourRadarTour();
        _tour.id = _item.tour_id;
        _tour.is_active = _item.is_active;
        _tour.tour_name = _item.tour_name;
        _tour.description = _item.description;
        _tour.images = Object.assign(_item.images);

        _tour.language = _item.language;
        _tour.locale = _item.locale;

        _tour.operator = Object.assign(_item.operator);
        _tour.age_range = Object.assign(_item.age_range);

        _tour.max_group_size = _item.max_group_size;
        _tour.tour_length_days = _item.tour_length_days;

        _tour.ratings = Object.assign(_item.ratings);

        _tour.destinations = Object.assign(_item.destinations);
        _tour.start_city = Object.assign(_item.start_city);
        _tour.end_city = Object.assign(_item.end_city);

        _tour.prices = Object.assign(_item.prices);

        _tour.budget = _item.budget;

        _tour.tour_types = Object.assign(_item.tour_types);
        _tour.links = Object.assign(_item.links);

        setTourItems((prevItems) => [...prevItems, _tour]);
    };
    const addItemToUnique = (item) => {
        setUniqueItems((prevItems) => [...prevItems, item]);
    };

    useEffect(()=> {
        console.log("Something new in the SuggestionsPipeline : "+SuggestionsPipeline.length);

        try {
            for (let i = 0; i < SuggestionsPipeline.length; i++) {
                const suggestionAction = SuggestionsPipeline[i];
                console.log("suggestionAction : ",suggestionAction);

                //remove the one item we are processing
                SuggestionsPipeline.splice(i, 1);

                if (suggestionAction.action === "planningConciergeSuggestionServiceLodgingResults"){

                    if (suggestionAction.result === 'ok'){
                        //clear any existing lodging items
                        setLodgingItems([]);
                        clearSuggestionsLodging();

                        for (const _lodging of suggestionAction.lodgings){
                            //add the new lodgings
                            addItemToLodging(_lodging);
                            addSuggestionsLodging(_lodging);
                        }
                    } else {
                        //failed
                        console.log(suggestionAction.reason);
                    }

                } else if (suggestionAction.action === "planningConciergeSuggestionServiceToursResults"){

                    if (suggestionAction.result === 'ok'){
                        //clear any existing unique items
                        setTourItems([]);
                        clearSuggestionsTours();

                        for (const _tour of suggestionAction.tours){
                            //add the new Tour
                            addItemToTours(_tour);
                            addSuggestionsTours(_tour);
                        }
                    } else {
                        //failed
                        console.log(suggestionAction.reason);
                    }

                } else if (suggestionAction.action === "planningConciergeSuggestionServiceUniqueResults"){

                    if (suggestionAction.result === 'ok'){
                        //clear any existing unique items
                        setUniqueItems([]);
                        clearSuggestionsUnique();

                        for (const _unique of suggestionAction.unique){
                            //add the new unique
                            addItemToUnique(_unique);
                            addSuggestionsUnique(_unique);
                        }
                    } else {
                        //failed
                        console.log(suggestionAction.reason);
                    }

                }

            }

        } catch (e){
            console.log(e);
        }

    }, [SuggestionsPipeline]);

    useEffect(()=> {
        //if the location changes reset any chips that might be showing

        setSelectedChip(null);

        console.log("!! location changed to : "+props.searchingForLocation);
        console.log("props.zoomInOnLocation : ",props.zoomInOnLocation);

        if (props.searchingForLocation !== ""
            && dataArray.Websocket
            && Object.keys(props.zoomInOnLocation).length > 0){


        } else {
            //websocket not available or no location info

        }

    }, [props.searchingForLocation]);


    const handleChipClick = (chipLabel) => {
        setSelectedChip(chipLabel);
        // Perform your desired function here
        console.log(`Clicked on ${chipLabel} chip`);

        props.setSuggestionForLocation(chipLabel);

        let _navigate_to = "";
        let _sendString = {};

        //get the current location on the map
        const _map_center = props._global_map_ref.current.getCenter();

        console.log("_map_center : ", _map_center);

        let distance = lastMapLocation
            ? haversineDistance(lastMapLocation.lat(), lastMapLocation.lng(), _map_center.lat(), _map_center.lng())
            : Infinity;


        console.log(`Distance moved: ${distance.toFixed(2)} miles`);

        if (!(fetchedSuggestions[`${_map_center.lat()},${_map_center.lng()}`]?.includes(chipLabel))
            || distance >= MIN_MOVE_DISTANCE_MILES) {

            const _sendLocation = {
                'latitude': _map_center.lat(),
                'longitude': _map_center.lng()
            };

            console.log("_sendLocation : ", _sendLocation);

            // Get event time_table
            const eventTimeTable = PA_GlobalObject.Planning.time_table;

            // Determine begin and end dates from event time_table
            const eventBeginDate = eventTimeTable?.dates?.[0]?.date
                ? dayjs(eventTimeTable.dates[0].date, "YYYY-MM-DD")
                : dayjs().add(14, 'day'); // Default to two weeks later if missing

            const eventEndDate = eventTimeTable?.dates?.slice(-1)?.[0]?.date
                ? dayjs(eventTimeTable.dates.slice(-1)[0].date, "YYYY-MM-DD")
                : eventBeginDate.add(5, 'day'); // Default to 5 days after begin_date if missing


            switch (chipLabel){
                case "Edventure Travel":
                    _sendString.action = "planningConciergeSuggestionServiceActivities";
                    _sendString.type = 'outdoor';
                    _sendString.location = _sendLocation;
                    break;
                case "Slow Travel":
                    _sendString.action = "planningConciergeSuggestionServiceActivities";
                    _sendString.type = 'indoor';
                    _sendString.location = _sendLocation;
                    break;
                case "Tours":
                    setTourItems([]);
                    clearSuggestionsTours();

                    _sendString.action = "planningConciergeSuggestionServiceTours";
                    _sendString.location = _sendLocation;
                    _sendString.city = _sendLocation;
                    _sendString.state = _sendLocation;
                    _sendString.country = _sendLocation;
                    _sendString.begin_date = eventBeginDate;
                    _sendString.end_date = eventEndDate;
                    dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
                    break;
                case "Lodging":
                    setLodgingItems([]);
                    clearSuggestionsLodging();

                    // Extract attendees from the Planning object
                    const attendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees || [];

                    // Generate the guests array based on attendees' ages
                    const guestsArray = attendees
                        .filter(attendee => !attendee.planner && attendee.status !== 4) // Exclude planners & status=4 attendees
                        .map(attendee => {
                            const isChild = attendee.age && attendee.age < 18;
                            return isChild
                                ? { type: 'child', age: attendee.age } // Include age only for children
                                : { type: 'adult' }; // Exclude age for adults
                        });

                    // If no valid attendees, default to 2 adults
                    if (guestsArray.length === 0) {
                        guestsArray.push({ type: 'adult' }, { type: 'adult' });
                    }

                    _sendString.action = "planningConciergeSuggestionServiceLodging";
                    _sendString.location = _sendLocation;
                    _sendString.guests = guestsArray;
                    _sendString.rooms = 1;
                    _sendString.begin_date = eventBeginDate.format("YYYY-MM-DD"); // Format for consistency
                    _sendString.end_date = eventEndDate.format("YYYY-MM-DD");
                    dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
                    props.setViewSuggestionLodgingMarkers(true);
                    break;
                case "Meals":
                    _sendString.action = "planningConciergeSuggestionServiceMeals";
                    _sendString.location = _sendLocation;
                    _sendString.begin_date = eventBeginDate;
                    _sendString.end_date = eventEndDate;
                    break;
                case "Walking":
                    _sendString.action = "planningConciergeSuggestionServiceWalking";
                    _sendString.location = _sendLocation;
                    _sendString.begin_date = eventBeginDate;
                    _sendString.end_date = eventEndDate;
                    break;
                case "Unique":
                    setUniqueItems([]);
                    clearSuggestionsUnique();

                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode(
                        {
                            'latLng': _map_center
                        },

                        function (place, status) {
                            console.log("status : ", status);
                            console.log("place.length : ", place.length);
                            console.log("The place : ", place[0]);

                            if (status === 'OK' && place.length > 0) {
                                _sendLocation.google_place_id = place[0].place_id;

                                _sendString.action = "planningConciergeSuggestionServiceUnique";
                                _sendString.location = _sendLocation;
                                _sendString.begin_date = eventBeginDate.format("YYYY-MM-DD"); // Format for consistency
                                _sendString.end_date = eventEndDate.format("YYYY-MM-DD");

                                console.log("_sendString : ", _sendString);

                                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
                            }
                        });

                    break;
                case "Hidden":
                    _sendString.action = "planningConciergeSuggestionServiceHidden";
                    _sendString.location = _sendLocation;
                    _sendString.begin_date = eventBeginDate;
                    _sendString.end_date = eventEndDate;
                    break;
                default:

            }

            if (!props.showEventItemConfig){
                //if it's not showing then show it
                props.setShowEventItemConfig(true);
            }

            // Update fetched suggestions state
            setFetchedSuggestions((prev) => ({
                ...prev,
                [`${_map_center.lat()},${_map_center.lng()}`]: [...(prev[`${_map_center.lat()},${_map_center.lng()}`] || []), chipLabel]
            }));

            setLastMapLocation(_map_center);

        }

        switch (chipLabel){
            case "Edventure Travel":
                break;
            case "Slow Travel":
                break;
            case "Tours":
                _navigate_to = '/Planner/Suggestions/Tours';
                break;
            case "Lodging":
                _navigate_to = '/Planner/Lodging/Suggestions';
                break;
            case "Meals":
                break;
            case "Walking":
                break;
            case "Unique":
                _navigate_to = '/Planner/Suggestions/Unique';
                break;
            case "Hidden":
                break;
            default:

        }

        navigate(_navigate_to);

    };

    useEffect(() => {

        if (props.clearEvent) {
            setEdventureTravelItems([]);
            setSlowTravelItems([]);
            setTourItems([]);
            setLodgingItems([]);
            setMealItems([]);
            setWalkingItems([]);
            setUniqueItems([]);
            setHiddenItems([]);
            setSustainableItems([]);
            setInsuranceItems([]);

            clearSuggestionsLodging();
            clearSuggestionsUnique();

        }

    }, [props.clearEvent]);


    return (

        <>
            {chipLabels.map((label, index) => {

                let _item_count = 0;
                switch (label){
                    case "Edventure Travel":
                        _item_count = edventureTravelItems.length;
                        break;
                    case "Slow Travel":
                        _item_count = slowTravelItems.length;
                        break;
                    case "Tours":
                        _item_count = tourItems.length;
                        break;
                    case "Lodging":
                        _item_count = lodgingItems.length;
                        break;
                    case "Meals":
                        _item_count = mealItems.length;
                        break;
                    case "Walking":
                        _item_count = walkingItems.length;
                        break;
                    case "Unique":
                        _item_count = uniqueItems.length;
                        break;
                    case "Hidden":
                        _item_count = hiddenItems.length;
                        break;
                    default:

                }

                return(
                    <Badge
                        key={index}
                        badgeContent={_item_count}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                                top: '10px', // Adjust top and right values to move the dot
                                right: '10px', // Adjust top and right values to move the dot
                            },
                        }}
                    >

                        <Chip
                            sx={selectedChip === label ? selectedSuggestionStyle : unselectedSuggestionStyle}
                            label={label}
                            clickable
                            onClick={() => handleChipClick(label)}
                            style={{margin: '0.5rem'}}
                        />
                    </Badge>
                );

            })}
        </>

    )
}

export default Suggestions;
