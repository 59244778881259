import React from 'react';
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";

function InfoActivities({ isOpen, onClose }){


    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle
                sx={{
                    color: '#151269',
                    textAlign:'center',
                    backgroundColor:'rgba(198, 226, 255, 0.8)',
                    paddingRight: '30px'
                }}
            >
                Attendees, Guests, Friends, Family
            </DialogTitle>
            <DialogTitle
                sx={{
                    fontSize: '18px',
                    textAlign:'center',
                    color: '#151269',
                    backgroundColor:'rgba(198, 226, 255, 0.8)',
                    padding:0
                }}
            >
                We're here to help you make sense of it all!
            </DialogTitle>
            <DialogContent>

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#151269',
                    })}
                >
                    <i className="icon-cross" style={{fontSize: '18px', padding: '5px'}}/>
                </IconButton>

            </DialogContent>
        </Dialog>
    )
}

export default InfoActivities;