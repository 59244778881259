import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../Services/PAContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button, Step, StepLabel, Stepper, TextField} from "@mui/material";

import {GoogleLogin} from "@react-oauth/google";

import './signup.css';

const delay = 30;
let _concierge_signup_message_index = 0;
let _concierge_signup_message_printing_timeout = null;
const steps = ['Email Address', 'Confirm Email', 'Create Password'];

function SignupContent(){

    const { dataArray } = useContext(PAContext);
    const { ws_connected, SignupPipeline } = dataArray;

    const [blinking, setBlinking] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [email, setEmail] = useState('');
    const [emailUpdated, setEmailUpdated] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeMatches, setEmailVerificationMatches] = useState(false);
    const [verificationCodeError, setEmailVerificationError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false);


    useEffect(() => {
        if (ws_connected) {
            const _send_string = {};
            _send_string.action = "signupConciergeGetWelcomeMessage";
            dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
        }
    }, [ws_connected]);

    function clearMessageArea(element) {
        element.innerHTML = ''; // Clears the content of the element
    }

    function printConciergeMessageNextCharacter(_message) {
        console.log("printConciergeMessageNextCharacter : "+JSON.stringify(_message, null, '\t'));

        try {

            const parentTextHolder  = document.getElementById("signup_concierge_output_area_content_text");

            setBlinking(true);

            // Clear any ongoing printing timeout
            clearTimeout(_concierge_signup_message_printing_timeout);

            // Clear the message area before printing a new message
            clearMessageArea(parentTextHolder);

            function continuePrinting() {
                if (_concierge_signup_message_index < _message.length) {
                    const character = _message.charAt(_concierge_signup_message_index);
                    parentTextHolder.textContent += character;
                    _concierge_signup_message_index++;
                    _concierge_signup_message_printing_timeout = setTimeout(() => {
                        continuePrinting();
                    }, delay);
                } else {
                    setBlinking(false);
                    _concierge_signup_message_index = 0;
                }
            }

            continuePrinting();

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        //handle the incoming action
        SignupPipeline.forEach(_incoming => {
            console.log('_incoming:', JSON.stringify(_incoming, null, '\t'));

            // Perform operations on the current action
            if (_incoming.result === "ok"){
                if (_incoming.action === "signupConciergeGetWelcomeMessageResults"){
                    //just landed on the page
                    printConciergeMessageNextCharacter(_incoming.message);
                } else if (_incoming.action === "signupConciergeVerifyEmailAddressResults"){
                    //just sent them the email verification code
                    setEmailVerificationSent(true);
                    printConciergeMessageNextCharacter(_incoming.message);
                } else if (_incoming.action === "signupConciergeVerifyVerificationCodeResults"){
                    //just checked the verification code
                    setEmailVerificationMatches(true);
                    printConciergeMessageNextCharacter(_incoming.message);
                } else if (_incoming.action === "signupConciergeCheckUsernameAndPasswordResults"){
                    //just checked the login info - they can login now
                    setAccountCreated(true);
                    printConciergeMessageNextCharacter(_incoming.message);
                }
            } else {
                //an error to show
                printConciergeMessageNextCharacter(_incoming.message);

                if (_incoming.action === "signupConciergeVerifyEmailAddressResults"){
                    //something was wrong with the email address
                    setEmailVerificationSent(false);
                    setEmailError(true);
                    setActiveStep(0);
                } else if (_incoming.action === "signupConciergeVerifyVerificationCodeResults"){
                    //the email verification code doesn't match
                    setEmailVerificationError(true);
                    setEmailVerificationMatches(false);
                    setActiveStep(1);
                } else if (_incoming.action === "signupConciergeCheckUsernameAndPasswordResults"){
                    //problems with the passwords
                    setAccountCreated(false);
                    setActiveStep(2);
                }

            }

        });

    }, [SignupPipeline])

    const handleNext = () => {
        steps[0] = email;

        if (activeStep === 0){
            if (validateEmail()){
                setEmailError(false);
                setEmailUpdated(email);

                const _send_string = {};
                _send_string.action = "signupConciergeVerifyEmailAddress";
                _send_string.email_address = email;

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");

                setActiveStep((activeStep) => activeStep + 1);

            } else {
                //need to fix something about the email address
                setEmailError(true);

            }

        } else if (activeStep === 1){
            //check verification code
            if (verificationCode.length === 6){
                setEmailVerificationError(false);
                const _send_string = {};
                _send_string.action = "signupConciergeVerifyVerificationCode";
                _send_string.email_address = email;
                _send_string.verification_code = verificationCode;

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
            } else {
                //to short
                setEmailVerificationError(true);
            }

        } else if (activeStep === 2){
            //check passwords match

            if (password === passwordConfirm){

                const _send_string = {};
                _send_string.action = "signupConciergeCheckUsernameAndPassword";
                _send_string.email_address = email;
                _send_string.username = "";
                _send_string.password = btoa(password);
                _send_string.password_confirm = btoa(passwordConfirm);

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
            } else {
                setPasswordError(true);
            }

        }


    }
    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
        activeStep - 1 > 0 ? steps[0] = email : steps[0] = 'Email Address';
    }

    function handleEmailChange(event){
        setEmail(event.target.value);
    }
    function handleVerificationCodeChange(event){
        setVerificationCode(event.target.value);
    }
    function handlePasswordChange(event){
        setPassword(event.target.value);
    }
    function handlePasswordConfirmChange(event){
        setPasswordConfirm(event.target.value);
    }

    function validateEmail() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const isStepFailed = (step) => {
        if (step === 0){
            return emailError;
        } else if (step === 1){
            return verificationCodeError;
        } else if (step === 2){
            return passwordError;
        }
    };

    return (

        <div id="content_area" className="content-area-signup">
            <div className="content-area-signup-content-holder">

                {/* add the header */}

                <Typography className="content-area-signup-content-title">Sign Up</Typography>

                {/* add the Sign Up With Google area */}
                <GoogleLogin
                    text="signup_with"
                    shape="pill"
                    onSuccess={credentialResponse => {
                        console.log(credentialResponse.credential);

                        //send this to the server for processing
                        const _sendString = {};
                        _sendString.action = "E_processUserLoginUsingGoogleJWT";
                        _sendString.jwt = credentialResponse;

                        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                    }}
                    onError={() => {
                        console.log('Login Failed or exited');
                    }}
                />

                {/* add the AI guidance area */}

                <div
                    id="signup_concierge_output_area_holder"
                    className="signup-concierge-output-area-holder">
                    {/* show whatever is in the Concierge Stream */}

                    <div
                        id="signup_concierge_output_area_content_text"
                        className="signup-concierge-output-area-content-text" />
                    <div
                        id="signup_concierge_output_area_content_cursor"
                        className="signup-concierge-output-area-content-cursor"
                        style={{display: blinking ? 'inline-block' : 'none'}}
                    />
                </div>

                <Box sx={{ width: '100%' }}>
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                    >

                        {steps.map((label, index) => {

                            const labelProps = {};
                            if (isStepFailed(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">
                                        {index === 0 ? 'Invalid Email' : index === 1 ? 'Invalid Code' : index === 2 ? "Passwords Don't match" : ''}
                                    </Typography>
                                );
                                labelProps.error = true;
                            }

                            return (
                                <Step key={label}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            )
                        })
                        }

                    </Stepper>

                    {/* this is the STEP content area */}
                    {
                        activeStep === 0 ? (
                                <div
                                    className="signup-concierge-input-area-content-holder"
                                >
                                    <TextField
                                        label="Email"
                                        required
                                        type="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        variant="standard"
                                        sx={{width: '80%'}}
                                    />
                                </div>
                        ) : (
                            activeStep === 1 ? (
                                <div
                                    className="signup-concierge-input-area-content-holder"
                                >
                                    <TextField
                                        label="Verification Code"
                                        required
                                        type="text"
                                        value={verificationCode}
                                        onChange={handleVerificationCodeChange}
                                        variant="standard"
                                        sx={{width: '80%'}}
                                    />
                                </div>
                            ) : (
                                <div
                                    className="signup-concierge-input-area-content-holder"
                                >
                                    <TextField
                                        label="Password"
                                        required
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        variant="standard"
                                        sx={{width: '80%', marginBottom: '20px'}}
                                    />
                                    <TextField
                                        label="Confirm Password"
                                        required
                                        type="password"
                                        value={passwordConfirm}
                                        onChange={handlePasswordConfirmChange}
                                        variant="standard"
                                        sx={{width: '80%', marginBottom: '20px'}}
                                    />
                                </div>
                            )
                        )
                    }


                    {/* this is the bottom row for buttons */}
                    {
                        activeStep === steps.length ? (
                            <>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you're finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleBack}>Reset</Button>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                            </>
                        )

                    }
                </Box>

            </div>
        </div>
    )

}

export default SignupContent;
