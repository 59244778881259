import { create } from 'zustand';

import PAEvent from "../MasterEventObjects/PAEvent";


const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { weekday: "long" });
};

const rebuildEventTimeTable = (event) => {
    if (!event) return event;

    console.log("🟢 Rebuilding Event Time Table...");

    // ✅ Extract dates from meals, activities, and lodging (EXCLUDING checkin/checkout)
    const allDateObjects = [
        ...(event.meals?.flatMap((meal) =>
            meal.time_table?.dates?.map(({ date, dayOfWeek }) => ({
                date,
                dayOfWeek,
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.activities?.flatMap((activity) =>
            activity.time_table?.dates?.map(({ date, dayOfWeek }) => ({
                date,
                dayOfWeek,
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.lodging?.flatMap((lodge) =>
            lodge.time_table?.dates?.map(({ date, dayOfWeek }) => ({
                date,
                dayOfWeek,
                "all-day": true,
                notes: "",
            })) || []
        ) || []), // ✅ Filters out checkin_time & checkout_time
    ];

    console.log("🔵 allDateObjects:", allDateObjects);

    // ✅ Remove duplicate dates and sort
    const uniqueSortedDates = Array.from(
        new Map(allDateObjects.map((obj) => [obj.date, obj])).values()
    ).sort((a, b) => a.date.localeCompare(b.date));

    // ✅ Extract and CREATE new objects for days from meals, activities, and lodging (WITHOUT extra fields)
    const allDayObjects = [
        ...(event.meals?.flatMap((meal) =>
            meal.time_table?.days?.map((day, index) => ({
                [`day ${index + 1}`]: day[Object.keys(day)[0]],
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.activities?.flatMap((activity) =>
            activity.time_table?.days?.map((day, index) => ({
                [`day ${index + 1}`]: day[Object.keys(day)[0]],
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.lodging?.flatMap((lodge) =>
            lodge.time_table?.days?.map((day, index) => ({
                [`day ${index + 1}`]: day[Object.keys(day)[0]],
                notes: "",
            })) || []
        ) || []),
    ];

    console.log("🟣 allDayObjects:", allDayObjects);

    // ✅ Ensure unique `day x` entries and maintain order

    const uniqueSortedDays = Array.from(
        new Map(allDayObjects.map((obj) => [Object.keys(obj)[0], obj])).values()
    ).sort((a, b) => {
        const numA = parseInt(Object.keys(a)[0]?.replace("day ", "")) || 0;
        const numB = parseInt(Object.keys(b)[0]?.replace("day ", "")) || 0;
        return numA - numB;
    });


    // ✅ Extracted allDateObjects and allDayObjects remain unchanged
    console.log("🔵 allDateObjects:", allDateObjects);
    console.log("🟣 allDayObjects:", allDayObjects);

    // ✅ Find the full range of dates in allDateObjects
    const allDates = allDateObjects.map(obj => obj.date).sort();
    const minDate = allDates[0] || null;
    const maxDate = allDates[allDates.length - 1] || null;

    // ✅ Find the full range of days in allDayObjects
    const allDayKeys = allDayObjects.map(obj => parseInt(Object.keys(obj)[0].replace("day ", ""))).sort((a, b) => a - b);
    const minDay = allDayKeys[0] || null;
    const maxDay = allDayKeys[allDayKeys.length - 1] || null;

    console.log("🟢 Min/Max Dates:", minDate, maxDate);
    console.log("🟢 Min/Max Days:", minDay, maxDay);

    // ✅ Get the existing range in event.time_table
    const existingDays = event.time_table.days.map(dayObj => parseInt(Object.keys(dayObj)[0].replace("day ", ""))).sort((a, b) => a - b);
    const existingMinDay = existingDays[0] || null;
    const existingMaxDay = existingDays[existingDays.length - 1] || null;

    const existingDates = event.time_table.dates.map(obj => obj.date).sort();
    const existingMinDate = existingDates[0] || null;
    const existingMaxDate = existingDates[existingDates.length - 1] || null;

    console.log("🔷 Existing Min/Max Days:", existingMinDay, existingMaxDay);
    console.log("🔷 Existing Min/Max Dates:", existingMinDate, existingMaxDate);

    // ✅ Check if we need to expand event.time_table.days
    let updatedDays = [...event.time_table.days];
    if (minDay !== null && maxDay !== null) {
        if (existingMinDay === null || existingMaxDay === null || minDay < existingMinDay || maxDay > existingMaxDay) {
            updatedDays = [];
            for (let i = minDay; i <= maxDay; i++) {
                const matchingDay = allDayObjects.find(obj => parseInt(Object.keys(obj)[0].replace("day ", "")) === i);
                updatedDays.push(matchingDay || { [`day ${i}`]: "Unknown", "all-day": true, notes: "" });
            }
        }
    }

    // ✅ Check if we need to expand event.time_table.dates
    let updatedDates = [...event.time_table.dates];
    if (minDate !== null && maxDate !== null) {
        if (existingMinDate === null || existingMaxDate === null || minDate < existingMinDate || maxDate > existingMaxDate) {
            updatedDates = [];
            for (const dateObj of allDateObjects) {
                if (!updatedDates.some(d => d.date === dateObj.date)) {
                    updatedDates.push(dateObj);
                }
            }
        }
    }

    console.log("🔵 Updated Dates:", updatedDates);
    console.log("🟣 Updated Days:", updatedDays);

    // ✅ Apply changes if necessary
    if (JSON.stringify(event.time_table.dates) !== JSON.stringify(updatedDates) ||
        JSON.stringify(event.time_table.days) !== JSON.stringify(updatedDays)) {
        console.log("🟢 Updating Event Time Table...");
        event.time_table.dates = updatedDates;
        event.time_table.days = updatedDays;
    } else {
        console.log("⚪ Event time table already covers the full range.");
    }

    return event;
};

const rebuildEventTimeTable1 = (event) => {
    if (!event) return event;

    console.log("🟢 Rebuilding Event Time Table...");

    // ✅ Extract dates from meals, activities, and lodging (EXCLUDING checkin/checkout)
    const allDateObjects = [
        ...(event.meals?.flatMap((meal) =>
            meal.time_table?.dates?.map(({ date, dayOfWeek }) => ({
                date,
                dayOfWeek,
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.activities?.flatMap((activity) =>
            activity.time_table?.dates?.map(({ date, dayOfWeek }) => ({
                date,
                dayOfWeek,
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.lodging?.flatMap((lodge) =>
            lodge.time_table?.dates?.map(({ date, dayOfWeek }) => ({
                date,
                dayOfWeek,
                "all-day": true,
                notes: "",
            })) || []
        ) || []), // ✅ Filters out checkin_time & checkout_time
    ];

    console.log("🔵 allDateObjects:", allDateObjects);

    // ✅ Remove duplicate dates and sort
    const uniqueSortedDates = Array.from(
        new Map(allDateObjects.map((obj) => [obj.date, obj])).values()
    ).sort((a, b) => a.date.localeCompare(b.date));

    // ✅ Extract and CREATE new objects for days from meals, activities, and lodging (WITHOUT extra fields)
    const allDayObjects = [
        ...(event.meals?.flatMap((meal) =>
            meal.time_table?.days?.map((day, index) => ({
                [`day ${index + 1}`]: day[Object.keys(day)[0]],
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.activities?.flatMap((activity) =>
            activity.time_table?.days?.map((day, index) => ({
                [`day ${index + 1}`]: day[Object.keys(day)[0]],
                "all-day": true,
                notes: "",
            })) || []
        ) || []),
        ...(event.lodging?.flatMap((lodge) =>
            lodge.time_table?.days?.map((day, index) => ({
                [`day ${index + 1}`]: day[Object.keys(day)[0]],
                notes: "",
            })) || []
        ) || []),
    ];

    console.log("🟣 allDayObjects:", allDayObjects);

    // ✅ Ensure unique `day x` entries and maintain order

    const uniqueSortedDays = Array.from(
        new Map(allDayObjects.map((obj) => [Object.keys(obj)[0], obj])).values()
    ).sort((a, b) => {
        const numA = parseInt(Object.keys(a)[0]?.replace("day ", "")) || 0;
        const numB = parseInt(Object.keys(b)[0]?.replace("day ", "")) || 0;
        return numA - numB;
    });

    console.log("🔵 Computed New Dates:", uniqueSortedDates);
    console.log("🟣 Computed New Days:", uniqueSortedDays);
    console.log("🔶 Existing Dates in Event:", event.time_table.dates);
    console.log("🔷 Existing Days in Event:", event.time_table.days);

    // ✅ Check if updates are necessary
    const datesChanged = JSON.stringify(event.time_table.dates) !== JSON.stringify(uniqueSortedDates);
    const daysChanged = JSON.stringify(event.time_table.days) !== JSON.stringify(uniqueSortedDays);

    if (datesChanged || daysChanged) {
        console.log("🟢 Updating Event Time Table...");
        event.time_table.dates = uniqueSortedDates;
        event.time_table.days = uniqueSortedDays;
    } else {
        console.log("⚪ Event time table already covers the full range.");
    }

    return event;
};




const usePA_GlobalStore = create((set) => ({
    PA_GlobalObject: {
        Planning: new PAEvent(),
    },
    set_PA_GlobalObject: (update) =>


        set((state) => {
            const newState =
                typeof update === "function" ? update(state.PA_GlobalObject) : update;

            // Automatically rebuild the event's time_table before updating the state
            if (newState.Planning) {
                newState.Planning = rebuildEventTimeTable(newState.Planning);
            }

            console.log("Updating PA_GlobalObject:", {
                update: newState,
            });

            return {
                PA_GlobalObject: { ...state.PA_GlobalObject, ...newState },
            };
        }),

}));

export default usePA_GlobalStore;
