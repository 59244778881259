import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {
    Button, Card, CardContent, CardMedia,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import tourRadar_logo from "../../../graphics/TourRadar/TourRadar_logo_teal.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

import './suggestionsTourRadar.css';
import TourRadarTour from "../../../MasterEventObjects/TourRadarTour";
import Divider from "@mui/material/Divider";



function SuggestionsTourRadar(props){

    const { dataArray } = useContext(PAContext);
    const { SuggestionsTours } = dataArray;

    const [tourSuggestions, setTourSuggestions] = useState(SuggestionsTours);

    useEffect(() => {

        setTourSuggestions([]);

        //now add the new suggestions
        for (const _item of SuggestionsTours){
            if (Object.keys(_item).length > 0){

                const _tour = new TourRadarTour();
                _tour.id = _item.tour_id;
                _tour.is_active = _item.is_active;
                _tour.tour_name = _item.tour_name;
                _tour.description = _item.description;
                _tour.images = Object.assign(_item.images);

                _tour.language = _item.language;
                _tour.locale = _item.locale;

                _tour.operator = Object.assign(_item.operator);
                _tour.age_range = Object.assign(_item.age_range);

                _tour.max_group_size = _item.max_group_size;
                _tour.tour_length_days = _item.tour_length_days;

                _tour.ratings = Object.assign(_item.ratings);

                _tour.destinations = Object.assign(_item.destinations);
                _tour.start_city = Object.assign(_item.start_city);
                _tour.end_city = Object.assign(_item.end_city);

                _tour.itinerary = Object.assign(_item.itinerary);

                _tour.prices = Object.assign(_item.prices);

                _tour.budget = _item.budget;

                _tour.tour_types = Object.assign(_item.tour_types);
                _tour.links = Object.assign(_item.links);

                setTourSuggestions((prev) => [...prev, _tour]);
            }
        }

    }, [SuggestionsTours]);

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    const [openDialogTourDetails, setOpenDialogTourDetails] = useState(false);
    const [selectedTourDetails, setSelectedTourDetails] = useState(new TourRadarTour());
    const [tourCities, setTourCities] = useState([]);
    function handleDialogTourDetailsToggle(_item) {
        console.log(JSON.stringify(_item, null, '\t'));
        if (selectedTourDetails['id'] !== _item['id']){
            //update the Tour details
            setSelectedTourDetails(_item);
        }
        setOpenDialogTourDetails(!openDialogTourDetails);
    }
    function handleDialogTourMapItToggle() {
        setOpenDialogTourDetails(!openDialogTourDetails);

        //send over the Itinerary accommodations and the list of destinations
        const _tour_map_info = {};


        props.setTourSuggestionsTourCities(tourCities);

    }
    function handleDialogTourBookIt() {
        //add this to the list of Planning Activities


        //open the TourRadar URL for booking


    }
    function handleDialogTourDetailsClose() {
        setOpenDialogTourDetails(false);
    }


    return (

        <div className="tour-radar-suggestions-list-container" >
            {/* show the header */}
            <div className="tour-radar-suggestions-list-header" >
                <div className="tour-radar-suggestions-list-header-text">
                    <h5>Tour Suggestions {tourSuggestions.length===0?'':tourSuggestions.length}</h5>
                </div>

                <div className="tour-radar-suggestions-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                </div>
            </div>


            {/* show the list of tour-radar suggestions */}
            <div className="tour-radar-suggestions-list-items-container">
                <Grid container spacing={2} direction="column">
                    {
                        tourSuggestions.length > 0 ? (
                            tourSuggestions.map((_item, index)=> (
                                <Grid
                                    item
                                    key={index}
                                    sx={{display: 'flex'}}
                                >
                                    <Card className="tour-radar-suggestions-list-item">
                                        <CardMedia
                                            sx={{ height: '50px', width: '200px' }}
                                            image={tourRadar_logo}
                                            title="airbnb logo"
                                        />
                                        <CardContent>
                                            <Typography variant="body2">
                                                {_item['tour_length_days']} days
                                            </Typography>
                                        </CardContent>
                                        <CardMedia
                                            sx={{ height: '200px' }}
                                            image={_item['images'][0]['url']}
                                            title={_item['tour_name']}
                                        />
                                        <CardContent>
                                            <Typography variant="body2">
                                                {decodeURI(_item['tour_name'])}
                                            </Typography>
                                            <Typography variant="caption" sx={{mb: 1.5, flex:1}} color="text.secondary">
                                                {_item['description']}
                                            </Typography>
                                            <Box sx={{mb: 1.5, flex:1, textAlign:'right'}} color="text.secondary">
                                                <Rating name="read-only" value={parseFloat(_item['ratings']['overall'])} precision={0.1} readOnly />
                                                <Typography color="text.secondary">
                                                    {_item['rating']}
                                                </Typography>
                                            </Box>
                                            {/* add the buttons for viewing details, booking, and showing route on map */}
                                            <DialogActions>
                                                <Button onClick={()=>handleDialogTourDetailsToggle(_item)}>Details</Button>
                                                <Button onClick={handleDialogTourMapItToggle}>Map It</Button>
                                                <Button onClick={handleDialogTourBookIt}>Book It</Button>
                                            </DialogActions>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <div style={{padding: '20px', textAlign:'center'}} >
                                {/* place is required and preferably some real dates */}
                                <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                                <Typography variant="subtitle2">No Tour suggestions yet.</Typography>
                                <Typography variant="caption">Once an area has been selected then the Concierge will look for Tour suggestions.</Typography>
                            </div>
                        )
                    }
                </Grid>
            </div>


            {/*  the Dialog for Tour details  */}
            <Dialog
                open={openDialogTourDetails}
                onClose={handleDialogTourDetailsClose}
            >
                <DialogTitle id="tour_dialog_title">
                    {decodeURI(selectedTourDetails.tour_name)}
                </DialogTitle>

                <DialogContent>
                    {/*  the Tour begin and end location  */}
                    {
                        selectedTourDetails['start_city']['location_id'] === undefined ?  (
                            <></>
                        ):(
                            selectedTourDetails['start_city']['location_id'] === selectedTourDetails['end_city']['location_id'] ? (
                                <Typography variant="body2" align="right" sx={{ marginBottom: '8px' }}>
                                    Begins and ends in {selectedTourDetails['start_city']['city_name']}
                                </Typography>
                            ):(
                                <Typography variant="body2" align="right" sx={{ marginBottom: '8px' }}>
                                    Begins in {selectedTourDetails['start_city']['city_name']} and ends in {selectedTourDetails['end_city']['city_name']}
                                </Typography>
                            )
                        )

                    }

                    {/*  the Tour description  */}
                    <Typography variant="caption" sx={{ marginBottom: '8px' }}>
                        {selectedTourDetails.description}
                    </Typography>



                    {/*  the Tour photos  */}
                    {
                        selectedTourDetails['images'] === undefined ?  (
                            <></>
                        ):(
                            <div style={{display:'flex', overflowX:'auto', margin: '20px 0'}}>
                                {
                                    selectedTourDetails.images.map((_image, _index) => (
                                        <img
                                            style={{maxWidth:'100', flex:'0 0 auto'}}
                                            key={_index}
                                            src={_image.thumbnail_url}
                                            title="image"
                                            alt=''
                                        />
                                    ))
                                }
                            </div>
                        )
                    }

                    {/*  the Tour Itinerary  */}
                    {
                        selectedTourDetails['itinerary'] === undefined ?  (
                            <></>
                        ):(
                            selectedTourDetails['itinerary']
                                .sort((a, b) => a.order - b.order)
                                .map((_item) => (
                                    <div key={_item.order}>
                                        <Typography variant="body2">
                                            {_item.title}
                                        </Typography>
                                        <Typography variant="caption" dangerouslySetInnerHTML={{ __html: _item.description }} />
                                        <Divider variant="middle" sx={{margin:'20px 16px'}}/>
                                    </div>
                                ))
                        )

                    }


                    {/*  the Tour destinations  */}
                    {
                        selectedTourDetails['destinations']['cities'] === undefined ?  (
                            <></>
                        ):(
                            selectedTourDetails['destinations']['cities'].map((_destination) => (
                                <DialogContentText key={_destination.location_id}>
                                    {_destination.city_name}
                                </DialogContentText>
                            ))
                        )

                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogTourDetailsClose}>
                        More Details
                    </Button>
                    <Button onClick={handleDialogTourDetailsClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>





        </div>

    )
}

export default SuggestionsTourRadar;
