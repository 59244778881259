import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Divider, List, ListItem, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";

function InfoMeals({ isOpen, onClose }) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            {/* Dialog Title */}
            <DialogTitle
                sx={{
                    color: '#151269',
                    textAlign: 'center',
                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                    paddingRight: '30px'
                }}
            >
                Meal Planning & Customization Guide
            </DialogTitle>

            <DialogTitle
                sx={{
                    fontSize: '18px',
                    textAlign: 'center',
                    color: '#151269',
                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                    padding: 0
                }}
            >
                Manage your meal details, attendees, costs, and locations with ease.
            </DialogTitle>

            {/* Close Button */}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#151269',
                }}
            >
                <i className="icon-cross" style={{ fontSize: '18px', padding: '5px' }} />
            </IconButton>

            {/* Dialog Content */}
            <DialogContent>
                <List>
                    {/* Selecting Dates and Days */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-calendar3" title="Date and Time" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Selecting Dates and Days
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    Choose the date or day for your meal. If your event is scheduled by days (e.g., "Day 1", "Day 2"),
                                    select the corresponding day. If your event has specific dates, pick a date from the calendar.
                                    The system will automatically sync your meal schedule accordingly.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Updating Meal Location */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-map-marker" title="Meal Location" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Selecting/Updating Meal Location
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    Choose from a list of previously used locations, your event’s lodging, or manually enter a custom location.
                                    You can also filter locations based on proximity to your event accommodations.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Adding/Removing Meal Attendees */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-user-24" title="Attendees" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Adding/Removing Meal Attendees
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    Add event attendees to your meal. The system will highlight event participants who are not yet included in the meal
                                    to make selection easier. You can also invite additional guests.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Assigning Costs to Attendees */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-wallet"  title="Costs" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Managing Meal Costs & Assigning Expenses
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    - Enter the total meal cost and choose how it should be split among attendees.<br/>
                                    - You can distribute the cost evenly or manually assign amounts.<br/>
                                    - If a meal is a business expense, mark the attendees whose expenses should be reimbursed.
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />

                    {/* Updating Meal Details */}
                    <ListItem>
                        <ListItemText
                            primary={
                                <span>
                                    <i className="icon-settings-icons" title="Details" style={{ fontSize: "18px", marginRight: "8px", color: "#1976d2" }} />
                                    Updating Meal Details
                                </span>
                            }
                            secondary={
                                <Typography variant="body2">
                                    Add special instructions such as dietary restrictions, menu preferences, or additional notes.
                                    You can also update meal reservations, confirmation numbers, and booking details.
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    );
}

export default InfoMeals;
