import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import {
    Avatar, Button, ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardMedia, Checkbox,
    Chip,
    ListItem, ListItemAvatar,
    TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import './lodging.css';
import Badge from "@mui/material/Badge";
import {createTheme} from "@mui/material/styles";
import dayjs from "dayjs";

import _ from 'lodash';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {DateCalendar, DatePicker, PickersDay} from "@mui/x-date-pickers";
import Divider from "@mui/material/Divider";
import Location from "../../../MasterEventObjects/Location";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import Lodging from "../../../MasterEventObjects/Lodging";
import {addChipStyle} from "../../ButtonStyle/Add";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {createTimeTableForLodging} from "../../Helpers/EventTimeTableConversion";


function LodgingAddFromSuggestions(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [lodgingTabSelected, setLodgingTabSelected] = useState("");
    const [lodgingDetails, setLodgingDetails] = useState(props.lodgingFromSuggestions);
    const [lodgingBeginDate, setLodgingBeginDate] = useState(props.eventBeginDate);
    const [lodgingEndDate, setLodgingEndDate] = useState(props.eventBeginDate);
    const [numberOfNights, setNumberOfNights] = useState(1);
    const [lodgingOccupants, setLodgingOccupants] = useState([]);
    const [lodgingOccupantsCosts, setLodgingOccupantsCosts] = useState([]);
    const [lodgingOccupantsCostsUnassigned, setLodgingOccupantsCostsUnassigned] = useState(0);
    const [lodgingCost, setLodgingCost] = useState(0);
    const [lodgingCostCurrency, setLodgingCostCurrency] = useState("");

    const [showCalendarSelectBeginDate, setShowCalendarSelectBeginDate] = useState(false);
    const [showCalendarSelectEndDate, setShowCalendarSelectEndDate] = useState(false);

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [assignEqual, setAssignEqual] = useState(true);

    const [bookedThrough, setBookedThrough] = useState("");
    const [confirmationNumber, setConfirmationNumber] = useState("");
    const [reservationName, setReservationName] = useState("");
    const [reservationNumber, setReservationNumber] = useState("");
    const [gateCode, setGateCode] = useState("");
    const [doorCode, setDoorCode] = useState("");
    const [wifiName, setWifiName] = useState("");
    const [wifiPassword, setWifiPassword] = useState("");
    const [notes, setNotes] = useState("");

    console.log("lodgingBeginDate : "+lodgingBeginDate);
    console.log("lodgingEndDate : "+lodgingEndDate);

    useEffect(() => {
        console.log("there's something in the lodgingFromSuggestions : ", props.lodgingFromSuggestions);

        if (Object.keys(props.lodgingFromSuggestions).length > 0){

            const _new_lodging = _.cloneDeep(props.lodgingFromSuggestions);

            setLodgingDetails(_new_lodging);

            //set the number of nights
            console.log("lodgingBeginDate : "+lodgingBeginDate);
            console.log("lodgingEndDate : "+lodgingEndDate);

            if (lodgingBeginDate !== ""){
                const startDate = dayjs.unix(lodgingBeginDate / 1000);
                const endDate = dayjs.unix(lodgingEndDate / 1000);
                setNumberOfNights(endDate.diff(startDate, 'day')+1);
            }

            //set the occupants
            setLodgingOccupants(PA_GlobalObject.Planning.attendees.map(_att => _att));


            //set the costs
            try {
                const check_in_date = dayjs(_new_lodging['check_in_date'],'YYYY-MM-DD');
                const check_out_date = dayjs(_new_lodging['check_out_date'],'YYYY-MM-DD');
                console.log("check_in_date : "+check_in_date.format());
                console.log("check_out_date : "+check_out_date.format());

                const _number_of_nights = check_out_date.diff(check_in_date, 'day');
                console.log("_number_of_nights : "+_number_of_nights);

                const _item_price = parseFloat(_new_lodging.cheapest_rate_total_amount);
                console.log("_item_price : "+_item_price);
                const _item_currency = _new_lodging.cheapest_rate_currency;
                setLodgingCostCurrency(_item_currency);
                const _item_per_night_rate = _item_price / _number_of_nights;
                console.log("_item_per_night_rate : "+_item_per_night_rate);

                const _total_lodging_cost = _number_of_nights === 1 ? _item_per_night_rate.toFixed(2) : (_item_per_night_rate * (endDate.diff(startDate, 'day')+1)).toFixed(2);
                setLodgingCost(_total_lodging_cost);

                console.log("_total_lodging_cost : "+_total_lodging_cost);

                //set the Occupants fees
                if (PA_GlobalObject.Planning.attendees.length > 0) {
                    const numberOfAttendees = PA_GlobalObject.Planning.attendees.length;
                    const baseCostPerAttendee = parseFloat(Math.floor((_total_lodging_cost / numberOfAttendees) * 100) / 100).toFixed(2);
                    const remainder = Math.round((_total_lodging_cost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                    const updatedAttFees = PA_GlobalObject.Planning.attendees.map((_att, index) => ({
                        id: _att.id,
                        costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                    }));
                    console.log("updatedAttFees : " + JSON.stringify(updatedAttFees, null, '\t'));
                    setLodgingOccupantsCosts(updatedAttFees);
                }

            } catch (e){
                console.log("error : "+e);
                setNumberOfNights(1);
                setLodgingCost(0);
            }
        }

    }, [props.lodgingFromSuggestions]);

    useEffect(() => {

        if (props.eventBeginDate !== null && props.eventEndDate !== null){

            setLodgingBeginDate(props.eventBeginDate);
            setLodgingEndDate(props.eventEndDate);

            let _event_day_count = 0;
            if (props.eventBeginDate !== ""){
                const startDate = dayjs.unix(props.eventBeginDate / 1000);
                const endDate = dayjs.unix(props.eventEndDate / 1000);
                _event_day_count = endDate.diff(startDate, 'day')+1;
                setNumberOfNights(_event_day_count);
            }

            console.log("lodgingDetails : "+JSON.stringify(lodgingDetails, null, '\t'));
            if (Object.keys(lodgingDetails).length > 0) {

                //update the numbers
                const check_in_date = dayjs(lodgingDetails['check_in_date'],'YYYY-MM-DD');
                const check_out_date = dayjs(lodgingDetails['check_out_date'],'YYYY-MM-DD');
                console.log("check_in_date : "+check_in_date.format());
                console.log("check_out_date : "+check_out_date.format());

                const _number_of_nights = check_out_date.diff(check_in_date, 'day');
                console.log("_number_of_nights : "+_number_of_nights);

                const _item_price = parseFloat(lodgingDetails['accommodation'].cheapest_rate_total_amount);
                console.log("_item_price : "+_item_price);
                const _item_currency = lodgingDetails['accommodation'].cheapest_rate_currency;
                setLodgingCostCurrency(_item_currency);
                const _item_per_night_rate = _item_price / _number_of_nights;
                console.log("_item_per_night_rate : "+_item_per_night_rate);

                const _new_lodging_cost = _event_day_count === 1 ? _item_per_night_rate.toFixed(2) : (_item_per_night_rate * _event_day_count).toFixed(2);
                console.log("_new_lodging_cost : "+_new_lodging_cost);
                setLodgingCost(_new_lodging_cost);


                //now re-figure the costs - just do even split
                console.log("lodgingOccupants : " + lodgingOccupants.length);
                if (lodgingOccupants.length > 0) {
                    const numberOfAttendees = lodgingOccupants.length;
                    const baseCostPerAttendee = parseFloat(Math.floor((_new_lodging_cost / numberOfAttendees) * 100) / 100).toFixed(2);
                    console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                    console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                    const remainder = Math.round((_new_lodging_cost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                    console.log("remainder : " + remainder);

                    const updatedAttFees = lodgingOccupants.map((_att, index) => ({
                        id: _att.id,
                        costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                    }));
                    console.log(JSON.stringify(updatedAttFees, null, '\t'));
                    setLodgingOccupantsCosts(updatedAttFees);
                } else {
                    //set the occupants
                    setLodgingOccupants(PA_GlobalObject.Planning.attendees.map(_att => _att));


                    //set the costs
                    try {
                        //set the Occupants fees
                        if (PA_GlobalObject.Planning.attendees.length > 0) {
                            const numberOfAttendees = PA_GlobalObject.Planning.attendees.length;
                            const baseCostPerAttendee = parseFloat(Math.floor((_new_lodging_cost / numberOfAttendees) * 100) / 100).toFixed(2);
                            const remainder = Math.round((_new_lodging_cost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                            const updatedAttFees = PA_GlobalObject.Planning.attendees.map((_att, index) => ({
                                id: _att.id,
                                costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                            }));
                            console.log("updatedAttFees : " + JSON.stringify(updatedAttFees, null, '\t'));
                            setLodgingOccupantsCosts(updatedAttFees);
                        }

                    } catch (e){
                        console.log("error : "+e);
                        setNumberOfNights(1);
                        setLodgingCost(0);
                    }
                }

                //update the unassigned cost
                setLodgingOccupantsCostsUnassigned(0);

                setAssignEqual(true);
            }

        }

    },[props.eventBeginDate, props.eventEndDate]);


    function handlePromptAddLodgingOK() {
        console.log("handlePromptAddLodgingOK");



    }

    function handlePromptAddLodgingClose() {
        navigate('/Planner/Lodging');
    }





    //build the array of dates
    const startDate = dayjs.unix(lodgingBeginDate / 1000);
    const endDate = dayjs.unix(lodgingEndDate / 1000);

    // Calculate the range of dates
    const highlightedDates = Array.from(
        { length: endDate.diff(startDate, 'day') + 1 }, // Calculate the number of days between begin_date and end_date
        (_, index) =>
            startDate
                .add(index, 'day')
                .toISOString() // Convert the date to ISO format
                .slice(0, 10) // Extract the YYYY-MM-DD portion
    );
    console.log("the highlightedDates : " + highlightedDates);

    function ServerDay(props) {
        const { highlightedDates = [], day, outsideCurrentMonth, ...other } = props;

        const isSelected =
            !props.outsideCurrentMonth &&
            highlightedDates.includes(props.day.format('YYYY-MM-DD'));

        return (
            <Badge
                key={props.day.toString()}
                variant={isSelected ? "dot" : "standard"} // Use variant="dot" for a dot, "standard" for a regular badge
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: isSelected ? 'red' : 'transparent', // Customize the dot color here
                        width: '8px', // Adjust the dot size as needed
                        height: '8px',
                        borderRadius: '50%', // Make it a circle
                        top: '10px', // Adjust top and right values to move the dot
                        right: '10px', // Adjust top and right values to move the dot
                    },
                }}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    function handlePromptShowLodgingBeginDateCalendar(){
        //toggle the button
        setShowCalendarSelectBeginDate(!showCalendarSelectBeginDate);
    }

    function handlePromptShowLodgingEndDateCalendar(){
        //toggle the button
        setShowCalendarSelectEndDate(!showCalendarSelectEndDate);

    }

    function handleUpdatedBeginDate(e) {
        console.log("handleUpdatedBeginDate : "+e);
        setLodgingBeginDate(e);
        setLodgingEndDate(dayjs(e).add(1, 'day'));
        setShowCalendarSelectBeginDate(false);

        props.setEventBeginDate(e);

    }
    function handleUpdatedEndDate(e) {
        console.log("handleUpdatedEndDate : "+e);
        setLodgingEndDate(e);
        setShowCalendarSelectEndDate(false);

        props.setEventEndDate(e);

    }


    const handleOccupantsToggle = (value) => () => {
        console.log("value : "+value);
        const _exists = lodgingOccupants.some((_att) => _att.id === value);
        console.log(_exists);
        let newChecked = [...lodgingOccupants];
        if (!_exists) {
            newChecked.push(PA_GlobalObject.Planning.attendees.find((_att) => _att.id === value));
        } else {
            newChecked = newChecked.filter((_att) => _att.id !== value);
        }
        console.log("newChecked : "+newChecked);
        setLodgingOccupants(newChecked);

        //now for the lodgingOccupantsCosts
        const _costs_exists = lodgingOccupantsCosts.some((_att) => _att.id === value);
        console.log(_costs_exists);
        let newCostsChecked = [...lodgingOccupantsCosts];
        if (!_costs_exists) {
            // Use find to get the matching attendee and push it
            const matchingAttendee = PA_GlobalObject.Planning.attendees.find((_att) => _att.id === value);
            if (matchingAttendee) {
                newCostsChecked.push({
                    id: matchingAttendee.id,
                    costs: parseFloat(0).toFixed(2),
                });
            }
        } else {
            newCostsChecked = newCostsChecked.filter((_att) => _att.id !== value);
        }

        //now re-figure the costs - just do even split
        const numberOfAttendees = newCostsChecked.length;
        const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
        console.log("baseCostPerAttendee : " + baseCostPerAttendee);
        console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
        const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
        console.log("remainder : " + remainder);

        const updatedAttFees = newCostsChecked.map((_att, index) => ({
            id: _att.id,
            costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
        }));
        console.log(JSON.stringify(updatedAttFees, null, '\t'));

        setLodgingOccupantsCosts(updatedAttFees);

        setLodgingOccupantsCostsUnassigned(0);

        setAssignEqual(true);
    };

    function toggleShowOverride(){
        setShowPriceOverride(!showPriceOverride);
    }

    function handleCostOverride(event){
        console.log("handleCostOverride : "+event.target.value);
        setLodgingCost(event.target.value);

        //update the occupants to evenly share the cost
        console.log("lodgingOccupants : " + lodgingOccupants.length);
        if (lodgingOccupants.length > 0) {
            const numberOfAttendees = lodgingOccupants.length;
            const baseCostPerAttendee = parseFloat(Math.floor((event.target.value / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((event.target.value - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            const updatedAttFees = lodgingOccupants.map((_att, index) => ({
                id: _att.id,
                costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
            }));
            console.log(JSON.stringify(updatedAttFees, null, '\t'));
            setLodgingOccupantsCosts(updatedAttFees);
        }

        //update the unassigned cost
        setLodgingOccupantsCostsUnassigned(0);

    }

    function handleToggleAssignEqual(){

        if (assignEqual){
            //they are changing to custom

        } else {
            //they are changing to equal
            console.log("lodgingOccupants : " + lodgingOccupants.length);
            if (lodgingOccupants.length > 0) {
                const numberOfAttendees = lodgingOccupants.length;
                const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                const updatedAttFees = lodgingOccupants.map((_att, index) => ({
                    id: _att.id,
                    costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                }));
                console.log(JSON.stringify(updatedAttFees, null, '\t'));
                setLodgingOccupantsCosts(updatedAttFees);
            }

            //update the unassigned cost
            setLodgingOccupantsCostsUnassigned(0);

        }

        setAssignEqual(!assignEqual);

    }

    function handleCustomOccupantCost(_attendee_id, e){
        console.log("handleCustomOccupantCost : "+_attendee_id+" : "+e.target.value);

        const updatedLodgingOccupantsCosts = lodgingOccupantsCosts.map((attendee) => {
            if (attendee.id === _attendee_id) {
                // Update the costs for the matching attendee
                console.log("---- attendee : "+JSON.stringify(attendee));
                return { ...attendee, costs: e.target.value };
            }
            console.log("---- attendee : "+JSON.stringify(attendee));
            return attendee; // Keep other attendees unchanged
        });

        // Use the state updater function to work with the updated state
        setLodgingOccupantsCosts((prevCosts) => {

            const _new_unassigned = parseFloat(lodgingCost - parseFloat(updatedLodgingOccupantsCosts.reduce((sum, attendee) => sum - -attendee.costs, 0)).toFixed(2));
            console.log("_new_unassigned: " + _new_unassigned);
            setLodgingOccupantsCostsUnassigned(_new_unassigned);

            return updatedLodgingOccupantsCosts;
        });

    }

    function handleBookedThroughChange(event){
        setBookedThrough(event.target.value);
    }
    function handleConfirmationNumberChange(event){
        setConfirmationNumber(event.target.value);
    }
    function handleReservationNameChange(event){
        setReservationName(event.target.value);
    }
    function handleReservationNumberChange(event){
        setReservationNumber(event.target.value);
    }
    function handleGateCodeChange(event){
        setGateCode(event.target.value);
    }
    function handleDoorCodeChange(event){
        setDoorCode(event.target.value);
    }
    function handleWifiNameChange(event){
        setWifiName(event.target.value);
    }
    function handleWifiPasswordChange(event){
        setWifiPassword(event.target.value);
    }
    function handleNotesChange(event){
        setNotes(event.target.value);
    }

    function handleFooterItemClick(_item){
        lodgingTabSelected === _item ? setLodgingTabSelected("") : setLodgingTabSelected(_item)
    }

    const theme = createTheme({
        palette: {
            badge: {
                main: '#cbe0f7'
            },
        }
    });



    return (

        <div className="lodging-suggestions-list-container" >
            {/* show the header */}
            <div
                className="lodging-suggestions-list-header"
            >
                <div className="lodging-suggestions-list-header-text">
                    <h5>Add Lodging</h5>
                </div>

                <div className="lodging-suggestions-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptAddLodgingClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label="Add"
                        size="small"
                        onClick={handlePromptAddLodgingOK}
                    />
                </div>
            </div>

            <div className="lodging-suggestions-add-list-items-container">
                <Grid container spacing={2} direction="column">

                    {
                        Object.keys(lodgingDetails).length > 0 ? (
                            <Grid item sx={{display:'flex'}}>

                                <Card
                                    className="lodging-suggestions-add-list-item"
                                >
                                    {
                                        lodgingDetails['accommodation']['photos'][0] === undefined ? (
                                            <></>
                                        ) : (
                                            <CardMedia
                                                sx={{ height: 100 }}
                                                image={lodgingDetails['accommodation']['photos'][0].url}
                                                title="green iguana"
                                            />
                                        )
                                    }
                                    <CardContent>
                                        <Typography variant="body2">
                                            {lodgingDetails['accommodation'].name}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                                            {lodgingDetails['accommodation']['location']['address'].line_one}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <div className="lodging-suggestions-add-list-item-footer-container">
                                            {/* add the stay dates */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Dates" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-dates-container"
                                                onClick={()=>handleFooterItemClick('Dates')}
                                            >
                                                <Badge
                                                    badgeContent={numberOfNights}
                                                    color="badge"
                                                    showZero
                                                >
                                                    <i className="icon-calendar3" title="Dates"/>
                                                </Badge>
                                            </div>
                                            {/* add the stay occupants */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Occupants" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-occupants-container"
                                                onClick={()=>handleFooterItemClick('Occupants')}
                                            >

                                                <Badge
                                                    badgeContent={lodgingOccupants.length}
                                                    color="badge"
                                                    showZero
                                                >
                                                    <i className="icon-user-24"  title="Occupants"/>
                                                </Badge>
                                            </div>
                                            {/* add the stay costs */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Costs" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-costs-container"
                                                onClick={()=>handleFooterItemClick('Costs')}
                                            >
                                                <i className="icon-wallet"  title="Costs"/>
                                                <span className="lodging-suggestions-add-list-item-footer-costs-tab-amount">
                                                    {parseFloat(lodgingCost).toFixed(2)}
                                                </span>
                                            </div>
                                            {/* add the stay details */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Details" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-details-container"
                                                onClick={()=>handleFooterItemClick('Details')}
                                            >
                                                <Badge
                                                    badgeContent={0}
                                                    color="badge"
                                                >
                                                    <i className="icon-checklist2"  title="Details"/>
                                                </Badge>
                                            </div>
                                        </div>
                                    </CardActions>

                                </Card>
                            </Grid>
                        ) : (
                            <></>
                        )

                    }
                </Grid>
            </div>
        </div>
    )
}

export default LodgingAddFromSuggestions;
