import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";

import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";

import {PAContext} from "../../../Services/PAContext";
import Grid from '@mui/material/Grid2';

import './planning.css';
import {
    Alert,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Snackbar,
    Tooltip
} from "@mui/material";

import '../../../css/Lindua/style.css';
import Suggestions from "../../../Pages/PlanningPage/Suggestions";

import GoogleMap from "../../../Pages/PlanningPage/GoogleMap";

import ActivitiesList from "../../EventFeatures/Activities/ActivitiesList";
import ActivitiesAdd from "../../EventFeatures/Activities/ActivitiesAdd";
import ActivitiesEdit from "../../EventFeatures/Activities/ActivitiesEdit";
import LocationDetails from "../../EventFeatures/Location/LocationDetails";
import FeaturesList from "../../../Pages/PlanningPage/FeaturesList";
import WeatherDetails from "../../EventFeatures/Weather/WeatherDetails";
import WeatherUsingVisualCrossing from "../../../Services/WeatherUsingVisualCrossing";
import DatesTimesContent from "../../EventFeatures/DatesTimes/DatesTimesContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AttendeesList from "../../EventFeatures/Attendees/AttendeesList";
import AttendeesAddNew from "../../EventFeatures/Attendees/AttendeesAddNew";
import AttendeesAddPrevious from "../../EventFeatures/Attendees/AttendeesAddPrevious";
import AttendeesAddSamples from "../../EventFeatures/Attendees/AttendeesAddSamples";
import PAEvent from "../../../MasterEventObjects/PAEvent";
import AttendeeEdit from "../../EventFeatures/Attendees/AttendeeEdit";
import SuppliesList from "../../EventFeatures/Supplies/SuppliesList";
import SuppliesAddNew from "../../EventFeatures/Supplies/SuppliesAddNew";
import SuppliesAddSuggestions from "../../EventFeatures/Supplies/SuppliesAddSuggestions";
import SuppliesAddEssentials from "../../EventFeatures/Supplies/SuppliesAddEssentials";
import LodgingList from "../../EventFeatures/Lodging/LodgingList";
import LodgingSuggestions from "../../EventFeatures/Lodging/LodgingSuggestions";
import dayjs from "dayjs";
import LodgingAddFromMap from "../../EventFeatures/Lodging/LodgingAddFromMap";
import SuggestionsUnique from "../../EventFeatures/Suggestions/SuggestionsUnique";
import BudgetSummary from "../../EventFeatures/Budget/BudgetSummary";
import LodgingAddFromSuggestions from "../../EventFeatures/Lodging/LodgingAddFromSuggestions";
import SampleLibraryEvent1 from "../../../sampleData/SampleLibraryEvent1";
import DetailsView from "../../EventFeatures/Details/DetailsView";
import SuggestionsTourRadar from "../../EventFeatures/Suggestions/SuggestionsTourRadar";
import LodgingAddFromPDF from "../../EventFeatures/Lodging/LodgingAddFromPDF";
import PAAttendee from "../../../MasterEventObjects/Attendee";
import Attendee from "../../../MasterEventObjects/Attendee";
import PhotosList from "../../EventFeatures/Photos/PhotosList";
import PhotosAddNew from "../../EventFeatures/Photos/PhotosAddNew";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import Lodging from "../../../MasterEventObjects/Lodging";
import PALocation from "../../../MasterEventObjects/Location";
import Activity from "../../../MasterEventObjects/Activity";
import Meal from "../../../MasterEventObjects/Meal";
import Idea from "../../../MasterEventObjects/Idea";
import Flight from "../../../MasterEventObjects/Flight";
import FlightsList from "../../EventFeatures/Flights/FlightsList";
import MealsList from "../../EventFeatures/Meals/MealsList";
import IdeasList from "../../EventFeatures/Ideas/IdeasList";
import ItineraryView from "../../EventFeatures/Itinerary/ItineraryView";
import ItineraryViewSection from "../../EventFeatures/Itinerary/ItineraryViewSection";
import {getEventFromDatabase} from "../../../Services/BrowserDatabaseFunctions";
import ConciergeServiceNew from "../../../Pages/PlanningPage/ConciergeServiceNew";
import MessagesList from "../../EventFeatures/Messages/MessagesList";
import TodoList from "../../EventFeatures/Todo/TodoList";
import ReceiptList from "../../EventFeatures/Receipts/ReceiptList";
import {
    createTimeTableForActivity, createTimeTableForFlight,
    createTimeTableForLodging,
    createTimeTableForMeal
} from "../../Helpers/EventTimeTableConversion";


function PlannerContent(props) {

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, setPlanning, setPlanningBudget, setPlanningBudgetCurrency } = useContext(PAContext);
    const { Account } = dataArray;

    const [zoomInOnLocation, setZoomInOnLocation] = useState(props.zoomInOn);
    const [searchingForLocation, setSearchingForLocation] = useState(props.searchingFor);
    const [suggestionForLocation, setSuggestionForLocation] = useState("");

    const [eventBudgetTotal, setEventBudgetTotal] = useState(0);
    const [eventBudgetActual, setEventBudgetActual] = useState(0);
    const [eventBudgetCurrency, setEventBudgetCurrency] = useState(0);
    const [eventBudgetExceeded, setEventBudgetExceeded] = useState(false);

    const [currentFeature, setCurrentFeature] = useState("Map");

    const [itineraryViewSection, setItineraryViewSection] = useState("");
    const [itineraryViewType, setItineraryViewType] = useState("");


    const [ideasFromMap, setIdeasFromMap] = useState({});
    const [activitiesFromMap, setActivitiesFromMap] = useState({});
    const [lodgingFromMap, setLodgingFromMap] = useState({});
    const [mealsFromMap, setMealsFromMap] = useState({});
    const [conciergePlanningItemsFromMap, setConciergePlanningItemsFromMap] = useState([]);

    const [lodgingFromSuggestions, setLodgingFromSuggestions] = useState({});
    const [lodgingPhotosFromMap, setLodgingPhotosFromMap] = useState([]);

    const [clearEvent, setClearEvent] = useState(false);
    const [eventId, setEventId] = useState("");
    const [eventName, setEventName] = useState("");
    const [eventBeginDate, setEventBeginDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [formattedEventDates, setFormattedEventDates] = useState("");

    const [eventAttendeesUpdated, setAttendeesUpdated] = useState(false);

    const [editAttendeeId, setEditAttendeeId] = useState("");
    const [selectedAttendeeForSupplies, setSelectedAttendeeForSupplies] = useState("");

    const [viewSuggestionLodgingMarkers, setViewSuggestionLodgingMarkers] = useState(false);
    const [viewSuggestionLodgingMarkersFilter, setViewSuggestionLodgingMarkersFilter] = useState([]);

    const [lodgingSuggestionNameFromMap, setLodgingSuggestionNameFromMap] = useState("");

    const [tourSuggestionsTourCities, setTourSuggestionsTourCities] = useState([]);

    const [showEventItemConfig, setShowEventItemConfig] = useState(false);
    const [showEventItemExtendedConfig, setShowEventItemExtendedConfig] = useState(false);

    const [pullWeatherReport, setPullWeatherReport] = useState(false);
    const [weatherReportReceived, setWeatherReportReceived] = useState(false);

    const [weatherReportReceivedSuccessOpen, setWeatherReportReceivedSuccessOpen] = useState(false);
    const handleSnackbarWeatherReportReceivedClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setWeatherReportReceivedSuccessOpen(false);
    };

    useEffect(() => {
        console.log("clearEvent");

        setSearchingForLocation("");
        setFormattedEventDates("");

        //clear the budget summary
        setEventBudgetTotal(0);
        setEventBudgetActual(0);

        setClearEvent(false);

    },[clearEvent]);

    useEffect(() => {
        console.log("Checking Planning ... ", usePA_GlobalStore.getState().PA_GlobalObject.Planning);
        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning === undefined || usePA_GlobalStore.getState().PA_GlobalObject.Planning.title === ""){

            console.log("Creating new Event");
            const newEvent = new PAEvent();
            newEvent.title = "My New Event";
            newEvent.status = 10;

            if (Account === undefined || Object.keys(Account).length === 0){
                //create the default Planner Attendee
                console.log("Need to create the default Planner Attendee");
                const _planner = new PAAttendee(
                    'John',
                    'Doe',
                    'sample.planning@plannedadventure.com');
                _planner.id = "100000";
                _planner.role = 'planner';
                _planner.planner = true;
                _planner.nickname = 'Planner';
                newEvent.attendees.push(_planner);
            } else {
                //they are logged in so use their account
                console.log("User is logged in");

                const _planner = new PAAttendee(
                    Account.fname,
                    Account.lname,
                    Account.email);
                _planner.id = Account.id;
                _planner.created = Account.created;
                _planner.role = Account.role;
                _planner.planner = true;
                _planner.nickname = Account.nickname;
                _planner.language = Account.language;

                _planner.address = Account.address;
                _planner.city = Account.city;
                _planner.state = Account.state;
                _planner.zip = Account.zip;
                _planner.country = Account.country;

                _planner.email = Account.email;
                _planner.dob = Account.dob;

                _planner.phone_prefix = Account.phone_prefix;
                _planner.phone = Account.phone;
                _planner.mask_phone_number = Account.mask_phone_number;

                _planner.events = Account.events;
                _planner.documents = Account.documents;

                newEvent.attendees.push(_planner);

            }

            setSearchingForLocation("");
            setFormattedEventDates("");

            //clear the budget summary
            setEventBudgetTotal(0);
            setEventBudgetActual(0);

            set_PA_GlobalObject({ Planning: newEvent });

            console.log("eventName : "+newEvent.title);
            setEventName(newEvent.title);

            console.log("after setPlanning");
        } else {
            //an event already exists
            console.log("the current Planning.title : " + usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);
            setEventName(usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);

            //change the map location if a location exists



        }

        //see if there is a location that needs to be gotten
        console.log("is searching : "+props.isSearching);
        if (props.isSearching){
            //see what they're searching for
            console.log("searching for : "+props.searchingFor);




        }

    }, []);

    useEffect(() => {
        console.log("--- PA_GlobalObject.Planning got triggered!!!! ",usePA_GlobalStore.getState().PA_GlobalObject.Planning);

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.id !== eventId){
            //a new Event was loaded
            setShowEventItemConfig(false);
            setEventId(usePA_GlobalStore.getState().PA_GlobalObject.Planning.id);
        }

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning && usePA_GlobalStore.getState().PA_GlobalObject.Planning.title !== ''){
            setEventName(usePA_GlobalStore.getState().PA_GlobalObject.Planning.title);

            //move the map
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging?.[0]?.location?.google_place_id) {
                setZoomInOnLocation({
                    google_place_id: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.google_place_id,
                    latitude: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.latitude,
                    longitude: usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging[0].location.longitude,
                });
            }

        }
    }, [PA_GlobalObject.Planning]);

    useEffect(() => {
        console.log("Event name has changed : "+eventName);

    }, [eventName]);

    useEffect(() => {
        console.log("eventBudgetTotal");
        setPlanningBudget(eventBudgetTotal);
    }, [eventBudgetTotal]);
    useEffect(() => {
        console.log("eventBudgetActual triggered!!");
//        setPlanning({
//            ...Planning,
//            budget_actual: eventBudgetActual
//        });
    }, [eventBudgetActual]);
    useEffect(() => {
        setPlanningBudgetCurrency(eventBudgetCurrency);
    }, [eventBudgetCurrency]);

    useEffect(() => {
        console.log("props.isSearching or props.searchingFor has been triggered ");
        if (props.isSearching){
            setSearchingForLocation(props.searchingFor);
        }
    }, [props.isSearching, props.searchingFor]);

    useEffect(() => {
        console.log("searchingForLocation has been triggered ");

    }, [searchingForLocation]);

    useEffect(() => {

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning !== undefined && usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'] !== undefined) {

            const _time_table = usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'];
            console.log("the _time_table : " , _time_table);

            // Showing the dates takes precedence over days
            const _datesArray = _time_table.dates;
            const _daysArray = _time_table.days;

            console.log("the _daysArray : " , _daysArray);

            // Utility function to format the time and replace 12:00 AM/PM with Midnight/Noon
            const formatTime = (time) => {
                const formattedTime = dayjs(time, 'h:mm A').format('h:mm A');
                if (formattedTime === '12:00 AM') return 'Midnight';
                if (formattedTime === '12:00 PM') return 'Noon';
                return formattedTime;
            };

            // Function to check if it's a full-day event based on the times
            const isFullDay = (beginTime, endTime) => {
                return (
                    dayjs(beginTime, 'h:mm A').format('h:mm A') === '12:00 AM' &&
                    dayjs(endTime, 'h:mm A').format('h:mm A') === '11:59 PM'
                );
            };

            // First, handle the dates (dates take precedence over days)
            if (_datesArray.length > 0) {
                // Parse dates to get minimum and maximum
                const minDateObj = _datesArray.reduce((min, current) => dayjs(min.date).isBefore(dayjs(current.date)) ? min : current);
                const maxDateObj = _datesArray.reduce((max, current) => dayjs(max.date).isAfter(dayjs(current.date)) ? max : current);

                const minDate = dayjs(minDateObj.date);
                const maxDate = dayjs(maxDateObj.date);

                // Format the time if available, using the formatTime utility to handle 12:00 AM/PM
                const minTime = minDateObj['begin-time'] ? formatTime(minDateObj['begin-time']) : null;
                const maxTime = maxDateObj['end-time'] ? formatTime(maxDateObj['end-time']) : null;

                // Check if the event spans a full day (12:00 AM to 11:59 PM)
                const fullDayEvent = isFullDay(minDateObj['begin-time'], maxDateObj['end-time']);
                const sameYear = minDate.year() === maxDate.year();

                // Format dates
                const minDateString = minDate.format('D MMM YYYY');
                const maxDateString = maxDate.format('D MMM YYYY');

                // Combine formatted dates and times
                if (minDate.isSame(maxDate, 'day')) {
                    // If dates are the same, show one date and the time range if it's not a full-day event
                    if (!fullDayEvent && minTime && maxTime) {
                        setFormattedEventDates(`${minDate.format('D MMM')}, ${minTime} - ${maxTime} ${minDate.format('YYYY')}`);
                    } else {
                        setFormattedEventDates(minDateString); // Show only date if it's a full-day event
                    }
                } else {
                    //different begin and end dates
                    // show the date range and time range if available and not a full-day event
                    if (!fullDayEvent && minTime && maxTime) {
                        setFormattedEventDates(`${minDateString} ${minTime} - ${maxDateString} ${maxTime} ${maxDate.format('YYYY')}`);
                    } else {
                        setFormattedEventDates(`${minDate.format('D MMM')} - ${maxDateString}`); // Show only date range if it's a full-day event
                    }
                }
            } else if (_daysArray.length > 0) {
                // Handle days if no dates are available
                if (_daysArray.length === 1) {
                    setFormattedEventDates(`1 day ${_daysArray[0]['day 1']}`);
                } else {
                    const _last_day = 'day ' + _daysArray.length;
                    setFormattedEventDates(`${_daysArray.length} days ${_daysArray[0]['day 1']} - ${_daysArray[_daysArray.length - 1][_last_day]}`);
                }
            } else {
                // No dates or days available
                setFormattedEventDates('');
            }

        }
    }, [PA_GlobalObject.Planning?.time_table]);

    useEffect(() => {
        console.log("showEventItemConfig has been triggered ");

    }, [showEventItemConfig]);

    useEffect(() => {
        console.log("showEventItemExtendedConfig has been triggered ");

    }, [showEventItemExtendedConfig]);

    useEffect(() => {
        console.log("Show all the suggestions for : "+suggestionForLocation);

    }, [suggestionForLocation]);

    useEffect(() => {
        //update the ConciergePlanning

    }, [conciergePlanningItemsFromMap]);

    useEffect(() => {
        console.log("pullWeatherReport : "+pullWeatherReport);
        if (pullWeatherReport){
            console.log("Need to pull the weather report");
            setWeatherReportReceived(false);

            try {
                /*
                    look at the Planning.time_table to see if dates array has entries
                    and look at any Planning.lodging.location to
                    see if the weather report should be retrieved
                 */

                const hasDates = PA_GlobalObject.Planning?.time_table?.dates?.length > 0;
                const begin_date = dayjs(PA_GlobalObject.Planning.time_table.dates[0].date); // First date in the time table
                const hasLocation = PA_GlobalObject.Planning?.lodging?.some(lodge => lodge.location && lodge.location.latitude && lodge.location.longitude);
                const daysUntilBeginDate = begin_date.diff(dayjs(), 'day');

                // Proceed only if both conditions are met
                if (hasDates && hasLocation && daysUntilBeginDate <= 14) {

                    const results = WeatherUsingVisualCrossing(PA_GlobalObject.Planning);

                    if (results) {
                        setWeatherReportReceived(true);
                        setWeatherReportReceivedSuccessOpen(true);
                    } else {
                        setWeatherReportReceived(true);
                    }

                }
            } catch (e){
                console.log(e);
            }

        }

    }, [pullWeatherReport]);


    /*
        for the total expense icon
     */
    const [openBudgetExplanationDialog, setOpenBudgetExplanationDialog] = useState(false);

    function handleOpenBudgetExplanationDialog (){
        setOpenBudgetExplanationDialog(true);
    }

    function handleBudgetExplanationDialogClose(){
        setOpenBudgetExplanationDialog(false);
    }

    /*
        for the Events menu list
     */

    const handleEventMenuClick = (event_id) => {
        console.log("handleEventMenuClick");
        console.log("event_id : "+event_id);

        setAnchorLibraryMenu(null);

        //load the Event from the browser database
        getEventFromDatabase(event_id).then(specificEvent => {
            console.log("specificEvent : "+specificEvent);

            if (specificEvent !== null){
                const _event = new PAEvent();
                _event.id = specificEvent.id;
                _event.title = specificEvent.title;
                _event.coverImage = specificEvent.coverImage;
                _event.cover_image_thumbnail = specificEvent.cover_image_thumbnail;

                //convert the calendar if necessary
                const _existing_calendar = specificEvent.calendar;
                if (_existing_calendar){
                    const _time_table = new TimeTable();

                    const beginDate = dayjs(specificEvent.calendar.begin_date);
                    const endDate = dayjs(specificEvent.calendar.end_date);

                    const dateObjects = [];
                    const daysOfWeekSet = new Set(); // To store unique days of the week

                    // Iterate through each day between begin_date and end_date
                    let currentDate = beginDate;
                    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
                        const dateObj = {
                            date: currentDate.format('YYYY-MM-DD'),
                            dayOfWeek: currentDate.format('dddd'),
                            'all-day': true, // Assuming it's an all-day event by default
                            notes: '', // Initialize notes as an empty string
                        };

                        // Add the unique day of the week to the Set
                        daysOfWeekSet.add(currentDate.format('dddd'));

                        // Check if the day is the first day and if it's not an all-day event
                        if (currentDate.isSame(beginDate, 'day') && (beginDate.hour() !== 0 || beginDate.minute() !== 0)) {
                            dateObj['all-day'] = false;
                        }

                        // Check if the day is the last day and if it's not an all-day event
                        if (currentDate.isSame(endDate, 'day') && (endDate.hour() !== 23 || endDate.minute() !== 59)) {
                            dateObj['all-day'] = false;
                        }

                        // Set begin-time and end-time if they exist
                        if (currentDate.isSame(beginDate, 'day')) {
                            dateObj['begin-time'] = beginDate.format('h:mm A');
                        }
                        if (currentDate.isSame(endDate, 'day')) {
                            dateObj['end-time'] = endDate.format('h:mm A');
                        }

                        dateObjects.push(dateObj);
                        currentDate = currentDate.add(1, 'day'); // Move to the next day
                    }

                    // Update _time_table with dates
                    _time_table.dates = dateObjects;

                    // Populate _time_table.days with the unique days of the week from daysOfWeekSet
                    _time_table.days = Array.from(daysOfWeekSet).map((dayOfWeek, index) => ({
                        [`day ${index + 1}`]: dayOfWeek,
                        'all-day': true,
                        notes: '', // Initialize with empty notes
                    }));

                    _event.time_table = _time_table;

                } else {
                    _event.time_table = specificEvent.time_table;
                }

                //convert the attendees if necessary
                if (specificEvent.attendees.budget === undefined){
                    const updated_attendees = [];

                    for (const _attendee of specificEvent.attendees){
                        const _pa_attendee = new Attendee(
                            _attendee.fname,
                            _attendee.lname,
                            _attendee.email
                        );

                        _pa_attendee.id = _attendee.id;
                        _pa_attendee.role = _attendee.role;
                        _pa_attendee.image = _attendee.image;
                        _pa_attendee.nickname = _attendee.nickname;

                        _pa_attendee.address = _attendee.address;
                        _pa_attendee.city = _attendee.city;
                        _pa_attendee.state = _attendee.state;
                        _pa_attendee.zip = _attendee.zip;
                        _pa_attendee.country = _attendee.country;

                        _pa_attendee.phone_prefix = _attendee.phone_prefix;
                        _pa_attendee.phone = _attendee.phone;
                        _pa_attendee.mask_phone_number = _attendee.mask_phone_number;

                        _pa_attendee.push_notifications = _attendee.push_notifications;
                        _pa_attendee.push_keys = _attendee.push_keys;

                        _pa_attendee.rsvp_sent = _attendee.rsvp_sent;
                        _pa_attendee.rsvp = _attendee.rsvp;
                        _pa_attendee.rsvp_notes = _attendee.rsvp_notes;

                        _pa_attendee.notification = _attendee.notification;

                        _pa_attendee.documents = _attendee.documents;

                        _pa_attendee.last_check = _attendee.last_check;
                        _pa_attendee.status = _attendee.status;

                        updated_attendees.push(_pa_attendee);
                    }
                    _event.attendees = updated_attendees;
                } else {
                    _event.attendees = specificEvent.attendees;
                }

                //convert the lodging if necessary
                const _existing_lodging = specificEvent.lodging;
                if (_existing_lodging && _existing_lodging.length > 0){

                    if (_existing_lodging[0].location === undefined){
                        //need to convert
                        for (const _existing of _existing_lodging){
                            const _new_lodging = new Lodging();
                            _new_lodging.id = _existing.id;

                            _new_lodging.name = _existing.name;

                            _new_lodging.begin_date = _existing.begin_date;
                            _new_lodging.end_date = _existing.end_date;
                            _new_lodging.check_in = _existing.check_in;
                            _new_lodging.check_out = _existing.check_out;

                            //now figure out the time_table
                            _new_lodging.time_table = createTimeTableForLodging(
                                _existing.begin_date,
                                _existing.end_date,
                                _event.time_table);


                            //add the lodging location
                            const _lodging_location = new PALocation();
                            _lodging_location.google_place_id = _existing.google_place_id;
                            _lodging_location.latitude = _existing.latitude;
                            _lodging_location.longitude = _existing.longitude;

                            _lodging_location.name = _existing.name;
                            _lodging_location.formatted_address = "";
                            _lodging_location.address = _existing.address;
                            _lodging_location.city = _existing.city;
                            _lodging_location.state = _existing.state;
                            _lodging_location.zip = _existing.zip;
                            _lodging_location.country = _existing.country;

                            _new_lodging.location = _lodging_location;

                            _new_lodging.phone = _existing.phone;

                            _new_lodging.booked_through = _existing.booked_through;
                            _new_lodging.confirmation_number = _existing.confirmation_number;
                            _new_lodging.reservation_name = _existing.reservation_name;
                            _new_lodging.reservation_number = _existing.reservation_name;
                            _new_lodging.gate_code = _existing.gate_code;
                            _new_lodging.door_code = _existing.door_code;
                            _new_lodging.wifi_password = _existing.wifi_password;

                            for (const _att of _existing.attendees){
                                if (_att.costs === undefined){
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_lodging.attendees.push(_att_with_cost);
                                } else {
                                    _new_lodging.attendees.push(_att);
                                }

                            }

                            _new_lodging.amount_total = _existing.amount_total === undefined ? 0 : _existing.amount_total;
                            _new_lodging.currency = _existing.currency === undefined ? "USD" : _existing.currency;

                            _new_lodging.time_zone = _existing.time_zone;
                            _new_lodging.notes = _existing.notes;
                            _new_lodging.status = _existing.status;

                            _event.lodging.push(_new_lodging);
                        }
                    } else {
                        _event.lodging = specificEvent.lodging;
                    }
                }

                //convert the activities if necessary
                const _existing_activities = specificEvent.activities;
                if (_existing_activities && _existing_activities.length > 0) {

                    const _activities_list= _existing_activities.map(_activity => {

                        if (_activity.amount_total === undefined) {
                            console.log("the Event Activity neeeds to be converted");
                            const _new_activity = new Activity();
                            _new_activity.id = _activity.id;
                            _new_activity.title = _activity.title;
                            _new_activity.begin_date = _activity.begin_date;
                            _new_activity.end_date = _activity.end_date;
                            _new_activity.all_day_activity = _activity.all_day_activity;
                            _new_activity.begin_time = _activity.begin_time;
                            _new_activity.end_time = _activity.end_time;

                            //now figure out the time_table
                            _new_activity.time_table = createTimeTableForActivity(
                                _activity.begin_date,
                                _activity.end_date,
                                _activity.all_day_activity,
                                _activity.begin_time,
                                _activity.end_time,
                                _event.time_table);

                            //add the activity location
                            const _activity_location = new PALocation();
                            _activity_location.google_place_id = _activity.location.google_place_id;
                            _activity_location.latitude = _activity.location.latitude;
                            _activity_location.longitude = _activity.location.longitude;

                            _activity_location.name = _activity.location.name;
                            _activity_location.formatted_address = "";
                            _activity_location.address = _activity.location.address;
                            _activity_location.city = _activity.location.city;
                            _activity_location.state = _activity.location.state;
                            _activity_location.zip = _activity.location.zip;
                            _activity_location.country = _activity.location.country;

                            _new_activity.location = _activity_location;

                            for (const _att of _activity.attendees){
                                if (_att.costs === undefined){
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_activity.attendees.push(_att_with_cost);
                                } else {
                                    _new_activity.attendees.push(_att);
                                }
                            }

                            _new_activity.phone = _activity.location.phone;
                            _new_activity.booked_through = _activity.location.booked_through;
                            _new_activity.confirmation_number = _activity.location.confirmation_number;
                            _new_activity.reservation_number = _activity.location.reservation_number;
                            _new_activity.reservation_name = _activity.location.reservation_name;

                            _new_activity.details = _activity.details;
                            _new_activity.bring = _activity.bring;

                            _new_activity.amount_total = _activity.amount_total === undefined ? 0 : _activity.amount_total;
                            _new_activity.currency = _activity.currency === undefined ? "USD" : _activity.currency;

                            _new_activity.notes = _activity.notes;

                            _new_activity.status = _activity.status;

                            return _new_activity;

                        } else {
                            return _activity;
                        }

                    });
                    _event.activities = _activities_list;

                }

                //convert the meals if necessary
                const _existing_meals = specificEvent.meals;
                if (_existing_meals && _existing_meals.length > 0) {

                    const _meal_list= _existing_meals.map(_meal => {

                        if (_meal.eatery_name || _meal.eatery_name === "") {
                            // Convert old structure to new structure

                            const _new_meal = new Meal();
                            _new_meal.id = _meal.id;
                            _new_meal.name = _meal.meal_name;
                            _new_meal.date = _meal.meal_date;
                            _new_meal.time = _meal.meal_time;

                            //now figure out the time_table
                            _new_meal.time_table = createTimeTableForMeal(
                                _meal.meal_date,
                                _meal.meal_time,
                                _event.time_table);

                            //add the meal location
                            const _meal_location = new PALocation();
                            _meal_location.google_place_id = _meal.eatery_google_place_id;
                            _meal_location.latitude = _meal.eatery_latitude;
                            _meal_location.longitude = _meal.eatery_longitude;

                            _meal_location.name = _meal.eatery_name;
                            _meal_location.formatted_address = "";
                            _meal_location.address = _meal.eatery_address;
                            _meal_location.city = _meal.eatery_city;
                            _meal_location.state = _meal.eatery_state;
                            _meal_location.zip = _meal.eatery_zip;
                            _meal_location.country = _meal.eatery_country;

                            _new_meal.location = _meal_location;

                            _new_meal.phone = _meal.eatery_phone;
                            _new_meal.booked_through = _meal.booked_through;
                            _new_meal.confirmation_number = _meal.confirmation_number;
                            _new_meal.reservation_number = _meal.reservation_number;
                            _new_meal.reservation_name = _meal.reservation_name;

                            _new_meal.eat_in = _meal.eat_in;
                            _new_meal.eat_in_details = _meal.eat_in_details;
                            _new_meal.notes = _meal.eatery_notes;

                            for (const _att of _meal.attendees) {
                                if (_att.costs === undefined) {
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_meal.attendees.push(_att_with_cost);
                                } else {
                                    _new_meal.attendees.push(_att);
                                }
                            }

                            _new_meal.photos = [];
                            _new_meal.amount_total = _meal.amount_total === undefined ? 0 : _meal.amount_total;
                            _new_meal.currency = _meal.currency === undefined ? "USD" : _meal.currency;
                            _new_meal.assign_equal = _meal.assign_equal === undefined ? true : _meal.assign_equal;

                            _new_meal.status = _meal.status;

                            return _new_meal;
                        } else {
                            return _meal;
                        }

                    });

                    _event.meals = _meal_list;

                }

                //convert the ideas if necessary
                const _existing_ideas = specificEvent.ideas;
                if (_existing_ideas && _existing_ideas.length > 0) {

                    if (_existing_ideas[0].location === undefined) {
                        for (const _existing of _existing_ideas) {
                            const _new_idea = new Idea();
                            _new_idea.id = _existing.id;
                            _new_idea.index = _existing.index;
                            _new_idea.title = _existing.title;
                            _new_idea.category = _existing.category;

                            _new_idea.attendee_id = _existing.attendee_id;

                            _new_idea.url = _existing.url;

                            const _idea_location = new PALocation();
                            _idea_location.google_place_id = _existing.google_place_id;
                            _idea_location.latitude = _existing.latitude;
                            _idea_location.longitude = _existing.longitude;

                            _idea_location.name = _existing.name;
                            _idea_location.formatted_address = "";
                            _idea_location.address = _existing.address;
                            _idea_location.city = _existing.city;
                            _idea_location.state = _existing.state;
                            _idea_location.zip = _existing.zip;
                            _idea_location.country = _existing.country;

                            _new_idea.location = _idea_location;

                            _new_idea.phone = _existing.phone;

                            _new_idea.comments = _existing.comments;

                            _new_idea.status = _existing.status;

                            _event.ideas.push(_new_idea);
                        }
                    } else {
                        _event.ideas = specificEvent.ideas;
                    }
                }

                //convert the flights if necessary
                const _existing_flights = specificEvent.flights;
                if (_existing_flights && _existing_flights.length > 0) {

                    if (_existing_flights[0].amount_total === undefined) {
                        for (const _existing of _existing_flights) {
                            const _new_flight = new Flight();
                            _new_flight.id = _existing.id;
                            _new_flight.flight_group_id = _existing.flight_group_id;
                            _new_flight.ident = _existing.ident;
                            _new_flight.actual_ident = _existing.actual_ident;
                            _new_flight.flight_number = _existing.flight_number;

                            _new_flight.airline_code = _existing.airline_code;
                            _new_flight.airline_name = _existing.airline_name;

                            //now figure out the time_table
                            _new_flight.time_table = createTimeTableForFlight(
                                _existing.depart_timestamp,
                                _existing.arrive_timestamp,
                                _event.time_table);

                            _new_flight.flight_duration = _existing.flight_duration;

                            _new_flight.depart_airport_code = _existing.depart_airport_code;
                            _new_flight.depart_airport_name = _existing.depart_airport_name;
                            _new_flight.depart_airport_city = _existing.depart_airport_city;
                            _new_flight.depart_airport_latitude = _existing.depart_airport_latitude;
                            _new_flight.depart_airport_longitude = _existing.depart_airport_longitude;
                            _new_flight.depart_timestamp = _existing.depart_timestamp;
                            _new_flight.depart_timezone = _existing.depart_timezone;
                            _new_flight.depart_timezone_offset = _existing.depart_timezone_offset;

                            _new_flight.arrive_airport_code = _existing.arrive_airport_code;
                            _new_flight.arrive_airport_name = _existing.arrive_airport_name;
                            _new_flight.arrive_airport_city = _existing.arrive_airport_city;
                            _new_flight.arrive_airport_latitude = _existing.arrive_airport_latitude;
                            _new_flight.arrive_airport_longitude = _existing.arrive_airport_longitude;
                            _new_flight.arrive_timestamp = _existing.arrive_timestamp;
                            _new_flight.arrive_timezone = _existing.arrive_timezone;
                            _new_flight.arrive_timezone_offset = _existing.arrive_timezone_offset;

                            for (const _att of _existing.attendees){
                                if (_att.costs === undefined){
                                    const _att_with_cost = {};
                                    _att_with_cost.id = _att;
                                    _att_with_cost.cost = 0;
                                    _new_flight.attendees.push(_att_with_cost);
                                } else {
                                    _new_flight.attendees.push(_att);
                                }
                            }

                            _new_flight.amount_total = _existing.amount_total === undefined ? 0 : _existing.amount_total;
                            _new_flight.currency = _existing.currency === undefined ? "USD" : _existing.currency;

                            _new_flight.notes = "";
                            _new_flight.status = _existing.status;

                            _event.flights.push(_new_flight);
                        }
                    } else {
                        _event.flights = specificEvent.flights;
                    }
                }

                _event.supplies = specificEvent.supplies;
                _event.to_do = specificEvent.to_do;
                _event.documents = specificEvent.documents;
                _event.receipts = specificEvent.receipts;

                console.log("specificEvent.messages : "+JSON.stringify(specificEvent.messages, null, '\t'));
                _event.messages = specificEvent.messages === undefined ? [] : specificEvent.messages;
                console.log("s_event.messages : "+JSON.stringify(_event.messages, null, '\t'));

                _event.photos = specificEvent.photos;

                _event.menu_items = specificEvent.menu_items;

                _event.need_to_save = specificEvent.need_to_save === undefined ? false : specificEvent.need_to_save;

                _event.version = 2.0;
                _event.last_mod = specificEvent.last_mod;
                _event.status = specificEvent.status;

                console.log("the new Event : "+JSON.stringify(_event, null, '\t'));

                //set the Event
                setPlanning(_event);


                //update the Event name display
                console.log("trying to set the event name to : "+specificEvent.title);
                setEventName(specificEvent.title);

                //change map location
                // Create an object to store seen city/state pairs
                if (_existing_lodging && _existing_lodging.length > 0){
                    setZoomInOnLocation({
                        'google_place_id': _event.lodging[0].location.google_place_id,
                        'latitude': _event.lodging[0].location.latitude,
                        'longitude': _event.lodging[0].location.longitude
                    })
                    setSearchingForLocation(_event.lodging[0].location.city+" "+_event.lodging[0].location.state);
                }

                //update the budget
                setEventBudgetTotal(_event.budget_total);

                /*
                    check for relevant weather
                    which means anything in the past or anything that isn't more than
                    2 weeks in the future
                 */
                setPullWeatherReport(true);

                /*
                    //see if there are any NEW Event messages
                 */
                const _send_string = {};
                _send_string.action = "E_getEventMessages";
                _send_string.event_id = _event.id;

                if (_event.messages.length === 0){
                    _send_string.latest_message_timestamp = 0;
                } else {
                    _send_string.latest_message_timestamp = _event.messages.reduce((latest, message) => {
                        return message.created > latest ? message.created : latest;
                    }, 0);
                }

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");


                /*
                    let AI know of the Event switch?
                 */


            } else {
                //no Event found
                console.log("specificEvent NOT FOUND");

            }
        });

    }

    /*
        for the library icon
     */
    const [anchorLibraryMenu, setAnchorLibraryMenu] = useState(null);
    const handleLibraryMenuOpenMenu = (event) => {
        setAnchorLibraryMenu(event.currentTarget);
    };
    const handleLibraryMenuClick = (event) => {
        console.log("handleLibraryMenuClick");
        setAnchorLibraryMenu(event.currentTarget);
        setAnchorLibraryMenu(null);

        //load the Event
        const _libraryEvent = SampleLibraryEvent1();
        setPlanning(_libraryEvent);

        //update the Event name display
        console.log("rtying to set the event name to : "+_libraryEvent.title);
        setEventName(_libraryEvent.title);

        //change map location
        const _libraryEventLocations = _libraryEvent.locations;
        // Create an object to store seen city/state pairs
        setZoomInOnLocation({
            'google_place_id': _libraryEventLocations[0].google_place_id,
            'latitude': _libraryEventLocations[0].latitude,
            'longitude': _libraryEventLocations[0].longitude
        })
        setSearchingForLocation(_libraryEventLocations[0].city+" "+_libraryEventLocations[0].state);

        //update the budget
        setEventBudgetTotal(_libraryEvent.budget_total);



        //figure out the actual expenses
        //this can include more than one type of currency
        //group by currency


        _libraryEvent.attendees.map((_att) => {
            const totalExpensesByCurrency = {};

            // Calculate total expenses for activities
            _libraryEvent.activities.forEach((activity) => {
                const _activityAttendees = activity.attendees;

                const { currency } = activity;
                for (const _activityAttendee of _activityAttendees){
                    if (_activityAttendee.id === _att.id){
                        // Check if the currency exists in the totalExpensesByCurrency object
                        if (!totalExpensesByCurrency[currency]) {
                            totalExpensesByCurrency[currency] = 0;
                        }

                        // Add the amount to the total for the respective currency
                        totalExpensesByCurrency[currency] += _activityAttendee.cost;
                    }
                }

            });

            // Calculate total expenses for lodgings
            _libraryEvent.lodging.forEach((lodging) => {
                const _lodgingAttendees = lodging.attendees;

                const { currency } = lodging;
                for (const _lodgingAttendee of _lodgingAttendees){
                    if (_lodgingAttendee.id === _att.id){
                        // Check if the currency exists in the totalExpensesByCurrency object
                        if (!totalExpensesByCurrency[currency]) {
                            totalExpensesByCurrency[currency] = 0;
                        }

                        // Add the amount to the total for the respective currency
                        totalExpensesByCurrency[currency] += _lodgingAttendee.cost;
                    }
                }

            });

            // Print the total expenses by currency
            for (const currency in totalExpensesByCurrency) {
                console.log(`Total expenses ${_att.fname} in ${currency}: ${totalExpensesByCurrency[currency]}`);
            }

        });


    };
    const handleLibraryMenuClose = () => {
        setAnchorLibraryMenu(null);
    };

    const [ showConciergeServiceWindow, setShowConciergeServiceWindow ] = useState(false);
    function toggleShowConciergeServiceWindow(){
        setShowConciergeServiceWindow(!showConciergeServiceWindow);
    }
    const [ showCalendarTodoWindow, setShowCalendarTodoWindow ] = useState(false);
    function toggleShowCalendarTodoWindow(){
        setShowCalendarTodoWindow(!showCalendarTodoWindow);
    }

    return (

        <div id="planning_div" className="planning-div">

            {/* First row */}
            <Grid container spacing={2} justify="space-between" sx={{position: 'relative', marginTop: '10px'}}>
                {/* this is the horizontal list of features icons */}
                <Grid item sx={{marginRight:'120px'}}>
                    <Paper
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            backgroundColor: 'rgb(251, 251, 251)',
                            whiteSpace: 'nowrap',
                            paddingTop: '10px',
                            marginRight: '10px',
                            overflowX: 'auto',
                            overflowY: 'hidden'
                        }}
                    >
                        {/* Features List */}
                        <FeaturesList
                            currentFeature={currentFeature}
                            showEventItemConfig={showEventItemConfig}
                            setShowEventItemConfig={setShowEventItemConfig}
                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                            setClearEvent={setClearEvent}
                            eventBudgetTotal={eventBudgetTotal}
                            eventBudgetActual={eventBudgetActual}
                            weatherReportReceived={weatherReportReceived}
                        />

                    </Paper>
                </Grid>

                {/* this is the calendar/to-do icon */}
                <Grid item sx={{position:'absolute', top:'1px', right:'60px'}}>
                    <Paper
                        sx={{
                            display: 'flex',
                            backgroundColor: 'rgb(251, 251, 251)',
                            paddingTop: '2px'
                        }}>

                        <div className="planning-budget-list">
                            <Tooltip title="Calendar & To-Do List">
                                <IconButton
                                    sx={{
                                        position: 'relative',
                                        boxShadow: 2,
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        color: '#F00'
                                    }}
                                    onClick={()=>handleOpenBudgetExplanationDialog()}
                                >
                                    <i className="icon-calendar-user" style={{fontSize:'28px', padding:'5px'}}/>
                                </IconButton>
                            </Tooltip>
                            <Dialog
                                open={openBudgetExplanationDialog}
                                onClose={handleBudgetExplanationDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Budget Summary
                                </DialogTitle>
                                <DialogContent>

                                    {/* add the budget summary breakdown */}
                                    <BudgetSummary
                                        eventBudgetTotal={eventBudgetTotal}
                                        setEventBudgetTotal={setEventBudgetTotal}
                                        eventBudgetActual={eventBudgetActual}
                                    />

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleBudgetExplanationDialogClose} autoFocus>
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Paper>
                </Grid>

                {/* this is the AI concierge icon */}
                <Grid item sx={{position:'absolute', top:'1px', right:'0'}}>
                    <Paper
                        sx={{
                            display: 'flex',
                            backgroundColor: 'rgb(251, 251, 251)',
                            paddingTop: '2px'
                        }}>

                        <div className="planning-library-list">
                            <Tooltip title="Concierge Service">
                                <IconButton
                                    sx={{
                                        boxShadow: 2,
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        color: '#F00'
                                    }}
                                    onClick={toggleShowConciergeServiceWindow}
                                >
                                    <i className="icon-robot" style={{fontSize:'28px', padding:'5px'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>

                    </Paper>
                </Grid>
            </Grid>


            {/* Event Summary Row */}
            <div className="planning-div-summary-container">
                {/* Event title on the left */}
                <div className="planning-div-summary-event-title">{eventName}</div>

                {/* Right-aligned Chip and Dates */}
                <div className="planning-div-summary-right">
                    {usePA_GlobalStore.getState().PA_GlobalObject.Planning?.status === 10 && (
                        <Chip
                            sx={{ marginRight: '10px', backgroundColor: '#abd154' }}
                            size="small"
                            label="Planning"
                        />
                    )}
                    {usePA_GlobalStore.getState().PA_GlobalObject.Planning?.is_template && (
                        <Chip
                            sx={{ marginRight: '10px', backgroundColor: '#abd154' }}
                            size="small"
                            label="Template"
                        />
                    )}
                    <span className="planning-div-summary-dates">{formattedEventDates}</span>
                </div>
            </div>






            {/* Main Content Area */}
            <div style={{display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '10px'}}>

                {/* this is the space to view/add/edit/delete any of the features */}
                <div style={{
                    marginLeft: '10px',
                    display: showEventItemConfig ? 'flex' : 'none',
                    flexDirection: 'column',
                    flexBasis: '350px', /* Set a base width for this section */
                    maxWidth: '350px',  /* Prevent it from expanding too wide */
                    flexShrink: 0       /* Prevent it from shrinking too much */
                }}>
                    <div className="planning-event-routes-holder">
                        <Paper style={{
                            backgroundColor: 'rgb(251, 251, 251)',
                        }}
                        >
                            <Routes>
                                <Route
                                    path="Details"
                                    element={
                                        <DetailsView
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setEventName={setEventName}
                                        />}
                                />
                                <Route
                                    path="Location"
                                    element={
                                        <LocationDetails
                                            setZoomInOnLocation={setZoomInOnLocation}
                                            searchingForLocation={searchingForLocation}
                                            setSearchingForLocation={setSearchingForLocation}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setPullWeatherReport={setPullWeatherReport}
                                            _global_map_ref={props._global_map_ref}
                                        />}
                                />

                                <Route
                                    path="Dates"
                                    element={
                                        <DatesTimesContent
                                            setEventName={setEventName}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            setPullWeatherReport={setPullWeatherReport}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Itinerary/*"
                                    element={
                                        <ItineraryView
                                            setItineraryViewSection={setItineraryViewSection}
                                            setItineraryViewType={setItineraryViewType}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Activities"
                                    element={
                                        <ActivitiesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route path="Activities/Add" element={<ActivitiesAdd/>}/>
                                <Route path="Activities/Edit" element={<ActivitiesEdit/>}/>

                                <Route
                                    path="Ideas"
                                    element={
                                        <IdeasList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Attendees"
                                    element={
                                        <AttendeesList
                                            setEditAttendeeId={setEditAttendeeId}
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Attendees/AddNew"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/AddPrevious"
                                       element={<AttendeesAddPrevious setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/AddSamples"
                                       element={<AttendeesAddSamples setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Attendees/Edit" element={<AttendeeEdit editAttendeeId={editAttendeeId}/>}/>


                                <Route
                                    path="Lodging"
                                    element={
                                        <LodgingList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromMap"
                                    element={
                                        <LodgingAddFromMap
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromMap={lodgingFromMap}
                                            lodgingPhotosFromMap={lodgingPhotosFromMap}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                            setEventBudgetCurrency={setEventBudgetCurrency}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromSuggestions"
                                    element={
                                        <LodgingAddFromSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            lodgingFromSuggestions={lodgingFromSuggestions}
                                            eventBeginDate={eventBeginDate}
                                            eventEndDate={eventEndDate}
                                            setEventBeginDate={setEventBeginDate}
                                            setEventEndDate={setEventEndDate}
                                            eventBudgetActual={eventBudgetActual}
                                            setEventBudgetActual={setEventBudgetActual}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/AddFromPDF"
                                    element={
                                        <LodgingAddFromPDF
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Lodging/Suggestions"
                                    element={
                                        <LodgingSuggestions
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setViewSuggestionLodgingMarkersFilter={setViewSuggestionLodgingMarkersFilter}
                                            lodgingSuggestionNameFromMap={lodgingSuggestionNameFromMap}
                                            setCurrentFeature={setCurrentFeature}
                                            setLodgingFromSuggestions={setLodgingFromSuggestions}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Flights"
                                    element={
                                        <FlightsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route path="Flights/SearchAndBook"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Flights/Search"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>
                                <Route path="Flights/EnterDetails"
                                       element={<AttendeesAddNew setAttendeesUpdated={setAttendeesUpdated}/>}/>

                                <Route
                                    path="Messages"
                                    element={
                                        <MessagesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Meals"
                                    element={
                                        <MealsList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            _global_map_ref={props._global_map_ref}
                                        />
                                    }
                                />

                                <Route
                                    path="Photos"
                                    element={
                                        <PhotosList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Photos/AddNew"
                                    element={
                                        <PhotosAddNew/>
                                    }
                                />

                                <Route
                                    path="Suggestions/Tours"
                                    element={
                                        <SuggestionsTourRadar
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setTourSuggestionsTourCities={setTourSuggestionsTourCities}
                                        />
                                    }
                                />

                                <Route
                                    path="Suggestions/Unique"
                                    element={
                                        <SuggestionsUnique
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />


                                <Route
                                    path="Supplies/*"
                                    element={
                                        <SuppliesList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                            selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                            setSelectedAttendeeForSupplies={setSelectedAttendeeForSupplies}
                                        />}
                                />
                                <Route path="Supplies/AddEssentials" element={<SuppliesAddEssentials/>}/>
                                <Route path="Supplies/AddNew" element={<SuppliesAddNew/>}/>

                                <Route
                                    path="Receipts"
                                    element={
                                        <ReceiptList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Todo"
                                    element={
                                        <TodoList
                                            setShowEventItemConfig={setShowEventItemConfig}
                                        />
                                    }
                                />

                                <Route
                                    path="Weather"
                                    element={
                                        <WeatherDetails
                                            pullWeatherReport={pullWeatherReport}
                                            setWeatherReportReceived={setWeatherReportReceived}
                                            setWeatherReportReceivedSuccessOpen={setWeatherReportReceivedSuccessOpen}
                                        />}
                                />


                            </Routes>
                        </Paper>
                    </div>
                </div>


                {/* this is the EXTENDED space to view/add/edit/delete any of the features */}
                <div style={{
                    display: showEventItemExtendedConfig ? 'flex' : 'none',
                    flexDirection: 'column',
                    flexBasis: '350px', /* Set a base width for this section */
                    maxWidth: '350px',  /* Prevent it from expanding too wide */
                    flexShrink: 0       /* Prevent it from shrinking too much */
                }}>

                    <div className="planning-event-routes-extended-holder">
                        <Paper style={{
                            backgroundColor: 'rgb(251, 251, 251)',
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                        >
                            <Routes>
                                <Route
                                    path="Itinerary/Section"
                                    element={
                                        <ItineraryViewSection
                                            itineraryViewSection={itineraryViewSection}
                                            itineraryViewType={itineraryViewType}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                        />
                                    }
                                />
                                <Route
                                    path="Supplies/AddSuggestions"
                                    element={
                                        <SuppliesAddSuggestions
                                            selectedAttendeeForSupplies={selectedAttendeeForSupplies}
                                            setSelectedAttendeeForSupplies={setSelectedAttendeeForSupplies}
                                            setShowEventItemExtendedConfig={setShowEventItemExtendedConfig}
                                        />
                                    }
                                />
                            </Routes>
                        </Paper>
                    </div>
                </div>


                {/* this holds the Suggestions and then the map and concierge holder */}
                <div style={{
                    flex: '1',
                    minWidth: '300px',
                    maxWidth: '100%',
                    maxHeight: 'calc(100vh - 170px)',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}>

                    {/* this is a collapsible area for showing selection lists
                        for example, selecting flights or lists of hotels to choose from
                     */}
                    <div className="planning-whiteboard-list-holder">

                    </div>

                    {/* Google Map and Concierge Side-by-Side Holder */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        overflow: 'hidden'
                    }}>

                        {/* Google Map */}
                        <div className="planning-map-area-container">
                            <Paper sx={{
                                backgroundColor: 'rgb(251, 251, 251)',
                            }}
                            >
                                <GoogleMap
                                    _global_map_ref={props._global_map_ref}
                                    zoomInOnLocation={zoomInOnLocation}
                                    searchingForLocation={searchingForLocation}
                                    setShowEventItemConfig={setShowEventItemConfig}
                                    setCurrentFeature={setCurrentFeature}
                                    viewSuggestionLodgingMarkers={viewSuggestionLodgingMarkers}
                                    viewSuggestionLodgingMarkersFilter={viewSuggestionLodgingMarkersFilter}
                                    setLodgingSuggestionNameFromMap={setLodgingSuggestionNameFromMap}
                                    setIdeasFromMap={setIdeasFromMap}
                                    setLodgingFromMap={setLodgingFromMap}
                                    setLodgingPhotosFromMap={setLodgingPhotosFromMap}
                                    setMealsFromMap={setMealsFromMap}
                                    conciergePlanningItemsFromMap={conciergePlanningItemsFromMap}
                                    setConciergePlanningItemsFromMap={setConciergePlanningItemsFromMap}
                                    tourSuggestionsTourCities={tourSuggestionsTourCities}
                                    clearEvent={clearEvent}
                                />
                            </Paper>
                        </div>

                        {/* Concierge */}
                        {
                            showConciergeServiceWindow ? (
                                <ConciergeServiceNew
                                    searchingForLocation={searchingForLocation}
                                    conciergePlanningItemsFromMap={conciergePlanningItemsFromMap}
                                    clearEvent={clearEvent}
                                />

                            ) : (
                                <></>
                            )
                        }

                        {/* Calendar & To-Do */}
                        {
                            showCalendarTodoWindow ? (
                                <Typography>Calendar & To-Do</Typography>
                            ) : (
                                <></>
                            )
                        }

                    </div>

                    <div className="planning-suggestions-list-holder">
                        <Paper style={{backgroundColor: 'rgb(251, 251, 251)', padding: '10px 0 0 0'}}>
                        <div className="planning-suggestions-list-title">Concierge Suggestions</div>
                            <div className="planning-suggestions-list">
                                <Suggestions
                                    setShowEventItemConfig={setShowEventItemConfig}
                                    searchingForLocation={searchingForLocation}
                                    zoomInOnLocation={zoomInOnLocation}
                                    setSuggestionForLocation={setSuggestionForLocation}
                                    setViewSuggestionLodgingMarkers={setViewSuggestionLodgingMarkers}
                                    clearEvent={clearEvent}
                                    _global_map_ref={props._global_map_ref}
                                />
                            </div>
                        </Paper>
                    </div>

                </div>
            </div>

            <Snackbar
                open={weatherReportReceivedSuccessOpen}
                autoHideDuration={2500}
                onClose={handleSnackbarWeatherReportReceivedClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarWeatherReportReceivedClose} severity="success" sx={{ width: '100%' }}>
                    Received Weather Report
                </Alert>
            </Snackbar>


        </div>

    )




}

export default PlannerContent;
