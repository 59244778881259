import React from 'react';
import PAEvent from "../MasterEventObjects/PAEvent";
import PAAttendee from "../MasterEventObjects/Attendee";
import Location from "../MasterEventObjects/Location";
import Lodging from "../MasterEventObjects/Lodging";
import Activity from "../MasterEventObjects/Activity";
import Attendee from "../MasterEventObjects/Attendee";
import TimeTable from "../MasterEventObjects/TimeTable";

const SampleLibraryEvent1 = () => {

    const _newEvent = new PAEvent();

    _newEvent.id = "sdkhfksjh0482038";
    _newEvent.title = "Iceland Awaits";
    _newEvent.description = "A wonderful and beautiful 5 day sampling of Reykjavík";

    _newEvent.eventIntro = "Pack your bags--you've got a wonderful trip to Iceland ahead of you!\n" +
        "\n" +
        " \n" +
        "\n" +
        "In your Iceland itinerary, you won't find micro-managed agendas giving you hour-by-hour to-do lists. Instead, you'll receive a variety of hand-picked experiences curated to immerse you in the culture, surround you with the scenery, and connect you with the people of Iceland.\n" +
        "\n" +
        " \n" +
        "\n" +
        "As we discussed, we want these days to be loose without pre-scheduled activities. So consider the below fodder for daydreams or a delightful menu of options.\n" +
        "\n" +
        " \n" +
        "\n" +
        "We have listed classic activities that you can find in most guidebooks, local favorites that are often overlooked by the masses, and, finally, a few unique experiences that might take a little more time, effort, or investment, but provide a special memory few will get to experience.\n" +
        "\n" +
        " \n" +
        "\n" +
        "We hope you enjoy every moment of your time in Iceland.";

    _newEvent.coverImage = "/graphics/background-image-animation-css-example.png"

    //set the Budget
    _newEvent.budget_total = 2500;


    /*
        add the timetable
        for this it'll be just 6 days
     */
    _newEvent.time_table = new TimeTable();
    _newEvent.time_table.days = [
        {'day 1': 'Thursday', 'all-day':true, 'notes':''},
        {'day 2': 'Friday', 'all-day':true, 'notes':''},
        {'day 3': 'Saturday', 'all-day':true, 'notes':''},
        {'day 4': 'Sunday', 'all-day':true, 'notes':''},
        {'day 5': 'Monday', 'all-day':true, 'notes':''},
    ];

    /*
        add 4 random attendees
     */
    const _liam = new Attendee("Liam", "Harris", "liam.harris@example.com");
    const _james = new Attendee("James", "Allen", "james.allen@example.com");
    const _sophia = new Attendee("Sophia", "Young", "sophia.young@example.com");
    const _olivia = new Attendee("Olivia", "Wright", "olivia.wright@example.com");

    _james.id='100026'
    _james.role='admin'
    _james.address = "821 S Oregon Ave";
    _james.city = "Tampa";
    _james.state = "FL";
    _james.zip = "33606";
    _james.country = "USA";
    _james.currency = "USD";

    _olivia.id='100030'
    _olivia.address = "821 S Oregon Ave";
    _olivia.city = "Tampa";
    _olivia.state = "FL";
    _olivia.zip = "33606";
    _olivia.country = "USA";
    _olivia.currency = "USD";

    _liam.id='100020'
    _liam.address = "389 Rue des Palais Outre-Ponts";
    _liam.city = "Bruxelles";
    _liam.state = "Belgium";
    _liam.zip = "1020";
    _liam.country = "BE";
    _liam.currency = "EUR";

    _sophia.id='100027'
    _sophia.address = "389 Rue des Palais Outre-Ponts";
    _sophia.city = "Bruxelles";
    _sophia.state = "Belgium";
    _sophia.zip = "1020";
    _sophia.country = "BE";
    _sophia.currency = "EUR";

    _newEvent.attendees.push(_liam);
    _newEvent.attendees.push(_james);
    _newEvent.attendees.push(_sophia);
    _newEvent.attendees.push(_olivia);


    /*
        add just 1 lodging for the entire time
     */

    const _lodgingLocation = new Location();
    _lodgingLocation.google_place_id = "ChIJJwAAU8901kgRlbv1oUt42bI";
    _lodgingLocation.latitude = 64.14439209999999;
    _lodgingLocation.longitude = -21.9095837;
    _lodgingLocation.name = "Fosshotel Reykjavík";
    _lodgingLocation.formatted_address = "Mýrargata 2, 101 Reykjavík, Iceland";
    _lodgingLocation.address = "Mýrargata 2";
    _lodgingLocation.city = "Reykjavík";
    _lodgingLocation.state = "Iceland";
    _lodgingLocation.zip = "101";
    _lodgingLocation.country = "IS";

    _newEvent.locations.push(_lodgingLocation);

    const _lodging = new Lodging();
    _lodging.id = 1;
    _lodging.name = "Fosshotel Reykjavík";
    _lodging.begin_date = "";
    _lodging.end_date = "";
    _lodging.check_in_time = "3:00 PM";
    _lodging.check_out_time = "11:00 AM";
    _lodging.time_table = new TimeTable();
    _lodging.location = _lodgingLocation; // Assign the location instance
    _lodging.phone = "+354 444 4000";
    _lodging.booked_through = "Booking.com";
    _lodging.confirmation_number = "BK123456";
    _lodging.reservation_name = "Liam Harris";
    _lodging.reservation_number = "R123456";
    _lodging.gate_code = "1234";
    _lodging.door_code = "5678";
    _lodging.wifi_password = "Password123";
    _lodging.attendees = [
        {
            id: _liam.id,
            cost: 239,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _james.id,
            cost: 239,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",},
        {
            id: _sophia.id,
            cost: 239,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _olivia.id,
            cost: 239,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
    ];
    _lodging.amount_total = 956;
    _lodging.currency = "USD";
    _lodging.notes = "Special requests: Non-smoking room";

    _newEvent.lodging.push(_lodging);



    //add some activities
    const _ziplining = new Activity();
    _ziplining.title = "Ziplining";

    _ziplining.time_table = new TimeTable();

    const _zipliningLocation = new Location();
    _zipliningLocation.google_place_id = "ChIJIZgtzUBK10gRx5yzWjv7fNk";
    _zipliningLocation.latitude = 63.41907320000001;
    _zipliningLocation.longitude = -19.0078844;
    _zipliningLocation.name = "Zipline Iceland";
    _zipliningLocation.formatted_address = "1 Ránarbraut, 870 Vik, Iceland";
    _zipliningLocation.address = "1 Ránarbraut";
    _zipliningLocation.city = "Vik";
    _zipliningLocation.state = "Iceland";
    _zipliningLocation.zip = "870";
    _zipliningLocation.country = "IS";

    _ziplining.location = _zipliningLocation;
    _ziplining.phone = "+354 698 8890";
    _ziplining.confirmation_number = "ZIP-T47259329";
    _ziplining.url = "https://www.zipline.is";

    _ziplining.attendees = [
        {
            id: _liam.id,
            cost: 11900,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _james.id,
            cost: 11900,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _sophia.id,
            cost: 11900,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _olivia.id,
            cost: 11900,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
    ];

    _ziplining.amount_total = 47600;
    _ziplining.currency = "ISK";

    _ziplining.notes = "park at pool and walk";

    _newEvent.activities.push(_ziplining);

    const _whaleWatching = new Activity();
    _whaleWatching.title = "Whale Watching";

    _whaleWatching.time_table = new TimeTable();

    const _whaleWatchingLocation = new Location();
    _whaleWatchingLocation.google_place_id = "ChIJyaLoTRVCzUgRESZcNE744Go";
    _whaleWatchingLocation.latitude = 66.04630259999999;
    _whaleWatchingLocation.longitude = -17.3437356;
    _whaleWatchingLocation.name = "Gentle Giants – Whale Watching";
    _whaleWatchingLocation.formatted_address = "Garðarsbraut, 640 Húsavík, Iceland";
    _whaleWatchingLocation.address = "Garðarsbraut";
    _whaleWatchingLocation.city = "Húsavík";
    _whaleWatchingLocation.state = "Iceland";
    _whaleWatchingLocation.zip = "640";
    _whaleWatchingLocation.country = "IS";

    _whaleWatching.location = _whaleWatchingLocation;
    _whaleWatching.phone = "+354 464 1500";
    _whaleWatching.confirmation_number = "VIA-21408912";
    _whaleWatching.reservation_number = "BR-945937022";
    _whaleWatching.url = "https://www.gentlegiants.is";

    _whaleWatching.attendees = [
        {
            id: _liam.id,
            cost: 10990,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _james.id,
            cost: 10990,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _sophia.id,
            cost: 10990,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
        {
            id: _olivia.id,
            cost: 10990,
            is_business_expense: false,
            business_expense_amount_authorized: 0,
            business_expense_amount_authorized_by: "",
            business_expense_amount_authorized_timestamp: 0,
            business_expense_amount: 0,
            business_expense_paid: 0,
            business_expense_reference_id: "",
        },
    ];

    _whaleWatching.amount_total = 43960;
    _whaleWatching.currency = "ISK";

    _whaleWatching.notes = "it's a 30 min drive and arrive 30 min early";

    _newEvent.activities.push(_whaleWatching);


    //add some ideas

    //add some meals

    //add some flights

    //add some supplies


    //add some to-do items


    _newEvent.is_template = true;



    return _newEvent;
}

export default SampleLibraryEvent1;
