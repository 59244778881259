import React, {useEffect, useRef, useState} from 'react';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {Checkbox, Chip, ListItem, Tab, TextField} from "@mui/material";
import Grid from '@mui/material/Grid2';
import TabPanel from "@mui/lab/TabPanel";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {addChipStyle} from "../../ButtonStyle/Add";
import Box from "@mui/material/Box";





function MealLocationTab(props){

    const { set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventMeal, setEventMeal] = useState(props.eventMeal);
    useEffect(() => {
        setEventMeal(props.eventMeal);
    }, [props.eventMeal]);

    useEffect(() => {
        // Update only the specific meal in the Planning.meals array
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                meals: prev.Planning.meals.map((meal) =>
                    meal.id === eventMeal.id ? eventMeal : meal // Update the matching meal, keep others unchanged
                ),
            },
        }));
    }, [eventMeal]);



    const [selectedTab, setSelectedTab] = useState('list');
    const handleTabChange  = (event, newValue) => {
        console.log("handleTabChange ");
        setSelectedTab(newValue);
    };


    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [selectedMealLocation, setSelectedMealLocation] = useState(eventMeal.location || {});

    // Extract unique locations on component mount
    useEffect(() => {
        const meals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals || [];
        const lodging = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging || [];
        const activities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities || [];

        // Combine all locations
        const allLocations = [
            ...meals.map((meal) => meal.location),
            ...lodging.map((lodging) => lodging.location),
            ...activities.map((activity) => activity.location),
        ];

        // Filter unique locations by Google Place ID or another unique property
        const unique = Array.from(
            new Map(
                allLocations
                    .filter((location) => location.name) // Exclude locations without a name
                    .map((location) => [location.google_place_id, location])
            ).values()
        );

        // Sort the unique locations alphabetically by name
        const sortedUnique = unique.sort((a, b) => {
            const nameA = (a.name || "").toLowerCase();
            const nameB = (b.name || "").toLowerCase();
            return nameA.localeCompare(nameB);
        });

        // Separate the matching location from the rest
        const matchingLocation = sortedUnique.filter(
            (location) =>
                selectedMealLocation &&
                location.google_place_id === selectedMealLocation.google_place_id
        );
        const otherLocations = sortedUnique.filter(
            (location) =>
                !selectedMealLocation ||
                location.google_place_id !== selectedMealLocation.google_place_id
        );

        // Combine matching location(s) on top with the rest alphabetically sorted
        const finalLocationList = [...matchingLocation, ...otherLocations];

        setUniqueLocations(finalLocationList);
    }, [selectedMealLocation]);

    function handleLocationSelect(location) {
        console.log("location : ", location);
        setSelectedMealLocation(location);

        // Update the eventMeal with the selected location
        setEventMeal((prevMeal) => ({
            ...prevMeal,
            location,
        }));
    }

    const [needToSaveSettings, setNeedToSaveSettings] = useState(false);
    const [imaCookingDetails, setImaCookingDetails] = useState(eventMeal['eat_in_details']);

    // Store the initial values using useRef
    const initialValues = useRef({
        imaCookingDetails: eventMeal['eat_in_details'] || ""
    });
    useEffect(() => {
            const hasChanges =
                imaCookingDetails !== initialValues.current.imaCookingDetails;
            setNeedToSaveSettings(hasChanges);
        }, [imaCookingDetails]);

    function handleImaCookingDetails(e){
        setImaCookingDetails(e.target.value);
    }

    function saveEventMealImaCooking() {
        // Update the eventMeal object
        setEventMeal({
            ...eventMeal, // Spread the existing eventMeal properties
            eat_in_details: imaCookingDetails, // Update the eat_in_details property
        });
    }

    return (

        <Grid item style={{flex: '1'}}>

            <TabContext sx={{ position:'relative'}} value={selectedTab}>
                <TabList
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        backgroundColor: 'rgba(251, 251, 251, 0.8)',
                        backdropFilter: 'blur(6px)',
                    }}
                    centered
                    variant="fullWidth"
                    onChange={handleTabChange}
                    aria-label="Meal Location"
                >
                    <Tab
                        label="Locations"
                        value="list"
                    />
                    <Tab
                        label="I'm Cooking"
                        value="ima_cooking"
                    />
                </TabList>

                <TabPanel value="list" sx={{ padding: '24px 0' }}>
                    <List dense>
                        {uniqueLocations.map((location, index) => (
                            <ListItem
                                key={index}
                                sx={{ alignItems: "flex-start" }}
                                onClick={() => handleLocationSelect(location)}
                                button
                            >
                                <Checkbox
                                    checked={
                                        selectedMealLocation.google_place_id ===
                                        location.google_place_id
                                    }
                                    onChange={() => handleLocationSelect(location)}
                                    sx={{ padding: "0 8px" }}
                                />
                                <ListItemText
                                    primary={location.name || "Unnamed Location"}
                                    secondary={location.address || "No Address Available"}
                                />
                            </ListItem>
                        ))}
                    </List>
                </TabPanel>

                <TabPanel value="ima_cooking" sx={{ padding: '24px 0' }}>
                    <TextField
                        fullWidth
                        id="ima_cooking_details"
                        label="Meal Details"
                        multiline
                        rows={4}
                        value={imaCookingDetails}
                        onChange={handleImaCookingDetails}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                        {
                            needToSaveSettings ? (
                                <Chip
                                    sx={addChipStyle}
                                    label="Save"
                                    size="small"
                                    onClick={saveEventMealImaCooking}
                                />
                            ) : (
                                <Chip
                                    sx={{
                                        backgroundColor: "#e0e0e0",
                                        color: "#757575",
                                    }} // Example style for inactive save
                                    label="Save"
                                    size="small"
                                />
                            )
                        }

                    </Box>
                </TabPanel>

            </TabContext>
        </Grid>

    );

}

export default MealLocationTab;