import usePA_GlobalStore from "../../Services/ZustandGlobalStore";
import {
    Button,
    Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl,
    FormControlLabel, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Step, StepLabel, Stepper, TextField
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {PAContext} from "../../Services/PAContext";
import Typography from "@mui/material/Typography";
import {
    browserStorageAddTemplate,
    browserStorageUpdateSpecificEvent, browserStorageUpdateTemplate,
    getEventFromDatabase, getTemplateFromDatabase
} from "../../Services/BrowserDatabaseFunctions";
import Box from "@mui/material/Box";
import {GoogleLogin} from "@react-oauth/google";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {updateChipStyle} from "../ButtonStyle/Update";
import {createTemplateFromEvent} from "../Helpers/EventConverter";
import PAEvent from "../../MasterEventObjects/PAEvent";

const keyStr = "ABCDEFGHIJKLMNOP" +
    "QRSTUVWXYZabcdef" +
    "ghijklmnopqrstuv" +
    "wxyz0123456789+/" +
    "=";
function encode64(input) {
    input = escape(input);
    let output = "";
    let chr1, chr2, chr3 = "";
    let enc1, enc2, enc3, enc4 = "";
    let i = 0;

    do {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }

        output = output +
            keyStr.charAt(enc1) +
            keyStr.charAt(enc2) +
            keyStr.charAt(enc3) +
            keyStr.charAt(enc4);
        chr1 = chr2 = chr3 = "";
        enc1 = enc2 = enc3 = enc4 = "";
    } while (i < input.length);

    return output;
}


const steps = ['Email Address', 'Confirm Email', 'Create Password'];




function SavePlanning({isOpen, onClose}){

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, setLoginFailed,
        removeFirstConciergeSignupActions, removeFirstEventUpgradeActions } = useContext(PAContext);
    const { Account, SampleAttendees, authenticated, ws_connected, SignupPipeline, EventUpgradePipeline } = dataArray;

    const [saveAsTemplate, setSaveAsTemplate] = useState(PA_GlobalObject.Planning.template_id !== null);
    const [saveAsEvent, setSaveAsEvent] = useState(PA_GlobalObject.Planning.template_to_event.length > 0);


    function compareEvents(planningEvent, databaseEvent) {
        const updatedSections = {};

        if (!databaseEvent) {
            return { all: planningEvent }; // If no old event exists, consider everything new
        }

        // Iterate through the keys of the event
        for (const key in planningEvent) {
            console.log("looking for changes to : "+key);

            if (planningEvent.hasOwnProperty(key) && !deepCompare(planningEvent[key], databaseEvent[key])) {
                updatedSections[key] = planningEvent[key];
            }
        }

        return updatedSections;
    }
    function deepCompare(obj1, obj2) {
        // If both are the same reference or both are null/undefined, return true
        if (obj1 === obj2) return true;

        // If either is null or not an object, compare directly
        if (!obj1 || !obj2 || typeof obj1 !== "object" || typeof obj2 !== "object") {
            return obj1 === obj2;
        }

        // If they are arrays, compare lengths and each item
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) return false;
            return obj1.every((item, index) => deepCompare(item, obj2[index]));
        }

        // If obj2 is null or undefined, return false before trying Object.keys()
        if (!obj2 || typeof obj2 !== "object") return false;

        // If they are objects, compare keys and values
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        return keys1.every(key => deepCompare(obj1[key], obj2[key]));
    }

    async function savePlanning(){
        console.log("savePlanning : ", PA_GlobalObject.Planning);
        //disable the SAVE button


        //if the Planning is an event we look in the browser database pa_data.Events
        //otherwise we look in the pa_data.Templates

        if (!PA_GlobalObject.Planning.is_template && PA_GlobalObject.Planning.id !== PA_GlobalObject.Planning.temp_id){
            console.log("this is an Event we are saving");

            const _existing_planning = new PAEvent(PA_GlobalObject.Planning);

            //if they want to make a Template from the Event
            //see if a Template has already been made from this Event
            if (saveAsTemplate){

                const convertedEventToTemplate = createTemplateFromEvent(_existing_planning, SampleAttendees);

                //assign the template_id to the event if there wasn't one already
                if (_existing_planning.template_id === '') {
                    _existing_planning.template_id = convertedEventToTemplate.template_id;
                }

                browserStorageUpdateTemplate(convertedEventToTemplate).then((success) => {
                    if (success){
                        //the update was successful
                    } else {
                        //problems updating the Template

                    }
                })

            }



            const databaseEvent = await getEventFromDatabase(_existing_planning.id);

            console.log("databaseEvent from browser database : ", databaseEvent);

            if (databaseEvent){
                console.log("Checking for changes between the Planning version and the browser database version");

                // Perform the comparison
                const changes = compareEvents(_existing_planning, databaseEvent);

                if (Object.keys(changes).length > 0) {
                    console.log("There are changes : ",changes);

                    //update the browser database
                    console.log("Updating the Event in the browser storage ...");
                    browserStorageUpdateSpecificEvent(_existing_planning).then(success => {

                        console.log("Update success : "+success);

                        if (success){
                            console.log("Connected to server : ", dataArray.Websocket);
                            if (dataArray.Websocket){
                                console.log("Connected to server!");
                                const _sendString = {};
                                _sendString.action = "E_setEventAllChanges";
                                _sendString.event_id = usePA_GlobalStore.getState().PA_GlobalObject.Planning.id;
                                _sendString.event_exists = true;
                                _sendString.updates = changes;

                                console.log("Sending to server (not really): ", _sendString);

//                            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                            }
                        } else {
                            //there were problems updating the Event in the database
                            console.log("Problems updating the browser database");
                        }
                    });

                } else {
                    console.log("There are NO changes with what we have in the browser database");

                    console.log("Connected to server : ", dataArray.Websocket);

                    if (dataArray.Websocket){
                        console.log("Connected to server!");

                        const _sendString = {};
                        _sendString.action = "updateEventUsingNewVersion";
                        _sendString.event_id = _existing_planning.id;
                        _sendString.event_exists = false;
                        _sendString.event = _existing_planning;

                        console.log("Sending to server (not really): ", _sendString);

//                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                    }

                }

            } else {
                //this Event doesn't exist so we need to add it
                console.log("The Event wasn't found in the browser database");

                if (dataArray.Websocket){
                    console.log("Connected to server!");

                    const _sendString = {};
                    _sendString.action = "addEventUsingNewVersion";
                    _sendString.event_id = _existing_planning.id;
                    _sendString.event_exists = false;
                    _sendString.planning = _existing_planning;

                    console.log("Sending to server (not really): ", _sendString);

//                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                }
            }

            set_PA_GlobalObject({ Planning: _existing_planning });

        } else if (PA_GlobalObject.Planning.is_template && PA_GlobalObject.Planning.temp_id) {
            console.log("this is a Template we are saving");

            //see if template exists
            const databaseTemplate = await getTemplateFromDatabase(usePA_GlobalStore.getState().PA_GlobalObject.Planning.template_id);

            if (databaseTemplate){
                console.log("found template", databaseTemplate);
                console.log("updating with ", PA_GlobalObject.Planning);

                //update the Template
                browserStorageUpdateTemplate(PA_GlobalObject.Planning).then(updatedTemplates => {
                    console.log("Updated Template:", updatedTemplates);



                });

            } else {
                console.log("template not found, need to add");
                browserStorageAddTemplate(PA_GlobalObject.Planning).then(addedTemplate => {
                    console.log("Added Template:", addedTemplate);

                    if (dataArray.Websocket){
                        console.log("Connected to server!");

                        const _sendString = {};
                        _sendString.action = "addTemplateUsingNewVersion";
                        _sendString.event_id = usePA_GlobalStore.getState().PA_GlobalObject.Planning.id;
                        _sendString.event_exists = false;
                        _sendString.planning = usePA_GlobalStore.getState().PA_GlobalObject.Planning;

                        console.log("Sending to server (not really): ", _sendString);

//                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                    }
                });
            }

            //now if they want to save it as an Event we need to show them the pricing

        }

    }

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [usernameErrorStatus, setUsernameErrorStatus] = useState(false);
    const [passwordErrorStatus, setPasswordErrorStatus] = useState(false);


    const handleUsernameInputChange = (event) => {
        const inputValue = event.target.value;
        setUsername(inputValue);
        if (inputValue.length > 0){
            setUsernameErrorStatus(false);
            setLoginFailed(false);
        }
    }
    const handlePasswordInputChange = (event) => {
        const inputValue = event.target.value;
        setPassword(inputValue);
        if (inputValue.length > 0){
            setPasswordErrorStatus(false);
            setLoginFailed(false);
        }
    }

    const handleOk = () => {
        console.log("handleOk");

        //check input values
        if (username !== "" && password !== ""){

            console.log("dataArray.ws_connected : "+dataArray.ws_connected);

            if (dataArray.ws_connected){

                const _sendString = {};
                _sendString.action = "E_processUserLogin";
                _sendString.username = username;
                _sendString.password = encode64(password);

                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            } else {
                //websocket not available

            }

        } else {
            //something wrong with username or password
            username === "" ? setUsernameErrorStatus(true):setUsernameErrorStatus(false)
            password === "" ? setPasswordErrorStatus(true):setPasswordErrorStatus(false)
        }

    };
    const [loginDetails, setLoginDetails] = useState({ username: "", password: "" });
    const [email, setEmail] = useState("");

    const [blinking, setBlinking] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [emailUpdated, setEmailUpdated] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeMatches, setEmailVerificationMatches] = useState(false);
    const [verificationCodeError, setEmailVerificationError] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false);


    useEffect(() => {
        //handle the incoming action
        SignupPipeline.forEach(_incoming => {
            console.log('_incoming:', JSON.stringify(_incoming, null, '\t'));

            // Perform operations on the current action
            if (_incoming.result === "ok"){
                if (_incoming.action === "signupConciergeGetWelcomeMessageResults"){
                    //just landed on the page
//                    printConciergeMessageNextCharacter(_incoming.message);
                } else if (_incoming.action === "signupConciergeVerifyEmailAddressResults"){
                    //just sent them the email verification code
                    setEmailVerificationSent(true);
//                    printConciergeMessageNextCharacter(_incoming.message);
                } else if (_incoming.action === "signupConciergeVerifyVerificationCodeResults"){
                    //just checked the verification code
                    setEmailVerificationMatches(true);
                    //ready to get passwords
                    setActiveStep(2);
//                    printConciergeMessageNextCharacter(_incoming.message);
                } else if (_incoming.action === "signupConciergeCheckUsernameAndPasswordResults"){
                    //just checked the login info - they can login now
                    setAccountCreated(true);
//                    printConciergeMessageNextCharacter(_incoming.message);
                }
            } else {
                //an error to show
//                printConciergeMessageNextCharacter(_incoming.message);

                if (_incoming.action === "signupConciergeVerifyEmailAddressResults"){
                    //something was wrong with the email address
                    setEmailVerificationSent(false);
                    setEmailError(true);
                    setActiveStep(0);
                } else if (_incoming.action === "signupConciergeVerifyVerificationCodeResults"){
                    //the email verification code doesn't match
                    setEmailVerificationError(true);
                    setEmailVerificationMatches(false);
                    setActiveStep(1);
                } else if (_incoming.action === "signupConciergeCheckUsernameAndPasswordResults"){
                    //problems with the passwords
                    setAccountCreated(false);
                    setActiveStep(2);
                }

            }
            removeFirstConciergeSignupActions();
        });

    }, [SignupPipeline])

    const handleNext = () => {
        steps[0] = email;

        if (activeStep === 0){
            if (validateEmail()){
                setEmailError(false);
                setEmailUpdated(email);

                const _send_string = {};
                _send_string.action = "signupConciergeVerifyEmailAddress";
                _send_string.email_address = email;

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");

                setActiveStep((activeStep) => activeStep + 1);

            } else {
                //need to fix something about the email address
                setEmailError(true);

            }

        } else if (activeStep === 1){
            //check verification code
            if (verificationCode.length === 6){
                setEmailVerificationError(false);
                const _send_string = {};
                _send_string.action = "signupConciergeVerifyVerificationCode";
                _send_string.email_address = email;
                _send_string.verification_code = verificationCode;

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
            } else {
                //to short
                setEmailVerificationError(true);
            }

        } else if (activeStep === 2){
            //check passwords match

            if (password === passwordConfirm){

                const _send_string = {};
                _send_string.action = "signupConciergeCheckUsernameAndPassword";
                _send_string.email_address = email;
                _send_string.username = "";
                _send_string.password = btoa(password);
                _send_string.password_confirm = btoa(passwordConfirm);

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");
            } else {
                setPasswordError(true);
            }

        }


    }
    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
        activeStep - 1 > 0 ? steps[0] = email : steps[0] = 'Email Address';
    }

    function handleEmailChange(event){
        setEmail(event.target.value);
    }
    function handleVerificationCodeChange(event){
        setVerificationCode(event.target.value);
    }
    function handlePasswordChange(event){
        setPassword(event.target.value);
    }
    function handlePasswordConfirmChange(event){
        setPasswordConfirm(event.target.value);
    }

    function validateEmail() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const isStepFailed = (step) => {
        if (step === 0){
            return emailError;
        } else if (step === 1){
            return verificationCodeError;
        } else if (step === 2){
            return passwordError;
        }
    };

    const [eventPriceGroups, setEventPriceGroups] = useState([]);
    const [promoCode, setPromoCode] = useState('');
    const [isPromoCodeChipDisabled, setIsPromoCodeChipDisabled] = useState(false);
    const [promoCodeMessage, setPromoCodeMessage] = useState('');
    const [discountAmount, setDiscountAmount] = useState(0.00);
    const [discountUnits, setDiscountUnits] = useState(null);

    useEffect(() => {
        EventUpgradePipeline.forEach(_incoming => {
            console.log('_incoming:', JSON.stringify(_incoming, null, '\t'));

            // Perform operations on the current action
            if (_incoming.result === "ok") {
                if (_incoming.action === "E_checkNewEventPlanPromoCodeResults") {
                    if (_incoming.valid){
                        setDiscountAmount(_incoming.amount);
                        setDiscountUnits(_incoming.units);
                    } else {
                        setDiscountAmount(0); // Reset discount
                        setDiscountUnits(null);
                    }
                    setPromoCodeMessage(_incoming.admin_notes);

                    setIsPromoCodeChipDisabled(false);
                }
            } else {
                //problems with whatever the incoming action is


            }
            removeFirstEventUpgradeActions()
        });
    }, [EventUpgradePipeline]);
    const handlePromoCodeChange = (e) => {
        setPromoCode(e.target.value);
        setPromoCodeMessage("");
        setIsPromoCodeChipDisabled(false);
    };
    function handleApplyPromoCode(){

        if (!promoCode.trim()) {
            setPromoCodeMessage("Please enter a valid promo code.");
            return;
        }

        setIsPromoCodeChipDisabled(true);

        //check the promo code
        const _sendString = {};
        _sendString.action = "E_checkNewEventPlanPromoCode";
        _sendString.promo_code = promoCode;

        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

    }
    function handlePayment(){

    }

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            {/* Dialog Title */}
            <DialogTitle
                sx={{
                    color: '#151269',
                    textAlign: 'center',
                    backgroundColor: 'rgba(198, 226, 255, 0.8)',
                    paddingRight: '30px'
                }}
            >
                Save this {PA_GlobalObject.Planning.is_template? 'Template' : 'Event'} : {PA_GlobalObject.Planning.title}
            </DialogTitle>
            {/* Close Button */}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#151269',
                }}
            >
                <i className="icon-cross" style={{ fontSize: '18px', padding: '5px' }} />
            </IconButton>

            {/* Dialog Content */}
            <DialogContent>
                {
                    !authenticated || Account.id === undefined ? (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Before saving, you must be logged in.
                            </Typography>
                            <Box display="flex" justifyContent="space-between" gap={2}>
                                {/* Sign-In Section */}
                                <Box p={2} border={1} borderRadius={2} borderColor="grey.300" flex={1}>
                                    <Typography variant="h6">Sign In</Typography>

                                    <GoogleLogin
                                        shape="pill"
                                        onSuccess={credentialResponse => {
                                            console.log(credentialResponse.credential);

                                            setLoginFailed(false);

                                            //send this to the server for processing
                                            const _sendString = {};
                                            _sendString.action = "E_processUserLoginUsingGoogleJWT";
                                            _sendString.jwt = credentialResponse;

                                            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />

                                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                        <Divider>
                                            <Chip label="OR"/>
                                        </Divider>
                                    </div>

                                    <FormControl sx={{m: 1}} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-username"
                                            variant="standard"
                                            type="text"
                                            label="Username"
                                            onChange={handleUsernameInputChange}
                                            value={username}
                                            error={usernameErrorStatus}
                                        />
                                        {usernameErrorStatus && (
                                            <FormHelperText sx={{color: "red"}}>* required</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl sx={{m: 1}} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            variant="standard"
                                            type={showPassword ? 'text' : 'password'}
                                            label="Password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={handlePasswordInputChange}
                                            value={password}
                                            error={passwordErrorStatus}
                                        />
                                        {passwordErrorStatus && (
                                            <FormHelperText sx={{color: "red"}}>* required</FormHelperText>
                                        )}
                                    </FormControl>

                                    <DialogActions>
                                        <Button onClick={handleOk}>Ok</Button>
                                    </DialogActions>
                                </Box>

                                {/* Sign-Up Section */}
                                <Box p={2} border={1} borderRadius={2} borderColor="grey.300" flex={1}>
                                    <Typography variant="h6">Sign Up</Typography>

                                    <GoogleLogin
                                        text="signup_with"
                                        shape="pill"
                                        onSuccess={credentialResponse => {
                                            console.log(credentialResponse.credential);

                                            //send this to the server for processing
                                            const _sendString = {};
                                            _sendString.action = "E_processUserLoginUsingGoogleJWT";
                                            _sendString.jwt = credentialResponse;

                                            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                                        }}
                                        onError={() => {
                                            console.log('Login Failed or exited');
                                        }}
                                    />

                                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                        <Divider>
                                            <Chip label="OR"/>
                                        </Divider>
                                    </div>

                                    <Stepper
                                        activeStep={activeStep}
                                        alternativeLabel
                                    >

                                        {steps.map((label, index) => {

                                            const labelProps = {};
                                            if (isStepFailed(index)) {
                                                labelProps.optional = (
                                                    <Typography variant="caption" color="error">
                                                        {index === 0 ? 'Invalid Email' : index === 1 ? 'Invalid Code' : index === 2 ? "Passwords Don't match" : ''}
                                                    </Typography>
                                                );
                                                labelProps.error = true;
                                            }

                                            return (
                                                <Step key={label}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            )
                                        })
                                        }

                                    </Stepper>

                                    {/* this is the STEP content area */}
                                    {
                                        activeStep === 0 ? (
                                            <div
                                                className="signup-concierge-input-area-content-holder"
                                            >
                                                <FormControl sx={{m: 1}} variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-email"
                                                        variant="standard"
                                                        type="email"
                                                        label="Email"
                                                        onChange={handleEmailChange}
                                                        value={email}
                                                        error={usernameErrorStatus}
                                                    />
                                                    {usernameErrorStatus && (
                                                        <FormHelperText sx={{color: "red"}}>* required</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                        ) : (
                                            activeStep === 1 ? (
                                                <div
                                                    className="signup-concierge-input-area-content-holder"
                                                >
                                                    <FormControl sx={{m: 1}} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-verification">Verification Code</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-verification"
                                                            variant="standard"
                                                            type="text"
                                                            label="Verification Code"
                                                            onChange={handleVerificationCodeChange}
                                                            value={verificationCode}
                                                            error={usernameErrorStatus}
                                                        />
                                                        {usernameErrorStatus && (
                                                            <FormHelperText sx={{color: "red"}}>* required</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </div>
                                            ) : (
                                                <div
                                                    className="signup-concierge-input-area-content-holder"
                                                >
                                                    <FormControl sx={{m: 1}} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            variant="standard"
                                                            type={showPassword ? 'text' : 'password'}
                                                            label="Password"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            onChange={handlePasswordChange}
                                                            value={password}
                                                            error={passwordErrorStatus}
                                                        />
                                                        {passwordErrorStatus && (
                                                            <FormHelperText sx={{color: "red"}}>* required</FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl sx={{m: 1}} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-password-confirm">Confirm Password</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-password-confirm"
                                                            variant="standard"
                                                            type={showPasswordConfirm ? 'text' : 'password'}
                                                            label="Confirm Password"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPasswordConfirm}
                                                                        edge="end"
                                                                    >
                                                                        {showPasswordConfirm ? <VisibilityOff/> : <Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            onChange={handlePasswordChange}
                                                            value={passwordConfirm}
                                                            error={passwordErrorStatus}
                                                        />
                                                        {passwordErrorStatus && (
                                                            <FormHelperText sx={{color: "red"}}>* required</FormHelperText>
                                                        )}
                                                    </FormControl>

                                                </div>
                                            )
                                        )
                                    }


                                    {/* this is the bottom row for buttons */}
                                    {
                                        activeStep === steps.length ? (
                                            <>
                                                <Typography sx={{mt: 2, mb: 1}}>
                                                    All steps completed - you're finished
                                                </Typography>
                                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                                    <Box sx={{flex: '1 1 auto'}}/>
                                                    <Button onClick={handleBack}>Reset</Button>
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                                    <Button
                                                        color="inherit"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{mr: 1}}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Box sx={{flex: '1 1 auto'}}/>
                                                    <Button onClick={handleNext}>
                                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                    </Button>
                                                </Box>
                                            </>
                                        )

                                    }
                                </Box>
                            </Box>
                        </>

                    ) : (
                        PA_GlobalObject.Planning.is_template ? (
                            <>
                                <Typography variant="body1" style={{margin: "0 0 25px 0"}}>
                                This will update the database with your latest changes to this Template.
                                </Typography>
                                <DialogActions>
                                    {
                                        authenticated && Account.id !== undefined ? (
                                            <Button onClick={savePlanning} variant="contained" color="primary">Save</Button>
                                        ) : null
                                    }
                                </DialogActions>

                                <Divider sx={{marginBottom: '20px'}} />

                                <Box display="flex" justifyContent="space-between" gap={2}>
                                    {/* purchase prompt Section */}
                                    <Box p={2} flex={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={saveAsEvent}
                                                    onChange={(e) => setSaveAsEvent(e.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            label={<Typography variant="body1" color="textSecondary">Save as an Event for only $4.99</Typography>}
                                        />

                                        {/* Promo Code Input Section */}
                                        <Box alignItems="center" gap={1} marginTop={2}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" marginBottom={1}>
                                                <Typography variant="body2">Enter Promo Code:</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    placeholder="Promo Code"
                                                    value={promoCode}
                                                    onChange={handlePromoCodeChange}
                                                />
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" width="100%" marginBottom={1}>
                                                <Typography
                                                    variant="body1"
                                                    style={{ color: discountAmount > 0 ? "green" : "red" }}
                                                >
                                                    {promoCodeMessage}
                                                </Typography>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    {isPromoCodeChipDisabled && (
                                                        <i className="icon-cw"
                                                           style={{
                                                               fontSize: "10px",
                                                               color: "#5E9F3D",
                                                               animation: "spin 1s linear infinite",
                                                                padding: '7px 0'
                                                            }} />
                                                    )}
                                                    <Chip
                                                        sx={updateChipStyle}
                                                        className="details-list-item-update-name-chip"
                                                        label="Apply"
                                                        size="small"
                                                        onClick={handleApplyPromoCode}
                                                        disabled={isPromoCodeChipDisabled}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Typography variant="body2" color="textSecondary" style={{ marginTop: "25px" }}>
                                            This will create a new Event using all of the data you have included in this Template.
                                        </Typography>
                                        {
                                            PA_GlobalObject.Planning.attendees.length > 0 && (
                                                <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: "5px" }}>
                                                    !! Important: {PA_GlobalObject.Planning.attendees.length === 1
                                                    ? "There is 1 attendee "
                                                    : `There are ${PA_GlobalObject.Planning.attendees.length} attendees `}
                                                    that are from a Sample list and cannot be used in an actual Event. Any attendees will be
                                                    removed from the new Event and you can add Attendees from your Contact list.
                                                </Typography>

                                            )
                                        }
                                    </Box>

                                    <Divider orientation="vertical" flexItem />

                                    {/* Summary and payment Section */}
                                    <Box p={2} flex={1} display="flex" flexDirection="column">
                                        <Typography variant="h6" gutterBottom>
                                            Order Summary
                                        </Typography>

                                        {/* Base Price */}
                                        <Box display="flex" justifyContent="space-between" width="100%" marginBottom={1}>
                                            <Typography variant="body1">Base Price:</Typography>
                                            <Typography variant="body1">{discountAmount} {discountUnits}</Typography>
                                        </Box>

                                        {/* Discount (If Promo Code Applied) */}
                                        {discountAmount > 0 && (
                                            <Box display="flex" justifyContent="space-between" width="100%" marginBottom={1}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Discount ({promoCode}):
                                                </Typography>
                                                <Typography variant="body1" color="error">
                                                    {discountUnits === "percent"
                                                        ? `- ${(4.99 * discountAmount / 100).toFixed(2)}`
                                                        : `- $${discountAmount.toFixed(2)}`
                                                    }
                                                </Typography>
                                            </Box>
                                        )}

                                        <Divider width="100%" />

                                        {/* Total Price */}
                                        <Box display="flex" justifyContent="space-between" width="100%" marginTop={1}>
                                            <Typography variant="h6">Total:</Typography>
                                            <Typography variant="h6">
                                                ${(4.99 - discountAmount).toFixed(2)}
                                            </Typography>
                                        </Box>

                                        {/* Payment Button */}
                                        <Button
                                            variant="contained"
                                            color="success"
                                            size="small"
                                            sx={{ marginTop: 2, alignSelf: "flex-end" }}
                                            onClick={handlePayment}
                                        >
                                            Proceed to Payment
                                        </Button>
                                    </Box>
                                </Box>

                            </>
                        ) : (
                            <>
                                <Typography variant="body1" color="textSecondary" style={{ margin: "0 0 25px 0" }}>
                                    This will update the database with your latest changes.
                                </Typography>
                                <DialogActions>
                                    {
                                        authenticated && Account.id !== undefined ? (
                                            <Button onClick={savePlanning} variant="contained" color="primary">Save</Button>
                                        ) : null
                                    }
                                </DialogActions>
                                <Divider />
                                {
                                    PA_GlobalObject.Planning.template_id === null ? (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={saveAsTemplate}
                                                        onChange={(e) => setSaveAsTemplate(e.target.checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label={<Typography variant="body1" color="textSecondary">Create a Template using this Event</Typography>}
                                            />
                                            <Typography variant="body2" color="textSecondary" style={{ marginTop: "5px" }}>
                                                This will create a new Template from this Event.
                                            </Typography>
                                            {
                                                PA_GlobalObject.Planning['time_table'].dates.length > 0 && (
                                                    <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: "5px" }}>
                                                        !! Important: There are dates for this Event. Those dates will be removed from the Template but the days will remain.
                                                        You can continue to edit the Template and when dates are confirmed you can add them and save the Template
                                                        as an Event.
                                                    </Typography>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={saveAsTemplate}
                                                        onChange={(e) => setSaveAsTemplate(e.target.checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label={<Typography variant="body1">Update Template</Typography>}
                                            />
                                            <Typography variant="body2" color="textSecondary" style={{ marginTop: "5px" }}>
                                                This will create a new Template from this Event.
                                            </Typography>
                                            {
                                                PA_GlobalObject.Planning['time_table'].dates.length > 0 && (
                                                    <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: "5px" }}>
                                                        !! Important: There are dates for this Event. Those dates will be removed from the Template but the days will remain.
                                                        You can continue to edit the Template and when dates are confirmed you can add them and save the Template
                                                        as an Event.
                                                    </Typography>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </>
                        )
                    )
                }
            </DialogContent>


            {/* Dialog Actions */}
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SavePlanning;
