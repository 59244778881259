import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {
    Button, Card, CardContent, CardMedia,
    Chip,
    Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    Menu,
    MenuItem,
    MenuList, Paper,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import MealCard from "../Meals/MealCard";
import Typography from "@mui/material/Typography";
import './meals.css';
import Box from "@mui/material/Box";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import InfoMeals from "../../Dialogs/InfoMeals";

function MealsList(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventMeals, setEventMeals] = useState([]);
    const [filteredMeals, setFilteredMeals] = useState([]);

    const [viewType, setViewType] = useState(
        usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0 ?
            'dates'
            : usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0 ?
                'days' : 'dates'); // Either 'dates' or 'days'
    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);
    const [selectedDate, setSelectedDate] = useState("all"); // For selected date
    const [selectedDay, setSelectedDay] = useState("all"); // For selected day

    // Toggle visibility of dates or days chips
    const handleToggleViewType = () => {
        console.log("handleToggleViewType : " + viewType);

        const planning = usePA_GlobalStore.getState().PA_GlobalObject.Planning;
        const eventDays = planning.time_table?.days || [];
        const eventDates = planning.time_table?.dates || [];

        if (viewType === 'dates') {
            console.log("Changing Chips to days...");

            if (selectedDate === 'all') {
                setSelectedDay('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding day for the selected date
                const correspondingDay = eventDates.find(dateEntry => dateEntry.date === selectedDate);
                if (correspondingDay) {
                    const dayIndex = eventDates.indexOf(correspondingDay);
                    const correspondingDayKey = Object.keys(eventDays[dayIndex] || {})[0] || 'all';
                    setSelectedDay(correspondingDayKey);
                } else {
                    setSelectedDay('all'); // Fallback
                }
            }

            setViewType('days');

        } else if (viewType === 'days') {
            console.log("Changing Chips to dates...");

            if (selectedDay === 'all') {
                setSelectedDate('all'); // Show all meals if "all" was selected
            } else {
                // Find the corresponding date for the selected day
                const correspondingDayEntry = eventDays.find(dayEntry => Object.keys(dayEntry)[0] === selectedDay);
                if (correspondingDayEntry) {
                    const dayIndex = eventDays.indexOf(correspondingDayEntry);
                    const correspondingDate = eventDates[dayIndex]?.date || 'all';
                    setSelectedDate(correspondingDate);
                } else {
                    setSelectedDate('all'); // Fallback
                }
            }

            setViewType('dates');
        }
    };



    const [anchorMealsListMenu, setAnchorMealsListMenu] = useState(null);
    const handleMealsListMenuOpenMenu = (event) => {
        setAnchorMealsListMenu(event.currentTarget);
    };
    const handleMealsListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorMealsListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Meals/AddNew');
        }
    };
    const handleMealsListMenuClose = () => {
        setAnchorMealsListMenu(null);
    };
    function handlePromptChangeClose() {
        setAnchorMealsListMenu(null);
        props.setShowEventItemConfig(false);
    }

    useEffect(() => {
        console.log("PA_GlobalObject.Planning.meals triggered again: ", PA_GlobalObject.Planning.meals);

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals !== eventMeals){
            setEventMeals([...usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals]);
            setEventHasDates(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
            setEventHasDays(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);

            if (viewType !== 'all'){
//                setViewType('all');
            }
        }

    }, [PA_GlobalObject.Planning.meals]);

    useEffect(() => {
        console.log("eventMeals triggered again: ", eventMeals);

        if ((viewType === 'dates' && selectedDate === 'all') || (viewType === 'days' && selectedDay === 'all')){
            handleMealChipAllClick();
        }

    }, [eventMeals]);


    function handleMealChipAllClick(){
        console.log("handleMealChipAllClick");

        let filtered = [...eventMeals];

        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date
                || (Array.isArray(a.time_table.days) && a.time_table.days[0]
                    ? dayjs().day(Object.keys(a.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
            const dateB = b.time_table.dates?.[0]?.date
                || (Array.isArray(b.time_table.days) && b.time_table.days[0]
                    ? dayjs().day(Object.keys(b.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
//            const dateA = a.time_table.dates?.[0]?.date || dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
//            const dateB = b.time_table.dates?.[0]?.date || dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        setFilteredMeals(filtered);
    }
    function handleMealChipDateClick(_meal_date){
        console.log("handleMealChipDateClick : "+_meal_date);

        if (viewType !== "dates"){
            setViewType("dates");
        }
        setSelectedDate(_meal_date);

        let filtered = [];
        filtered = eventMeals.filter(meal =>
            meal.time_table?.dates?.length > 0 && meal.time_table.dates[0].date === _meal_date
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date;
            const dateB = b.time_table.dates?.[0]?.date;

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        console.log("filtered : ", filtered);

        setFilteredMeals(filtered);
    }
    function handleMealChipDayClick(_meal_day){
        console.log("handleMealChipDayClick : "+_meal_day);

        if (viewType !== "days"){
            setViewType("days");
        }
        setSelectedDay(_meal_day);

        let filtered = [];
        filtered = eventMeals.filter(meal =>
            meal.time_table?.days?.length > 0 && Object.keys(meal.time_table.days[0])[0] === _meal_day
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
            const dateB = dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });
        console.log("filtered : "+JSON.stringify(filtered, null, '\t'));

        setFilteredMeals(filtered);
    }

    const updateMealDetails = (updatedMeal) => {
        // Find the index of the updated Meal in eventMeals
        const mealIndex = eventMeals.findIndex(
            (meal) => meal.id === updatedMeal.id || meal.temp_id === updatedMeal.temp_id
        );

        console.log("mealIndex to update : "+mealIndex);

        if (mealIndex !== -1) {
            // If the meal is found, update it in the eventMeals array
            const updatedEventMealsArray = [...eventMeals];
            updatedEventMealsArray[mealIndex] = updatedMeal;

            //this updates the meal at the Global level
            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    meals: updatedEventMealsArray, // Update only the title
                },
            }));

            console.log("updatedEventMeals");
        }

    };

    const [mealToDelete, setMealToDelete] = useState(null);  // Holds the meal to delete for confirmation dialog
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);  // Controls the confirmation dialog visibility
    const handleDeleteMeal = (mealId) => {
        // Remove the meal with the given ID
        const updatedMeals = eventMeals.filter(meal => meal.id !== mealId);

        // You can call a function to persist this change or update parent state
        setEventMeals(updatedMeals);


        //this updates the meals array at the Global level
        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                meals: updatedMeals, // Update only the title
            },
        }));

        closeDeleteDialog();

    };
    const openDeleteDialog = (meal) => {
        setMealToDelete(meal);  // Set the meal to be deleted
        setDeleteDialogOpen(true);  // Open the confirmation dialog
    };
    const closeDeleteDialog = () => {
        setMealToDelete(null);  // Reset the meal to be deleted
        setDeleteDialogOpen(false);  // Close the confirmation dialog
    };

    const [isMealsInfoDialogOpen, setMealsInfoDialogOpen] = useState(false);


    return (

        <div className="meals-list-container">

            {/* show the header */}
            <div className="meals-list-header">
                <div className="meals-list-header-text">
                    <h5>Meals</h5>
                </div>
                <div className="meals-list-header-info-icon-holder">
                    <i
                        className="icon-info-circle"
                        style={{fontSize: '20px', padding: '5px'}}
                        onClick={() => setMealsInfoDialogOpen(!isMealsInfoDialogOpen)}
                    />
                </div>
                <div className="meals-list-header-controls">
                    {/* Toggle for Dates/Days */}
                    <Box sx={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                        {/* Icon Button for Dates */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'dates' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to dates"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Dates</Typography>
                        </IconButton>

                        {/* Divider between buttons */}
                        <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>

                        {/* Icon Button for Days */}
                        <IconButton
                            type="button"
                            sx={{
                                padding: '3px',
                                backgroundColor: viewType === 'days' ? '#FFF' : '',
                                boxShadow: '' +
                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                border: '1px solid #0096ff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                color: 'black', // Text color (adjust as needed)
                                '&:hover': {
                                    background: '#cbe0f7', // Reverse the gradient on hover
                                },
                            }}
                            aria-label="toggle to days"
                            onClick={handleToggleViewType}
                        >
                            <Typography variant="caption">Days</Typography>
                        </IconButton>
                    </Box>

                    {/* Menu Chip */}
                    <Chip
                        sx={menuChipStyle}
                        label={<i className="icon-menu2" style={{fontSize: '16px'}}/>}
                        size="small"
                        onClick={handleMealsListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorMealsListMenu}
                        id="library-menu"
                        open={Boolean(anchorMealsListMenu)}
                        onClose={handleMealsListMenuClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                            <MenuItem data-id="new-entry" onClick={handleMealsListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handlePromptChangeClose}>
                                <ListItemText>Close</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of meals for dates/days */}
            <div className="meals-list-timetable-list-container">
                {/* "All" Chip to show all meals */}
                {
                    eventHasDates || eventHasDays ? (
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-block',
                                marginTop: '5px',
                                marginRight: '10px',
                            }}
                        >
                            <Chip
                                label="All"
                                sx={selectedDate === 'all' ? selectedChipStyle : unselectedChipStyle}
                                style={{ margin: '0.2rem', height: '40px' }}
                                onClick={handleMealChipAllClick}

                            />
                        </Box>
                    ) : (
                        <></>
                    )
                }


                {
                    viewType === 'dates' && eventHasDates ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'].dates.map((eventDateObj) => {
                            const eventDate = eventDateObj.date;
                            const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd');
                            const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D');

                            // Filter meals for this specific date
                            const mealsForDate = eventMeals.filter(meal =>
                                meal.time_table?.dates?.length > 0 && meal.time_table.dates[0].date === eventDate
                            );
                            const mealCount = mealsForDate.length;

                            return (
                                <Box
                                    key={`meal-${eventDate}`}
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: '5px',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Chip
                                        label={
                                            <div style={{textAlign: 'center'}}>
                                                <Typography variant="body2">{formattedDay}</Typography>
                                                <Typography variant="caption">{formattedDate}</Typography>
                                            </div>
                                        }
                                        sx={viewType === 'dates' && selectedDate === eventDate ? selectedChipStyle : unselectedChipStyle}
                                        style={{margin: '0.2rem', height: '40px'}}
                                        onClick={() => handleMealChipDateClick(eventDate)}
                                    />
                                    {/* Badge or warning icon */}
                                    {mealCount > 0 ? (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                backgroundColor: 'white',
                                                color: 'green',
                                                borderRadius: '35%',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                                border: '1px solid',
                                                fontWeight: '700',
                                            }}
                                        >
                                {mealCount}
                            </span>
                                    ) : (
                                        <i
                                            className="icon-warning"
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                fontSize: '10px',
                                                color: 'red',
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })
                    ) : viewType === 'days' && eventHasDays ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'].days.map((dayObj) => {
                                const dayKey = Object.keys(dayObj)[0];
                                const dayName = dayObj[dayKey];

                                // Filter meals for this specific day
                                const mealsForDay = eventMeals.filter(meal =>
                                    meal.time_table?.days?.length > 0 && meal.time_table.days[0][dayKey] === dayName
                                );
                                const mealCount = mealsForDay.length;

                                return (
                                    <Box
                                        key={`meal-${dayKey}`}
                                        sx={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            marginTop: '5px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <Chip
                                            label={
                                                <div style={{textAlign: 'center'}}>
                                                    <Typography variant="body2">{dayName.slice(0, 3)}</Typography>
                                                    <Typography
                                                        variant="caption">{capitalizeFirstLetter(dayKey)}</Typography>
                                                </div>
                                            }
                                            sx={viewType === 'days' && selectedDay === dayKey ? selectedChipStyle : unselectedChipStyle}
                                            style={{margin: '0.2rem', height: '40px'}}
                                            onClick={() => handleMealChipDayClick(dayKey)}
                                        />
                                        {/* Badge or warning icon */}
                                        {mealCount > 0 ? (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px',
                                                    right: '-5px',
                                                    backgroundColor: 'white',
                                                    color: 'green',
                                                    borderRadius: '35%',
                                                    width: '15px',
                                                    height: '15px',
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    zIndex: 1,
                                                    border: '1px solid',
                                                    fontWeight: '700',
                                                }}
                                            >
                                    {mealCount}
                                </span>
                                        ) : (
                                            <i
                                                className="icon-warning"
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px',
                                                    right: '-5px',
                                                    fontSize: '10px',
                                                    color: 'red',
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    padding: '3px',
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </Box>
                                );
                            })
                    ) : (
                        <></>
                    )
                }
            </div>

            {/* show the list of meals */}
            <div className="meals-list-items-container">
                {
                    filteredMeals.length > 0 ? (
                        filteredMeals.map((mealDetails, index) => (
                            <Grid
                                item
                                key={`${mealDetails.id || mealDetails.temp_id}-${selectedDate || selectedDay}-${index}`} // Ensure key is unique to the current meal and date/day selection
                                sx={{ display: 'block', marginBottom: '10px' }}
                            >
                                <MealCard
                                    meal={mealDetails}
                                    onDeleteMeal={() => openDeleteDialog(mealDetails)}
                                    _global_map_ref={props._global_map_ref}
                                />
                            </Grid>
                        ))
                    ) : (
                        <Grid item >
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{ height: 140 }}
                                        image={lodgingHeaderImage}
                                        title="Add Meals"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Meals Made Easy: Find, Order, or Cook!
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            Hungry? Here are some tasty ways to build your meal plan:
                                        </Typography>

                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            <strong>Find an Eatery</strong>:
                                            Use the map to search for a restaurant nearby. It’ll help you locate great spots in the area for that day.
                                        </Typography>

                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            <strong>Craving a Cuisine?</strong>:
                                            Search for a type of cuisine (like sushi, pizza, or tacos) on the map and discover delicious options close by.
                                        </Typography>

                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            <strong>Take-Out or Delivery</strong>:
                                            Prefer staying in? Search for eateries and mark it as take-out or delivery instead of dine-in.
                                        </Typography>

                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            <strong>Food Delivery Services</strong>:
                                            Order through DoorDash, Grubhub, Instacart, Uber Eats, or your favorite delivery service, and add it to your plan.
                                        </Typography>

                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            <strong>Room Service</strong>:
                                            Staying at a hotel? Add the lodging as your eatery and choose take-out or dine-in if you're enjoying the hotel’s dining area.
                                        </Typography>

                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            <strong>I'm Cooking</strong>:
                                            If you're whipping up something yourself, choose the “I’m Cooking” option and list out the meal items.
                                        </Typography>
                                    </CardContent>

                                </Card>

                            </Paper>
                        </Grid>
                    )
                }
            </div>


            {/* Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Delete Meal</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the meal "{mealToDelete?.title}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteMeal(mealToDelete.id)} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


            <InfoMeals
                isOpen={isMealsInfoDialogOpen}
                onClose={() => setMealsInfoDialogOpen(false)}
            />
        </div>
    )

}

export default MealsList;
